import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
  InputLabel,
  FormHelperText,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Container from '../Container';
import Modal from '../Modal';
import { useLoading } from '../../context/LoadingContext';
import LoadingComponent from '../LoadingComponent';
import styles from './LoadSchedules.module.css';

// Mapeo de días para traducción
const dayMapping = {
  MONDAY: 'Lunes',
  TUESDAY: 'Martes',
  WEDNESDAY: 'Miércoles',
  THURSDAY: 'Jueves',
  FRIDAY: 'Viernes',
};

// Valores iniciales del formulario
const INITIAL_VALUES = {
  day: '',
  start_time: '',
  end_time: '',
};

// Esquema de validación con Yup
const validationSchema = Yup.object({
  day: Yup.string().required('El día es requerido'),
  start_time: Yup.string()
    .required('La hora de inicio es requerida')
    .test('is-valid-time', 'La hora debe estar entre 06:00 y 16:00', (value) => {
      if (!value) return false;
      const hours = parseInt(value.split(':')[0], 10);
      return hours >= 6 && hours < 16;
    }),
  end_time: Yup.string()
    .required('La hora de fin es requerida')
    .test('is-valid-time', 'La hora debe estar entre 06:00 y 16:00', (value) => {
      if (!value) return false;
      const hours = parseInt(value.split(':')[0], 10);
      return hours >= 6 && hours < 16;
    }),
});

function LoadSchedules({
  currentSection,
  setCurrentSection,
  createSchedule,
  updateSchedule,
  deleteSchedule,
  fetchCourses,
}) {
  const [currentSchedule, setCurrentSchedule] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [scheduleCreateData, setScheduleCreateData] = useState(null);
  const { showLoading, hideLoading, isLoading } = useLoading();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(950));

  // Maneja el cierre del diálogo de confirmación
  const handleCloseDialog = () => {
    setScheduleCreateData(null);
    setOpenDialog(false);
  };

  // Maneja la aceptación en el diálogo de confirmación
  const handleAcceptDialog = async () => {
    if (scheduleCreateData.type === 'create') {
      await handleCreateSchedule(scheduleCreateData.values);
    } else if (scheduleCreateData.type === 'edit') {
      await handleEditSchedule(scheduleCreateData.values);
    }
    setScheduleCreateData(null);
    setOpenDialog(false);
  };

  // Calcula la duración entre dos horas
  const calculateDuration = (start, end) => {
    const [startHours, startMinutes] = start.split(':').map(Number);
    const [endHours, endMinutes] = end.split(':').map(Number);
    const startTime = new Date(0, 0, 0, startHours, startMinutes);
    const endTime = new Date(0, 0, 0, endHours, endMinutes);
    const duration = (endTime - startTime) / (1000 * 60);
    return duration;
  };

  // Verifica el horario antes de crear o editar
  const handleCheckSchedule = (values, type) => {
    const duration = calculateDuration(values.start_time, values.end_time);
    if (duration > 90) {
      setScheduleCreateData({ type, values });
      setOpenDialog(true);
      return;
    }
    if (type === 'create') {
      handleCreateSchedule(values);
    } else if (type === 'edit') {
      handleEditSchedule(values);
    }
  };

  // Crea un nuevo horario
  const handleCreateSchedule = async (values) => {
    showLoading();
    const fillData = {
      day: values.day,
      start_time: values.start_time,
      end_time: values.end_time,
      course_section: currentSection?.id,
    };
    try {
      const response = await createSchedule({ data: fillData });
      setCurrentSection((prevCurrentSection) => ({
        ...prevCurrentSection,
        schedule: [...prevCurrentSection.schedule, response.data.class_journal],
      }));
      fetchCourses();
    } catch (error) {
      console.error('Error al crear el horario:', error);
    } finally {
      hideLoading();
    }
  };

  // Edita un horario existente
  const handleEditSchedule = async (values) => {
    showLoading();
    const fillData = {
      day: values.day,
      start_time: values.start_time,
      end_time: values.end_time,
      course_section: currentSection?.id,
    };
    const scheduleId = currentSchedule?.id;
    try {
      await updateSchedule({ scheduleId, data: fillData });
      fetchCourses();
      setCurrentSection((prevCurrentSection) => {
        const updatedSchedule = prevCurrentSection.schedule.map((item) =>
          item.id === scheduleId ? { ...item, ...fillData } : item
        );
        return { ...prevCurrentSection, schedule: updatedSchedule };
      });
    } catch (error) {
      console.error('Error al editar el horario:', error);
    } finally {
      hideLoading();
      setOpenModal(false);
    }
  };

  // Elimina un horario
  const handleDeleteSchedule = async () => {
    showLoading();
    const scheduleId = currentSchedule?.id;
    try {
      await deleteSchedule({ scheduleId });
      fetchCourses();
      setCurrentSection((prevCurrentSection) => {
        const updatedSchedule = prevCurrentSection.schedule.filter(
          (item) => item.id !== scheduleId
        );
        return { ...prevCurrentSection, schedule: updatedSchedule };
      });
    } catch (error) {
      console.error('Error al eliminar el horario:', error);
    } finally {
      hideLoading();
      setOpenModal(false);
    }
  };

  // Maneja la apertura del modal de edición
  const handleSetEdit = useCallback(
    (horario) => {
      setCurrentSchedule(horario);
      setOpenModal(true);
    },
    [setCurrentSchedule, setOpenModal]
  );

  return (
    <>
      <Container>
        <div className={styles.schedule_container}>
          {currentSection?.schedule?.length > 0 ? (
            currentSection.schedule.map((horario) => {
              const startTime =
                horario.start_time.length > 5
                  ? horario.start_time.slice(0, -3)
                  : horario.start_time;
              const endTime =
                horario.end_time.length > 5
                  ? horario.end_time.slice(0, -3)
                  : horario.end_time;
              const day = dayMapping[horario.day];

              return (
                <div className={styles.schedule_content} key={horario.id}>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      marginRight: '20px',
                    }}
                  >
                    {day}
                  </p>
                  <p style={{ fontSize: '12px', marginLeft: '10px' }}>
                    {startTime}
                  </p>
                  <p style={{ fontSize: '12px' }}>-</p>
                  <p style={{ fontSize: '12px' }}>{endTime}</p>
                  <IconButton
                    sx={{ marginLeft: 'auto' }}
                    onClick={() => handleSetEdit(horario)}
                  >
                    <BorderColorIcon
                      fontSize="medium"
                      sx={{ color: 'var(--main-blue)', fontSize: '20px' }}
                    />
                  </IconButton>
                </div>
              );
            })
          ) : (
            <div className={styles.empty}>
              No hay bloques horarios cargados para la sección seleccionada
            </div>
          )}
        </div>
      </Container>

      {/* Formulario para agregar un nuevo horario */}
      <Box
        sx={{
          backgroundColor: 'var(--background-gray)',
          borderRadius: '10px',
          padding: '10px',
          width: '100%',
          marginTop: '10px',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#fff',
            borderRadius: '10px',
            width: '100%',
            padding: '10px',
          }}
        >
          <p style={{ fontSize: '14px' }}>
            Agrega el bloque horario para la sección seleccionada. Si la sección
            tiene más de un grupo, agregue el horario para todos los grupos de
            la sección.
          </p>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Formik
              initialValues={INITIAL_VALUES}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                handleCheckSchedule(values, 'create');
                resetForm();
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form style={{ width: '100%' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="day">Día</InputLabel>
                      <TextField
                      size='small'
                        select
                        id="day"
                        name="day"
                        value={values.day}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.day && Boolean(errors.day)}
                        helperText={touched.day && errors.day}
                        fullWidth
                        sx={{
                          marginBottom: 1,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                        }}
                      >
                        {Object.entries(dayMapping).map(([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel htmlFor="start_time">Hora inicio</InputLabel>
                      <TextField
                      size='small'
                        type="time"
                        id="start_time"
                        name="start_time"
                        value={values.start_time}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.start_time && Boolean(errors.start_time)}
                        helperText={touched.start_time && errors.start_time}
                        fullWidth
                        sx={{
                          marginBottom: 1,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel htmlFor="end_time">Hora fin</InputLabel>
                      <TextField
                      size='small'
                        type="time"
                        id="end_time"
                        name="end_time"
                        value={values.end_time}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.end_time && Boolean(errors.end_time)}
                        helperText={touched.end_time && errors.end_time}
                        fullWidth
                        sx={{
                          marginBottom: 1,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: 2,
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ borderRadius: '10px' }}
                    >
                      Aceptar
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>

      {/* Modal para editar un horario existente */}
      {openModal && (
        <Modal title="Editar Horario" modalOpen={setOpenModal}>
          <Formik
            initialValues={{
              day: currentSchedule.day,
              start_time: currentSchedule.start_time,
              end_time: currentSchedule.end_time,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleCheckSchedule(values, 'edit');
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form style={{ width: '100%' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="day">Día</InputLabel>
                    <TextField
                    size='small'
                      select
                      id="day"
                      name="day"
                      value={values.day}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.day && Boolean(errors.day)}
                      helperText={touched.day && errors.day}
                      fullWidth
                      sx={{
                        marginBottom: 1,
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      {Object.entries(dayMapping).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel htmlFor="start_time">Hora inicio</InputLabel>
                    <TextField
                    size='small'
                      type="time"
                      id="start_time"
                      name="start_time"
                      value={values.start_time}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.start_time && Boolean(errors.start_time)}
                      helperText={touched.start_time && errors.start_time}
                      fullWidth
                      sx={{
                        marginBottom: 1,
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel htmlFor="end_time">Hora fin</InputLabel>
                    <TextField
                      type="time"
                      id="end_time"
                      name="end_time"
                      value={values.end_time}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.end_time && Boolean(errors.end_time)}
                      helperText={touched.end_time && errors.end_time}
                      fullWidth
                      size='small'
                      sx={{
                        marginBottom: 1,
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ borderRadius: '10px' }}
                    onClick={handleDeleteSchedule}
                  >
                    Eliminar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: '10px' }}
                  >
                    Editar
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Modal>
      )}

      {/* Componente de carga */}
      {isLoading && <LoadingComponent />}

      {/* Diálogo de confirmación */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: '10px' } }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ color: 'red', fontSize: '18px' }}
        >
          {'La duración de la clase es mayor a 1 hora y 30 minutos.'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontSize: '16px' }}
          >
            ¿Estás seguro que quieres agregar este horario?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} sx={{ fontSize: '14px' }}>
            Cancelar
          </Button>
          <Button onClick={handleAcceptDialog} sx={{ fontSize: '14px' }}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default LoadSchedules;
