import React from "react";
import { useField, Field } from "formik";
import { Button, FormHelperText } from "@mui/material";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import styles from "./FormFields.module.css";

export const MyTextInput = ({label,width, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);

  return (
    <div className={styles["input-container"]} style={{ width: width }}>
      <label htmlFor={props.id || props.name} 
      style={{marginBottom: '3px'}}>{label}</label>
      {/* <i className={props.icon}></i> */}
      <TextField 
        {...field}
        {...props}
        variant="outlined" size="small"
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error ? (
          <div className={styles.error}>
            {meta.error}
          </div>
        ) : null}
        fullWidth
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
          },
          "& .MuiInputBase-root": {
          fontSize: "16px", // Cambia el tamaño de la fuente del texto del input
        },
        "& .MuiInputLabel-root": {
          fontSize: "16px", // Cambia el tamaño de la fuente de la etiqueta
        },
        "& .MuiFormHelperText-root": {
          fontSize: "14px", // Cambia el tamaño de la fuente del texto de ayuda/error
        },
        }}
      />
    </div>
  );
};
export const MyDateTextInput = ({label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  const today = new Date().toISOString().split('T')[0];

  return (
    <div className={styles["input-container"]}>
      <label htmlFor={props.id || props.name} 
      style={{marginBottom: '3px'}}>{label}</label>
      {/* <i className={props.icon}></i> */}
      <input
        max={today}
        type="date"
        className={
          meta.touched && meta.error
            ? styles["input-container__input-error"]
            : styles["input-container__input"]
        }
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className={styles.error}>
          <i
            className="fa fa-exclamation-circle"
            style={{ fontSize: "16px" }}
          ></i>
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export const MyEmailTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);

  return (
    <div className={styles["input-container"]}>
      <label htmlFor={props.id || props.name} 
      style={{marginBottom: '3px'}}>{label}</label>
      {/* <i className={props.icon}></i> */}
      <input
        className={
          meta.touched && meta.error
            ? styles["input_container_email_error"]
            : styles["input_container_email"]
        }
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className={styles.error}>
          <i
            className="fa fa-exclamation-circle"
            style={{ fontSize: "16px" }}
          ></i>
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};
export const MyIconTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  return (
    <>
      <div className={styles["login-input-container"]}>
        {/* <label htmlFor={props.id || props.name}>{label}</label> */}
        <i className={props.icon}></i>
        <input
          className={styles["login-input-container__input"]}
          {...field}
          {...props}
        />
      </div>
      {meta.touched && meta.error ? (
        <div className={styles.error}>
          <i className="fa fa-exclamation-circle" style={{ fontSize: "14px" }}></i>
          {meta.error}
        </div>
      ) : null}
    </>
  );
};

export const MyCheckbox = ({ children, onClick, ...props }) => {
  // React treats radios and checkbox inputs differently other input types, select, and textarea.
  // Formik does this too! When you specify `type` to useField(), it will
  // return the correct bag of props for you -- a `checked` prop will be included
  // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
  const [field, meta] = useField({ ...props, type: "checkbox" });

  return (
    <div className={styles.checkbox_field}>
      <label className={styles.checkbox_input}>
        <input
          type="checkbox"
          {...field}
          {...props}
          className={styles.checkbox}
          onClick={onClick}
        />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export const MySelect = ({ setType, label, children, ...props }) => {
  const [field, meta] = useField(props);

  React.useEffect(() => {
    if (setType) {
      setType(field.value === "INTERNAL_TEACHER");
    }
  }, [field.value, setType]);

  return (
    <FormControl 
      fullWidth 
      variant="outlined"  size="small"
      error={meta.touched && Boolean(meta.error)} 
      sx={{"& .MuiInputBase-root": {
          fontSize: "14px", // Cambia este valor al tamaño de letra que desees
          },
          "& .MuiMenuItem-root": {
            fontSize: "14px", // Cambia el tamaño de la letra de los elementos del menú
          },}}
    >
      <label>{label}</label>
      <Select
        {...field}
        {...props}
        size="small"
        fullWidth
        sx={{
          marginTop: '3px',
          borderRadius: "10px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
          },
          
        }}
      >
        {children}
      </Select>
      {meta.touched && meta.error ? (
        <FormHelperText className={styles.error}>
          <i
            className="fa fa-exclamation-circle"
            style={{ fontSize: "16px" }}
          ></i>
          {meta.error}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

export const MySubmitButton = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className={styles.submit_buttom}>
      <Button {...field} {...props} className={styles["form-button"]} variant="contained">
        {props.icon ? <i className={props.icon}></i> : props.name}
      </Button>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export const MyTextarea = ({type, label, rows = 7, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  return (
    <div>
      <div className={styles["text_area"]} 
      style={{width: type === "comment" && "700px"}}>
        <label htmlFor={props.id || props.name}>{label}</label>
        {/* <i className={props.icon}></i> */}
        <TextField multiline
        fullWidth
        style={{fontSize: type === "feedback" && "15px"}}
          rows={rows}
          {...field}
          {...props}
          error={meta.touched && meta.error}
          sx={{
            fontSize: type === "feedback" ? "15px" : "inherit",
            "& .MuiOutlinedInput-root": {
              fontSize: type === "feedback" ? "15px" : "inherit",
              borderRadius: '10px'
            },
            "& .MuiInputBase-root": {
          fontSize: "16px", // Cambia el tamaño de la fuente del texto del input
        },
        "& .MuiInputLabel-root": {
          fontSize: "16px", // Cambia el tamaño de la fuente de la etiqueta
        },
        "& .MuiFormHelperText-root": {
          fontSize: "14px", // Cambia el tamaño de la fuente del texto de ayuda/error
        },
          }}
        />
      </div>
      {meta.touched && meta.error ? (
        <div className={styles.error}>
          
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export const MyMessageArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  return (
    <div className={styles["msg_area"]}>
      <input
        type="text"
        className={
          meta.touched && meta.error
            ? styles["msg__input-error"]
            : styles["msg"]
        }
        {...field}
        {...props}
      />
    </div>
  );
};

export const MyRadio = ({ children, onClick, ...props }) => {
  // React treats radios and checkbox inputs differently other input types, select, and textarea.
  // Formik does this too! When you specify `type` to useField(), it will
  // return the correct bag of props for you -- a `checked` prop will be included
  // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
  const [field, meta] = useField({ ...props, type: "radio" });

  return (
    <div className={styles.radio_field}>
      <label className={styles.radio_input}>
        <input
          type="radio"
          {...field}
          {...props}
          className={styles.radio}
          onClick={onClick}
        />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export const MySubmitMessageButton = ({ label, ...props }) => {
  const [field, meta] = useField({ ...props });
  return (
    <>
      <button className={styles["send-msg-button"]} {...props}>
        <i className="fas fa-paper-plane"></i>
      </button>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};
