import React, {useContext, useEffect, useState} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Grid, Stack, Box, MenuItem, FormControl, Select, InputLabel, TextField, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { dateFormatter } from '../../../utils/dateFormatter';
import { EventsContext } from '../../../context/EventsProvider';
import { CurrentUserContext } from "../../../context/CurrentUserProvider";

const Title = styled('p')({
  fontSize: '1.6rem',
  fontWeight:'bold',
  marginBottom: '5px'
});
const Info = styled('p')({
  fontSize: '1.2rem',
  color: '#787878',
});
const Text = styled('p')({
  fontSize: '1.2rem',
  paddingTop: '5px'
});
const Text2 = styled('p')({
  fontSize: '1.3rem',
  paddingTop: '5px',
  color: 'var(--main-blue)',
  fontWeight:'bold',
});

function RequestAdmin() {
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const {currentUser} = useContext(CurrentUserContext)
  const {fetchSingleEvents, singleEvents, fetchForUpdate, dataUpdate, updatePartialEvent} = useContext(EventsContext)

  const navigate = useNavigate();
  const { eventId } = useParams();

  useEffect(() => {
    setFormData(singleEvents);
  }, [singleEvents]);

    useEffect(() => {
        const event = parseInt(eventId);
        fetchSingleEvents(event);  
        fetchForUpdate();
    }, [eventId]);

    const toggleEditMode = () => {
        setEditMode(!editMode);
      };

      const formatDuration = (duration) => {
        if (!duration) return "";
        const days = String(duration).split(" ")[0];
        return `${days} Días`;
      };
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'program') {
          const selectedProgram = dataUpdate.programs.find(program => program?.id === value);
          setFormData({ ...formData, [name]: selectedProgram });
        } 
        else if (name === 'term') {
          const selectedTerm = dataUpdate.terms.find(term => term?.id === value);
          setFormData({ ...formData, [name]: selectedTerm });
        }
        else if (name === 'designated_person'){
          const selectedPerson = dataUpdate?.internal_users.find(user => user?.id === value);
          setFormData({ ...formData, [name]: selectedPerson });
        }
        else {
          setFormData({ ...formData, [name]: value });
        }
      };
    
      const handleSave = async () => {
        try {
          // Enviar los datos actualizados al servidor
          const event = parseInt(eventId);
          const data = {
            ...formData,
            status : formData?.status,
            term: formData?.term?.id || null,
            program: formData?.program?.id || null,
            designated_person: formData?.designated_person?.id || null,
            area_responsible: formData?.area_responsible || null,
          }

          await updatePartialEvent({eventId: event, data: data});
          setEditMode(false);
          fetchSingleEvents(event); // Actualiza los datos después de guardar
        } catch (error) {
          console.error('Error updating incidence:', error);
        }
      };
      const areaResponsible = (area_responsable) => {

        let area = "";
      
        switch (area_responsable) {
          case "Administration":
            area = "Administración";
            break;
          case "Logistics and Inventory":
            area = "Logística y Inventario";
            break;
          case "Human Resources":
            area = "Recursos Humanos";
            break;
          case "IT":
            area = "IT";
            break;
          case "Post Sales":
            area = "Post Venta";
            break;
          case "Sales":
            area = "Ventas";
            break;
          case "Monitoring":
            area = "Seguimiento";
            break;
          case "Other":
            area = "Otro";
            break;
        }
      
        return area;
      };
      
      const typeofEvents = (type) => {
        switch (type) {
          case 'Meeting':
            return 'Reunión';
          case 'Chat':
            return 'Chat';
          case 'Call':
            return 'Llamada';
          case 'Other':
            return 'Otro';
        }
      };
    const getStatusIcon = (status) => {
        switch (status) {
          case 'Pendiente':
            return {
              className: 'fa fa-clock',
              style: {
                backgroundColor: 'var(--sec-orange)', 
                color: '#fff',
                padding: '7px',
                borderRadius: '7px',
                fontSize: '16px',
              },
            };
          case 'En Proceso':
            return {
              className: 'fas fa-circle-notch',
              style: {
                backgroundColor: 'var(--sec-blue)',
                color: '#fff',
                padding: '7px',
                borderRadius: '7px',
                fontSize: '16px',
              },
            };
          case 'Resuelto':
            return {
              className: 'fas fa-check-circle',
              style: {
                backgroundColor: 'var(--sec-green)',
                color: '#fff',
                padding: '7px',
                borderRadius: '7px',
                fontSize: '16px',
              },
            };
          case 'Cerrado':
            return {
              className: 'fas fa-times-circle',
              style: {
                backgroundColor: 'var(--sec-red)', // Puedes definir un color para closed
                color: '#fff',
                padding: '7px',
                borderRadius: '7px',
                fontSize: '16px',
              },
            };
          default:
            return {
                className: 'fa fa-clock',
                style: {
                  backgroundColor: 'var(--sec-orange)', 
                  color: '#fff',
                  padding: '7px',
                  borderRadius: '7px',
                  fontSize: '16px',
                },
            };
        }
      };
    const icon = getStatusIcon(singleEvents?.status);
    
    const fullAccessRoles = ['IT', 'post_sales', 'monitoring_teacher', 'monitoring_coordinator',
      'monitoring_teacher_3', 'purchases_and_inventory'];
    const UserFullAccess = fullAccessRoles.includes(currentUser.role);

  return (
    <Stack direction={"column"}>
      <i
      className="fal fa-arrow-left"
      style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
      onClick={() => navigate(-1)}
      ></i>
        <Grid container direction={"row"} columnGap={2} rowGap={2}>
            <Grid item md={3.5} xs={7} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Title>{"Reportado Por"}</Title>
              <Info>{singleEvents.report_person?.first_name } {singleEvents?.report_person?.last_name}</Info>
              <Stack direction={"row"} spacing={1}
              sx={{display:'flex', alignItems:'center', marginTop: '2px'}}>
                <i class="fas fa-calendar" style={{color: "#787878", fontSize:'1.2rem'}}></i>
                <Info>Fecha de Inicio: {dateFormatter(singleEvents?.event_date)}</Info>
              </Stack>
            </Grid>
            <Grid item md={3.5} xs={7}
              sx={{ backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px' }}>
              <Title>{"Personal Asignado"}</Title>
              <Stack direction={"column"} spacing={1} sx={{ display: 'flex', alignItems: 'flex-start', marginTop: '2px' }}>
                {editMode && UserFullAccess ? (
                  <FormControl fullWidth>
                    <InputLabel>Personal Asignado</InputLabel>
                    <Select name="designated_person" value={formData?.designated_person?.id || ''} onChange={handleInputChange}>
                      {dataUpdate?.internal_users?.map((user) => (
                        <MenuItem key={user?.id} value={user?.id}>
                          {user?.first_name} {user?.last_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <>
                    <Info>  {singleEvents?.designated_person?.first_name && singleEvents?.designated_person?.last_name
                      ? `${singleEvents.designated_person.first_name} ${singleEvents.designated_person.last_name}`
                      : "Sin personal asignado"}
                      
                    </Info>
                    <Stack direction={"row"} spacing={1} alignItems="center" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      <i className="fas fa-calendar" style={{ color: "#787878", fontSize: '1.2rem' }}></i>
                      <Info>Fecha de Fin:</Info>
                      <Info>{dateFormatter(singleEvents?.update_to_solution) || "Sin Asignar"}</Info>
                    </Stack>
                  </>
                )}
              </Stack>
            </Grid>
            <Grid item md={2} xs={4.5} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"} sx = {{flexGrow: 1}}>
                      <Info>Estado</Info>
                      {editMode  && UserFullAccess ? (
                        <FormControl fullWidth>
                            <InputLabel>Estado</InputLabel>
                            <Select name="status" value={formData.status} onChange={handleInputChange}>
                            <MenuItem value={'Pendiente'}>Pendiente</MenuItem>
                            <MenuItem value={'En Proceso'}>En Proceso</MenuItem>
                            <MenuItem value={'Resuelto'}>Resuelto</MenuItem>
                            <MenuItem value={'Cerrado'}>Cerrado</MenuItem>
                            </Select>
                        </FormControl>
                        ) : (
                        <Text2>{singleEvents?.status}</Text2>
                        )}
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                     <i className={icon.className} style={icon.style}></i>
                  </Grid>
              </Grid>
              
            </Grid>
            
            <Grid item md={1.4} xs={2.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"} sx = {{flexGrow: 1}}>
                      <Info>Editar</Info>
                        <Stack direction={"row"} spacing={2} alignItems="center">
                          <IconButton onClick={toggleEditMode} sx={{ marginTop: '15px' }}>
                            <i className={`fa ${editMode ? 'fa-times' : 'fas fa-edit'}`} style={{backgroundColor: 'var(--main-blue)', 
                                color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                          </IconButton>
                          {editMode && (
                            <IconButton onClick={handleSave} sx={{ marginTop: '15px' }}>
                              <i className="fa fa-save" style={{backgroundColor: 'var(--sec-green)', 
                                color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                            </IconButton>
                          )}
                        </Stack>
                      </Stack>
                  </Grid>
              </Grid>
              
            </Grid>
            <Grid item md={3.5} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"}>
                      <Info>Institución</Info>
                      <Text2>{singleEvents?.institution?.name}</Text2>
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                    <i class="fas fa-university" 
                    style={{backgroundColor: 'var(--main-blue)', 
                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                  </Grid>
              </Grid>
              
            </Grid>
            <Grid item md={3.5} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"}>
                      <Info>Tipo</Info>
                      <Text2>{singleEvents?.type_event}</Text2>
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                    <i class="fas fa-list-ul" 
                    style={{backgroundColor: 'var(--main-blue)', 
                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                  </Grid>
              </Grid>
              
            </Grid>
            <Grid item md={3.5} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"}>
                      <Info>A través de</Info>
                      <Text2>{typeofEvents(singleEvents?.incident_type)}</Text2>
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                    <i class="fas fa-list-ul" 
                    style={{backgroundColor: 'var(--main-blue)', 
                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                  </Grid>
              </Grid>
              
            </Grid>
            <Grid item md={3.5} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"} sx = {{flexGrow: 1}}>
                      <Info>Tiempo Estimado De Solución</Info>
                      <Stack direction={"row"} spacing={1}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '2px'
                          }}
                        >
                          {editMode && UserFullAccess ? (
                            <TextField
                              fullWidth
                              name="estimated_resolution_time"
                              label="Tiempo Estimado de Resolución"
                              value={formData.estimated_resolution_time || ''}
                              onChange={handleInputChange}
                              placeholder="3"
                            />
                          ) : (
                            <Info>{formatDuration(singleEvents?.estimated_resolution_time)}</Info>
                          )}
                        </Stack>
                        
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                    <i class="fas fa-clock" 
                    style={{backgroundColor: 'var(--main-blue)', 
                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                  </Grid>
              </Grid>
              
            </Grid>
            <Grid item md={3.5} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"} sx = {{flexGrow: 1}}>
                      <Info>Área Responsable</Info>
                      {editMode && UserFullAccess ? (
                        <FormControl fullWidth>
                            <InputLabel>Área Responsable</InputLabel>
                            <Select name="area_responsible" value={formData.area_responsible} onChange={handleInputChange}>
                            <MenuItem value={'Administration'}>Administración</MenuItem>
                            <MenuItem value={'IT'}>IT</MenuItem>
                            <MenuItem value={'Logistics and Inventory'}>Logística e Inventario</MenuItem>
                            <MenuItem value={'Human Resources'}>Recursos Humanos</MenuItem>
                            <MenuItem value={'Post Sales'}>Postventa</MenuItem>
                            <MenuItem value={'Monitoring'}>Seguimiento</MenuItem>
                            <MenuItem value={'Other'}>Otros</MenuItem>
                            </Select>
                        </FormControl>
                        ) : (
                        <Text2>{areaResponsible(singleEvents?.area_responsible)}</Text2>
                        )}
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                     <i class = "fas fa-users" style={{backgroundColor: 'var(--main-blue)', 
                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                  </Grid>
              </Grid>
            </Grid>
            <Grid item md={3.5} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"} sx = {{flexGrow: 1}}>
                      <Info>Programa</Info>
                      {editMode ? (
                        <FormControl fullWidth>
                            <InputLabel>Programa</InputLabel>
                            <Select name="program" value={formData?.program?.id} onChange={handleInputChange}>
                            {dataUpdate?.programs?.map((program) => (
                                <MenuItem key={program?.id} value={program?.id}>
                                {program.name}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                        ) : (
                        <Text2>{singleEvents?.program?.name}</Text2>
                        )}
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                    <i class="fa fa-book" 
                    style={{backgroundColor: 'var(--main-blue)', 
                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                  </Grid>
              </Grid>
              
            </Grid>
           
            <Grid item md={3.5} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"} sx = {{flexGrow: 1}}>
                      <Info>Etapa</Info>
                      {editMode ? (
                        <FormControl fullWidth>
                          <InputLabel>Etapa</InputLabel>
                            <Select name="stage" value={formData.stage} onChange={handleInputChange}>
                            <MenuItem value={'Preescolar'}>Preescolar</MenuItem>
                            <MenuItem value={'Primaria Menor'}>Primaria Menor</MenuItem>
                            <MenuItem value={'Primaria Mayor'}>Primaria Mayor</MenuItem>
                            <MenuItem value={'Bachillerato'}>Bachillerato</MenuItem>
                            </Select>
                        </FormControl>
                        ) : (
                        <Text2>{singleEvents?.stage}</Text2>
                      )}
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                  <i class="fas fa-bookmark" style={{backgroundColor: 'var(--main-blue)', 
                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                  </Grid>
              </Grid>
            </Grid>
            <Grid item md={3.5} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"} sx = {{flexGrow: 1}}>
                      <Info>Lapso</Info>
                      {editMode ? (
                        <FormControl fullWidth>
                            <InputLabel>Lapso</InputLabel>
                            <Select name="term" value={formData?.term?.id} onChange={handleInputChange}>
                              {dataUpdate?.terms?.map((term) => (
                                <MenuItem value={term?.id}>{term?.name}</MenuItem>

                              ))}
                            </Select>
                        </FormControl>
                        ) : (
                        <Text2>{singleEvents?.term?.name}</Text2>
                      )}
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                    <i class="fas fa-calendar-alt" 
                    style={{backgroundColor: 'var(--main-blue)', 
                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                  </Grid>
              </Grid>
              
            </Grid>
          </Grid>
          {singleEvents?.text_message && (
             <Box sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', 
              borderRadius: '10px', marginTop:'15px'}}>
                <Info>Mensaje</Info>
                  <Text>{singleEvents?.text_message}</Text>
              </Box>
          )}
         
          <Box sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', 
          borderRadius: '10px', marginTop:'15px'}}>
            <Info>Descripción</Info>
            {editMode ? (
                <TextField name="description" value={formData.description} onChange={handleInputChange} fullWidth multiline rows={4} />
                ) : (
                <Text>{singleEvents?.description}</Text>
            )}
          </Box>
          <Box sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', 
          borderRadius: '10px', marginTop:'15px'}}>
            <Info>Solución</Info>
                {editMode && UserFullAccess ? (
            <TextField name="text_solution" value={formData.text_solution} onChange={handleInputChange} fullWidth multiline rows={4} />
            ) : (
            <Text>{singleEvents?.text_solution}</Text>
            )}
          </Box>         
    </Stack>
  )
}
export default RequestAdmin
