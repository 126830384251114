import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MySubmitButton } from "../../FormFields";
import { CapacitacionesContext } from "../../../context/CapacitacionesContext";
import YoutubeEmbed from "../../../utils/YoutubePreview";
import extractVideoId from "../../../utils/extractVideoId";
import styles from "./CapacitacionTeacher.module.css";
import Container from "../../Container";
import { CAPACITACION_ENDPOINT } from "../../../utils/constants";
import { wordCapitalize } from "../../../utils/capitalize";
import { get } from "../../../utils/http";
import { CurrentUserContext } from "../../../context/CurrentUserProvider";
import { dateFormatter } from "../../../utils/dateFormatter";
import { handleDownload } from "../../../utils/downloadFile";
import QuizView from "./QuizView";

function CapacitacionTeacher() {
  const { sendAssignment } = useContext(CapacitacionesContext);
  const [capacitacion, setCapacitacion] = useState([]);
  const [showFileUploader, setShowFileUploader] = useState(true);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState({});
  const { currentUser } = useContext(CurrentUserContext);
  const fileInputRef = useRef(null);

  const { capacitacionId } = useParams();

  const navigate = useNavigate();
  
  const getCapacitacion = async () => {
    const url = `${CAPACITACION_ENDPOINT}${capacitacionId}/my_training/`;
    const response = await get({ url });
    setCapacitacion(response.data);
  };

  useEffect(() => {
    getCapacitacion();
  }, [capacitacionId]);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleOnChange = (e) => {
    setShowFileUploader(false);
    setFileName(e.target.files[0].name);
    setFile(e.target.files[0]);
    setFileUploaded(true);
  };

  const contenidoSinComillas = capacitacion?.task_info?.replace(/^"|"$/g, "");

  const createHandler = async (data) => {
    const response = await sendAssignment({ data });
    getCapacitacion();
    setShowFileUploader(true);
    setFileUploaded(false);
  };

  const tasksFilter = capacitacion?.tasks?.filter((item) => item?.teacher === currentUser?.id)

  return (
    <Container>
      <div className={styles.container}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
        <div>
          <p className={styles.title}>{wordCapitalize(capacitacion?.title)}</p>
        </div>
        {(capacitacion?.video !== "nan" && capacitacion.video) && (
          <div>
            <YoutubeEmbed
              url={capacitacion?.video}
              embedId={extractVideoId(capacitacion?.video)}
            />
          </div>
        )}
        <div>
          {capacitacion?.task_info && 
          <>
          <p className={ styles.title2}
            >Asignación</p>
          <div className={styles.task_info}>
            <div dangerouslySetInnerHTML={{ __html: contenidoSinComillas }} style={{fontSize: '14px'}} />
          </div> 
          </>}
          {capacitacion?.guide && (
            <>
              <p className={styles.title2}>Guía</p>
              <div className={styles.task_info}>
                <a target="_blank" rel="noreferrer" href={capacitacion.guide}
                 style={{textDecoration: 'none', fontSize: '16px'}} >Ver guía</a>
              </div>
            </>
          )}
          {capacitacion?.quiz && 
          <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <p className={styles.title2}>Quiz</p>
            <QuizView getCapacitacion={getCapacitacion} quizName={capacitacion?.quiz?.description}
            quiz={capacitacion?.quiz?.templatequiz} quizId={capacitacion?.quiz?.id} quizUser ={capacitacion?.quiz_user}/>
          </div>
          }
          {capacitacion?.task_info && 
          <>
          {tasksFilter?.length === 0 ? (
            <Formik
              initialValues={{
                stage: "",
              }}
              validationSchema={Yup.object({
                stage: Yup.string().min(1, "El campo no puede estar vacío."),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const formData = new FormData();
                const date = new Date();
                const formattedDate = date.toISOString();
                formData.append("training", capacitacionId);
                formData.append("date", formattedDate);
                formData.append("teacher", currentUser.id);
                formData.append("file", file);
                setTimeout(() => {
                  createHandler(formData);
                  setSubmitting(false);
                }, 400);
                resetForm();
              }}
            >
              <Form className={styles["form"]}>
                <div className={styles["form__fields"]}>
                  <div className={styles.wrapper}>
                    {showFileUploader && (
                      <form
                        action="#"
                        onClick={handleUploadClick}
                        encType="multipart/form-data"
                      >
                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          onChange={handleOnChange}
                        ></input>
                        <i className="fas fa-cloud-upload-alt"></i>
                        <p>{`Click para subir archivo`}</p>
                      </form>
                    )}
                    {fileUploaded && (
                      <section className={styles.uploadedarea}>
                        <li className={styles.row}>
                          <div className={styles.content}>
                            <i
                              className="fas fa-file-alt"
                              style={{ fontSize: "20px" }}
                            ></i>
                            <div className={styles.details}>
                              <span
                                className={styles.name}
                              >{`${fileName} • Uploaded`}</span>
                            </div>
                          </div>
                          <i
                            className="fas fa-check"
                            style={{ fontSize: "20px" }}
                          ></i>
                        </li>
                        <MySubmitButton name="Cargar" type="submit" />
                      </section>
                    )}
                  </div>
                </div>
              </Form>
            </Formik>
          ) : (
            <div>
              {tasksFilter?.map((task) => {
                return (
                  <div className={styles.task}>
                    <div className={styles.task_row}>
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ fontSize: "11px", textAlign: "center" }}>
                          Fecha de Entrega
                        </p>
                        <p
                          style={{
                            fontSize: "13px",
                            textAlign: "center",
                            paddingTop: "5px",
                          }}
                        >
                          {dateFormatter(task.date)}
                        </p>
                      </div>
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ fontSize: "11px", textAlign: "center" }}>
                          Nota
                        </p>
                        <p
                          style={{
                            fontSize: "13px",
                            textAlign: "center",
                            paddingTop: "5px",
                          }}
                        >
                          {task.reviewed ? task.grade : "Sin corregir"}
                        </p>
                      </div>
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <p className={styles.submitted}>Entregado</p>
                      </div>
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          justifyContent: "center",
                          marginLeft: "auto",
                        }}
                      >
                        <p
                          className={styles.comment}
                          onClick={() => handleDownload(task.file)}
                        >
                          Ver mi Entrega
                        </p>
                      </div>
                    </div>
                    {task.reviewed && (
                      <div
                        style={{
                          width: "100%",
                          justifyContent: "flex-start",
                          display: "flex",
                          flexDirection: "column",
                          borderTop: "1px solid #989a9e",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "start",
                            fontSize: "15px",
                            fontWeight: "bold",
                            paddingTop: "10px",
                          }}
                        >
                          Comentario
                        </p>
                        <p style={{ textAlign: "start", fontSize: "14px" }}>
                          {task.comment}
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          </>}
        </div>
      </div>
    </Container>
  );
}

export default CapacitacionTeacher;
