import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import CapacitacionesListView from "../../CapacitacionesAnuales/Students/CapacitacionesListView";

import {
  PROGRAMS_ENDPOINT,
  CAPACITACION_ENDPOINT,
} from "../../../utils/constants";
import { get } from "../../../utils/http";
import Container from "../../Container";
import { tutoriales, instalaciones } from "../../../utils/capacitacionesIniciales";

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

function CapacitacionesListTeachers() {
  const [capacitaciones, setCapacitaciones] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [value, setValue] = React.useState('2');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchPrograms = async () => {
      const url = `${PROGRAMS_ENDPOINT}`;
      const response = await get({ url });

      setPrograms(response.data);
    };
    fetchPrograms();
  }, []);

  useEffect(() => {
    const fetchCapacitaciones = async () => {
      const url = `${CAPACITACION_ENDPOINT}my_trainings/`;
      const response = await get({ url });

      setCapacitaciones(response.data);
    };
    fetchCapacitaciones();
  }, []);

  const columns = [
    {
      name: "Título",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Programas",
      selector: (row) => row.programs,
      sortable: true,
      cell: (cell) => {
        const programas = [];
        cell.programs.forEach((program) => {
          programs.forEach((pr) => {
            if (program === pr.id) {
              programas.push(pr.name);
            }
          });
        });
        return (
          <div style={{ marginTop: "5px" }}>
            {programas.map((pp) => (
              <p style={{ marginBottom: "5px" }}>{pp}</p>
            ))}
          </div>
        );
      },
    },
    {
      name: "Fecha de inicio",
      selector: (row) => row.start_date,
      sortable: true,
    },
    {
      name: "Fecha de cierre",
      selector: (row) => row.end_date,
      sortable: true,
    },
  ];

  const columnsTutoriales = [
    {
      name: "Título",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Tutorial",
      selector: (row) => row.url,
      cell: (cell) => {
        return(
          <a target="_blank"  rel="noreferrer" href={cell.url} style={{color: 'var(--main-blue)', textDecoration: 'none'}}>
            {cell.url}
          </a>
        )
      }
    },
  ];
  const columnsInstalaciones = [
    {
      name: "Título",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Tutorial",
      selector: (row) => row.url,
      cell: (cell) => {
        return(
          <a target="_blank"  rel="noreferrer" href={cell.url} style={{color: 'var(--main-blue)', textDecoration: 'none'}}>
            {cell.url}
          </a>
        )
      }
    },
  ];

  const navigate = useNavigate();

  const handleRowClick = (row) => {
    navigate(`/capacitaciones/${row.id}`, { relative: false });
  };

  const handleVideoClick = (row) => {
    navigate(`/capacitaciones/anuales/${row.id}`, { relative: false });
  };

  const filteredCapacitaciones = capacitaciones.filter((item) => new Date(item.end_date) >= new Date())
  return (
    <Container>
      <Box sx={{ width: '100%' }}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs onChange={handleChange} aria-label="visible arrows tabs example" variant="scrollable"
         value={value} >
            <Tab label="Capacitaciones Anuales" value="2" sx={{fontSize: '12px'}}/>
            <Tab label="Capacitaciones Mensuales" value="1"  sx={{fontSize: '12px'}}/>
            <Tab label="Instalaciones de programas" value="4" sx={{fontSize: '12px'}}/>
          </Tabs>
        </Box>
        <TabPanel value="1" sx={{borderRadius: '10px', padding: "10px 2px"}}>
        <DataTable
            columns={columns}
            data={filteredCapacitaciones}
            customStyles={customStyles}
            sortIcon={
              <i
                className="far fa-arrow-up"
                style={{ marginLeft: "5px", fontSize: "14px" }}
              ></i>
            }
            highlightOnHover
            fixedHeader
            fixedHeaderScrollHeight="300px"
            pointerOnHover
            onRowClicked={(row) => handleRowClick(row)}
            noDataComponent="No tienes capacitaciones asignadas actualmente"
          />
        </TabPanel>
        <TabPanel value="2" sx={{borderRadius: '10px', padding: "10px 2px"}}>
          <CapacitacionesListView />
        </TabPanel>
        <TabPanel value="4"  sx={{borderRadius: '10px', padding: "10px 2px"}}>
        <DataTable
            columns={columnsInstalaciones}
            data={instalaciones}
            customStyles={customStyles}
            sortIcon={
              <i
                className="far fa-arrow-up"
                style={{ marginLeft: "5px", fontSize: "14px" }}
              ></i>
            }
            highlightOnHover
            fixedHeader
            fixedHeaderScrollHeight="300px"
            onRowClicked={(row) => handleVideoClick(row)}
            noDataComponent="No tienes capacitaciones asignadas actualmente"
          />
        </TabPanel>
      </TabContext>
    </Box>
    </Container>
  );
}

export default CapacitacionesListTeachers;

