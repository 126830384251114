import React, { useState, useContext, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MenuItem from '@mui/material/MenuItem';
import { MySelect, MySubmitButton } from "./FormFields";
import Modal from "./Modal";
import { CurrentUserContext } from "../context/CurrentUserProvider";
import styles from "./LoadPictures.module.css";
import { post } from "../utils/http";
import { BASE_URL } from "../utils/constants";
import { AlertsContext } from "../context/AlertsContext";

function LoadPictures({ setOpenModal }) {
  const { currentUser } = useContext(CurrentUserContext);
  const fileInputRef = useRef(null);
  const showSnackbar = useContext(AlertsContext);

  const [files, setFiles] = useState([]);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  function bytesToMegabytes(bytes) {
    const bytesPerMB = 1024 * 1024;
    return ((bytes / bytesPerMB) + 0.21);
  }

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const handleFiles = (newFiles) => {
    const updatedFiles = [...files, ...newFiles]?.filter((file, index, self) =>
      index === self.findIndex(f => f.name === file.name) // Elimina duplicados
    );

    const allFilesAreValid = updatedFiles.every(file => bytesToMegabytes(file?.size) <= 10);

    setFiles(updatedFiles);
    setIsSubmitEnabled(allFilesAreValid);
  };

  const removeFile = (index) => {
    const updatedFiles = files?.filter((_, fileIndex) => fileIndex !== index);

    const allFilesAreValid = updatedFiles?.every(file => bytesToMegabytes(file?.size) <= 10);

    setFiles(updatedFiles);
    setIsSubmitEnabled(allFilesAreValid);
  };

  const sendPictures = async (data) => {
    const url = `${BASE_URL}/monitoring_pictures/`;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Registro fotográfico enviado", "success");
    } else {
      showSnackbar("Error al cargar el registro", "error");
    }
    setOpenModal(false);
  };

  return (
    <Modal title={"Registro Fotográfico"} modalOpen={setOpenModal}>
      <Formik
        initialValues={{
          institution: null,
        }}
        validationSchema={Yup.object()}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const formData = new FormData();

          // let inst = values.institution
          //   ? values.institution
          //   : currentUser.teacher_profile.institution.toString()

          formData.append("teacher", currentUser.teacher_profile.id);
          formData.append("institution", currentUser.teacher_profile.institution[0].id);
          formData.append("files_count", files?.length);
          files?.forEach((file, index) => {
            formData.append(`file${index}`, file);
          });

          sendPictures(formData);

          setTimeout(() => {
            setSubmitting(false);
          }, 400);
          resetForm();
        }}
      >
        <Form className={styles["form"]}>
          <div className={styles["form__fields"]} onDragOver={onDragOver}
              onDrop={(e) => {
                e.preventDefault();
                handleFiles(Array.from(e.dataTransfer.files));
              }}>
            <p style={{textAlign: 'center', marginBottom: '15px'}}>
              Las imágenes no deben exceder los 10MB</p>
            <div className={styles.wrapper}>
                <form
                  action="#"
                  onClick={() => fileInputRef.current.click()}
                  encType="multipart/form-data"
                >
                 <input
                type="file"
                multiple
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={(e) => handleFiles(Array.from(e.target.files))}
              />
                  <i className="fas fa-cloud-upload-alt"></i>
                  <p>{`Arrastra tus imágenes aquí o has click para seleccionarlas`}</p>
                </form>
              
              {files?.length > 0 && (
                <section className={styles.uploadedarea}>
                  {files?.map((file, index) => {
                    return(
                      <>
                      {bytesToMegabytes(file?.size) <= 10 ?
                        <li className={styles.row}>
                          <div className={styles.content}>
                            <i
                              className="fas fa-file-alt"
                              style={{ fontSize: "20px" }}
                            ></i>
                            <div className={styles.details}>
                              <span
                                className={styles.name}
                              >{`${file?.name} • Cargado - Size: ${bytesToMegabytes(file?.size)?.toFixed(2)} MB`}</span> 
                            </div>
                          </div>
                          <i
                            className="fas fa-check"
                            style={{ fontSize: "20px" }}
                          ></i>
                          <i style={{ fontSize: "15px", color: 'var(--sec-red)', cursor: 'pointer' }} onClick={() => removeFile(index)}
                           class="fas fa-trash-alt"></i>
                        </li> : 
                        <li className={styles.row}>
                        <div className={styles.content}>
                          <i
                            className="fas fa-file-alt"
                            style={{ fontSize: "20px" }}
                          ></i>
                          <div className={styles.details}>
                            <span
                              className={styles.name}
                            >{`${file?.name} • Excede el tamaño permitido - Size: ${bytesToMegabytes(file?.size)?.toFixed(2)} MB`}</span> 
                          </div>
                        </div>
                        <i class="fas fa-times" style={{ fontSize: "20px", color: 'var(--sec-red)' }}></i>
                        <button onClick={() => removeFile(index)} 
                        style={{backgroundColor: '#fff', 
                        border: '1px solid var(--main-blue)', 
                        borderRadius: '10px', padding: '5px', cursor: 'pointer'}}>
                          Eliminar</button>
                      </li>}
                      </>
                    )
                  })}
                  {currentUser?.institution?.length > 1 && (
                    <div className={styles.institution}>
                      <MySelect label="Institución" name="institution">
                        {currentUser?.institution?.map((i) => (
                          <MenuItem value={i.id}>{i.name}</MenuItem>
                        ))}
                      </MySelect>
                    </div>
                  )}
                  {isSubmitEnabled && 
                  <MySubmitButton name="Cargar" type="submit" />}
                </section>
                )}
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
}

export default LoadPictures;
