import React from 'react';
import { 
    Document, 
    Page, 
    Text, 
    StyleSheet, 
    PDFViewer, 
    View, 
    Image, 
    Font 
} from '@react-pdf/renderer';
import OpenSansRegular from '../../fonts/OpenSans/OpenSans-Regular.ttf'
import { capitalizeWords } from '../../utils/capitalize';

Font.register({ family: 'OpenSans', src: OpenSansRegular });

const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      position: 'relative',
    },
    backgroundImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
    },
    contentContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    title: {
        textAlign:'center', 
        marginTop: 210, 
        fontSize: '28px', 
        color: '#412b88',
        fontFamily: 'OpenSans'
    },
    text:{
        fontSize: '14px', 
        textAlign: 'center', 
        marginTop: 30, width: '70%', 
        color: '#58595b',
        fontFamily: 'OpenSans'
    },
    text2:{
        fontSize: '14px', 
        textAlign: 'center', 
        marginTop: 15, width: '70%', 
        color: '#58595b',
        fontFamily: 'OpenSans'
    }
});

function Certificate({ person }) {
    if (!person) return null;

    let text = `Por cumplir con éxito los objetivos del programa de robótica ${person.programa} del año escolar 2023 - 2024, en el colegio ${capitalizeWords(person.colegio)}. Trabajando la creatividad, la resolución de problemas y el pensamiento crítico a lo largo de las clases.`

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.contentContainer}>
            <Image src={person.UCAB === "FALSE" ? "/template2.png" : "/template.png"} style={styles.backgroundImage} />
          <View style={styles.section}>
            <Text 
            style={styles.title}>
                {`${capitalizeWords(person.name)} ${capitalizeWords(person.lastName)}`}
            </Text>
            <div style={{width: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center'}}>
            <Text 
            style={styles.text}>
                {`${text}`}
            </Text>
            <Text 
            style={styles.text2}>
                {`Caracas, mes de julio del año 2024.`}
            </Text>
            </div>
            
          </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default Certificate
