import React, {useState, useEffect} from 'react'
import ReactDOM from "react-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Typography } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Modal from '../Modal'
import styles from './StudentsCertificates.module.css'
import { useLoading } from '../../context/LoadingContext'
import LoadingComponent from '../LoadingComponent'
import Certificate from './Certificate';
import { capitalizeWords } from '../../utils/capitalize';

function StudentsCertificates({setOpenCertificatedModal, openCertificatedModal}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState(false)
  const [studentData, setStudentData] = useState([]);
  const { showLoading, hideLoading, isLoading } = useLoading();

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        showLoading();
        const response = await fetch('https://kuriosedu.s3.us-east-2.amazonaws.com/app/studentData.json');
        const data = await response.json(); // asumiendo que el archivo es JSON
        setStudentData(data);
        hideLoading();
      } catch (error) {
        console.error('Error fetching student data:', error);
        hideLoading();
      }
    };

    fetchStudentData();
  }, []);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    showLoading()

    const term = searchTerm.toLowerCase()

    const filtered = studentData?.filter(person => {
      const cedulaSinPuntos = person?.cedula?.toString().replace(/\./g, '');
      return (
        person.correoPapa.toLowerCase().includes(term) ||
        person.correoMama.toLowerCase().includes(term) ||
        person.correoAlumno.toLowerCase().includes(term) ||
        cedulaSinPuntos.includes(term)
      );
    });
    setSearch(true)
    setFilteredData(filtered);
    hideLoading()
  };

  const openCertificateWindow = (result) => {
    const newWindow = window.open("", "_blank");
      newWindow.document.title = "Certificado Kurios";
      newWindow.document.body.innerHTML = `<div id="certificate-container"></div>`;

      ReactDOM.render(
        <Certificate person={result} />,
        newWindow.document.getElementById("certificate-container")
      );
  };

  return (
    <Modal modalOpen={setOpenCertificatedModal} title={"Certificados Estudiantes"}>
      <div
        style={{
          width: "100%",
          maxHeight: "80vh",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <p style={{textAlign: 'center', fontSize: '16px'}}>Para acceder al certificado, busque por número de cédula, correo electrónico del alumno o del representante</p>
        <input
        type="text"
        value={searchTerm}
        onChange={handleSearchTermChange}
        placeholder="Buscar"
        className={styles.input}
        />
        <button onClick={handleSearch} className={styles.button}>Buscar</button>
        {filteredData?.length > 0 ?
        <div className={styles['results-container']}>
          <p>Resultados</p>
          {filteredData?.map((person, index) => {
          return(
            <Box sx={{ minWidth: 275, marginTop: 1 }}>
              <Card variant="outlined" sx={{borderRadius: '10px'}}>
              <CardContent sx={{padding:'10px'}}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {capitalizeWords(person.colegio)}
                </Typography>
                <Typography variant="h5" component="div" sx={{fontWeight: 'bold'}}>
                  {`${capitalizeWords(person.name)} ${capitalizeWords(person.lastName)}`}
                </Typography>
                <Typography variant="h5" component="div">
                  {`${person.grado} - ${person.programa}`}
                </Typography>
              </CardContent>
              <CardActions sx={{display:'flex', justifyContent:'flex-end', paddingTop: 0}}>
                <Button size="large" onClick={() => openCertificateWindow(person)} sx={{fontSize:'13px', borderRadius: '10px'}}>
                  Ver Certificado
                </Button>
              </CardActions>

              </Card>

            </Box>
          )})}
        </div> : 
        <>
        {search && <h3 style={{textAlign: 'center'}}>No se encontró ningún estudiante</h3>}
        </>
        }

      </div>
      {isLoading && <LoadingComponent />}
    </Modal>
  )
}

export default StudentsCertificates
