import { useState, createContext, useContext, useEffect } from "react";
import { get, post, patch, deleted } from "../utils/http";
import {
    CAPACITACION_ENDPOINT,
    ASIGNACION_ENDPOINT,
    QUIZ_QUESTIONS_ENDPOINT,
    QUIZ_ENDPOINT,
    QUIZ_ANSWERS_ENDPOINT,
} from "../utils/constants";
import useLocalStorage from "../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "./CurrentUserProvider";
import { AlertsContext } from "./AlertsContext";

const CapacitacionesContext = createContext();

const CapacitacionesProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [capacitaciones, setCapacitaciones] = useState([]);
  const [questions, setQuestions] = useState([])
  const [quices, setQuices] = useState([])
  const [assignments, setAssignments] = useState([]);
  const [quizAnswers,setQuizAnswers] = useState([])
  const [selectedCapacitacion, setSelectedCapacitacion] = useLocalStorage(
    "selectedCapacitacion",
    ""
  );
  
  const showSnackbar = useContext(AlertsContext);

  const { currentUser } = useCurrentUser();

  const navigate = useNavigate();

  const fetchQuestions = async () => {
    const url = QUIZ_QUESTIONS_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setQuestions(response.data);
    }
  };

  const fetchQuices = async () => {
    const url = QUIZ_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setQuices(response.data);
    }
  };

  const createCapacitacion = async ({ data }) => {
    const url = CAPACITACION_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Se ha creado la capacitación", "success");
      fetchCapacitaciones();
    } else {
      showSnackbar("Hubo un error al crear la capacitación", "error");
    }
  };

  const createQuiz = async ({ data }) => {
    const url = QUIZ_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Se ha creado el quiz", "success");
      fetchQuices();
    } else {
      showSnackbar("Hubo un error al crear el quiz", "error");
    }
  };

  const createQuestion = async ({ data }) => {
    const url = QUIZ_QUESTIONS_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Se ha creado la pregunta", "success");
      fetchQuestions();
    } else {
      showSnackbar("Hubo un error al crear la pregunta", "error");
    }
  };

  const fetchQuizAnswers = async () => {
    const url = QUIZ_ANSWERS_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setQuizAnswers(response.data);
    }
  };

  const createQuizAnsw = async ({ data }) => {
    const url = QUIZ_ANSWERS_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Se ha guardado la respuesta", "success");
      fetchQuizAnswers();
    } else {
      showSnackbar("Hubo un error al guardar la respuesta", "error");
    }
    return response;
  };

  const updateCapacitacion = async ({ trainingId, data }) => {
    const url = `${CAPACITACION_ENDPOINT}${trainingId}/`;

    const response = await patch({ url, data });
    if (!response.error) {
      showSnackbar("Se ha editado la capacitación", "success");
      fetchCapacitaciones();
    } else {
      showSnackbar("Hubo un error al editar la capacitación", "error");
    }
  };

  const fetchCapacitaciones = async () => {
    const url = CAPACITACION_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setCapacitaciones(response.data);
    }
  };

  const fetchAssignments = async () => {
    const url = ASIGNACION_ENDPOINT;
    const response = await get({ url });

    if (!response.error) {
        setAssignments(response);
    }
  };

  const sendAssignment = async ({ data }) => {
    const url = ASIGNACION_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Asignación enviada satisfactoriamente", "success");
      fetchAssignments();
    } else {
      showSnackbar("Hubo un error al enviar la asignación", "error");
    }
  };

  const reviewTask = async ({ taskId, values }) => {
    const url = `${ASIGNACION_ENDPOINT}${taskId}/`;
    const data = { ...values };
    const response = await patch({ url, data });
    if (!response.error) {
      showSnackbar('Se ha corregido la asignación', 'success')
      fetchAssignments();
    }else{
      showSnackbar('Hubo un error al corregir la asignación', 'error');
    }
  };

  useEffect(() => {
  }, [currentUser]);

  const contextData = {
    sendAssignment,
    fetchAssignments,
    fetchCapacitaciones,
    createCapacitacion,
    assignments,
    setAssignments,
    capacitaciones,
    setCapacitaciones,
    setSelectedCapacitacion,
    selectedCapacitacion,
    reviewTask,
    updateCapacitacion,
    createQuestion,
    fetchQuestions,
    createQuiz,
    createQuizAnsw
  };

  return (
    <CapacitacionesContext.Provider value={contextData}>
      {loading ? null : children}
    </CapacitacionesContext.Provider>
  );
};

const useForms = () => {
  return useContext(CapacitacionesContext);
};

export { CapacitacionesContext, CapacitacionesProvider, useForms };
