import React, { useContext, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Survey from "survey-react";
import { FormsContext } from "../../context/FormsContext";
import Container from "../Container";
import styles from "./SurveyView.module.css";
import { BASE_URL } from "../../utils/constants";
import { get } from "../../utils/http";
import "./SurveyStyles.css";
import "survey-react/survey.css";
import LoadingComponent from "../LoadingComponent";
import { useLoading } from "../../context/LoadingContext";

export const themeJson = {
  "cssVariables": {},
  "isPanelless": false
}

Survey.StylesManager.applyTheme("default");

let defaultThemeColors = Survey.StylesManager.ThemeColors["default"];
defaultThemeColors["$main-color"] = "var(--main-blue)";
defaultThemeColors["$main-hover-color"] = "var(--ter-blue)";

function SurveyView() {
  const { submitAswers } = useContext(FormsContext);
  const [data, setData] = useState(null);
  const [jsonSurvey, setJsonSurvey] = useState({})
  const [userForms, setUserForms] = useState([])
  const { showLoading, hideLoading, isLoading } = useLoading();

  useEffect(() => {
    showLoading()
    const fetchForms = async () => {
      const url = `${BASE_URL}/accounts/forms/`;
      const response = await get({ url });

      if (!response.error) {
        setUserForms(response.data);
        Dataset(response.data);
        hideLoading()
      }else{
        hideLoading()
      }
    };
    fetchForms();
  }, []);

  const { templateId } = useParams();

  const Dataset = (userForms) => {
    
    const opt = []

    userForms?.forEach((template) => {
      if (template.id === parseInt(templateId)) {
        setData(template);
        
        opt.push(template.template_questions)
      }
    });
    
    surveyquestions(opt)
    
    hideLoading()

  };

  const navigate = useNavigate();

  //const questions = data?.template_questions;
 
  const surveyquestions = (questions) => {
    const json = {
      elements: [],
    };

    questions[0]?.forEach((question) => {
      let questionType;
      const options = [];
      if (question.options_type === null || question.options_type === 0) {
        questionType = "text";
      } else {
        if (question.options_type === 1) {
          questionType = "radiogroup";
        } else {
          questionType = "checkbox";
        }
        question?.question_options?.forEach((opt) => {
          options?.push(opt.content);
        });
      }


      const qst =
      questionType === "text"
          ? {
              type: questionType,
              name: question.id.toString() || '',
              title: question.description,
              isRequired: true,
              requiredErrorText: "Value cannot be empty",
            }
          : {
              type: questionType,
              name: question.id.toString() || '',
              title: question.description,
              choices: options,
              isRequired: true,
              requiredErrorText: "Value cannot be empty",
            };
      json?.elements?.push(qst);
      
    });
    setJsonSurvey(json)
  }

  const survey = new Survey.Model(jsonSurvey);

  survey.completedHtml = "<p>¡Gracias por completar la encuesta!</p>";

  const alertResults = useCallback((sender) => {
    showLoading()
    const results = sender.data;
    const answersData = {
      user_form_id: parseInt(templateId),
      answers: [],
    };
    Object.entries(results).map(([key, value]) => {
      const data = {
        question_id: parseInt(key),
        content: value,
      };
      answersData.answers.push(data);
    });
    submitAswers({ data: answersData });
    hideLoading()
  }, []);
  survey.onComplete.add(alertResults);

  return (
    <Container>
      <div className={styles.survey_content}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
        <div style={{ width: "100%", maxHeight: '80vh', overflowY: 'scroll' }}>
          <Survey.Survey model={survey} />
        </div>
      </div>
      {isLoading && <LoadingComponent />}
    </Container>
  );
}

export default SurveyView;
