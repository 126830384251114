import React, { useContext, useEffect, useState, useRef} from "react";
import { Formik, Form } from "formik";
import { MyTextInput, MySubmitButton, MyCheckbox } from "../FormFields";
import * as Yup from "yup";
import styles from "./UpdateProfileForm.module.css";
import { CurrentUserContext } from "../../context/CurrentUserProvider";
import { UsersContext } from "../../context/UsersProvider";
import { useOutletContext } from "react-router-dom";

const UpdateProfileForm = ({setUpdateUserInfo}) => {
  const { updateUser, fetchSingleUser } = useContext(UsersContext);
  const { currentUser } = useContext(CurrentUserContext);
  const [file, setFile] = useState(null)

  const fileInputRef = useRef(null);

  const outletContext = useOutletContext();
  const [user, setUser] = useState(outletContext?.selectedUser ? outletContext?.selectedUser[0] : null);

  useEffect(() => {
    if (!user) {
      const fetchUser = async () => {
        const Id = currentUser?.id;
        const response = await fetchSingleUser(Id); 
        setUser(response);
      };
      fetchUser();
    }
  }, [user, fetchSingleUser]);

  if (!user) {
    return <div>Loading...</div>;
  }

  const userId = user?.id;

  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file)
  };

  return (
    <div className={styles["update-profile"]}>
      <div className={styles["update-profile__form"]}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            first_name: user["first_name"],
            last_name: user["last_name"],
            email: user["email"],
            employee_id: user["employee_id"],
            teacher_id: user["employee_id"],
            is_staff: user["is_staff"],
            is_active: user["is_active"],
            number_phone: user["number_phone"],
            dni: user["dni"],
            instagram_handle: user["instagram_handle"],
          }}
          validationSchema={Yup.object({
            first_name: Yup.string()
              .min(2, "Debe contener al menos 2 caracteres.")
              .required("Campo requerido"),
            last_name: Yup.string()
              .min(3, "Debe contener mas de 3 caracteres.")
              .required("Campo requerido"),
            email: Yup.string()
              .email()
              .min(2, "Debe ingresar un email valido.")
              .required("Campo requerido"),
            number_phone: Yup.number()
              .min(2, "Debe ingresar un telefono valido.")
              .required("Campo requerido"),
              dni: Yup.number()
              .min(5, "Debe ingresar una cédula valida.")
              .required("Campo requerido"),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setTimeout(() => {
              const formData = new FormData();
              formData.append("first_name", values.first_name);
              formData.append("last_name", values.last_name);
              formData.append("email", values.email);
              formData.append("dni", values.dni);
              formData.append("number_phone", values.number_phone);
              formData.append("instagram_handle", values?.instagram_handle);
              if(file){
                formData.append("img_profile", file);
              }
              updateUser({ userId, data: formData });
              if(setUpdateUserInfo){
                setUpdateUserInfo(false)
              }
              setSubmitting(false);
            }, 400);
            resetForm();
          }}
        >
          <Form className={styles["form__fields"]}>
            <div className={styles.inputs}>
              <MyTextInput
                label="Nombre"
                name="first_name"
                type="text"
                placeholder="Nombre"
                icon="fa fa-user"
              />
              <MyTextInput
                label="Apellido"
                name="last_name"
                type="text"
                placeholder="Apellido"
                icon="fa fa-user"
              />
            </div>
            <div className={styles.inputs}>
              <MyTextInput
                label="Correo electrónico"
                name="email"
                type="text"
                placeholder="hola@tecnikids.com"
                icon="fa fa-key
            "
              />
              <MyTextInput
                label="Teléfono"
                name="number_phone"
                type="text"
                placeholder=""
                icon="fa fa-key
            "
              />
            </div>
            <div className={styles.inputs}>
              <MyTextInput
                label="Número de Cédula"
                name="dni"
                type="text"
                icon="fa fa-key
            "
              />
              <MyTextInput
                label="Instagram"
                name="instagram_handle"
                type="text"
                icon="fa fa-key
            "
              />
              
            </div>
            <div className={styles.inputs}>
            <div className={styles["profile-icon-edit"]}>
              {file ? 
              <img src={file} 
              style={{width: '60px', borderRadius: '50%', height: '60px'}}/> : 
              <>
              {user?.img_profile ? 
                <img src={user?.img_profile} 
                style={{width: '60px', borderRadius: '50%', height: '60px'}}/> :
                <i  style={{fontSize: '60px'}} className="fas fa-user-circle"></i>}
              </>
              }
              <button type="button" className={styles["edit-button"]} onClick={handleEditClick}>
                Cambiar foto de perfil
              </button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }} 
            /></div>

            </div>
            
            {currentUser?.role === "IT" && 
            <div className={styles.inputs}>
              <MyCheckbox label="Usuario activo" name="is_active">
                Usuario activo
              </MyCheckbox>
              <MyCheckbox label="Staff" name="is_staff">
                Staff
              </MyCheckbox>
            </div>}
            <div style={{marginTop: '15px'}}>
            <MySubmitButton name="Actualizar" type="submit" />
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default UpdateProfileForm;