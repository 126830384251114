import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import Modal from '../Modal';
import './ConfidentialityAgreementModal.css';
import { CurrentUserContext } from '../../context/CurrentUserProvider';
import { UsersContext } from '../../context/UsersProvider';
import { useLoading } from '../../context/LoadingContext'; 
import LoadingComponent from '../LoadingComponent';

const ConfidentialityAgreementModal = ({ setOpenAgreement }) => {
  const [htmlContent, setHtmlContent] = useState(null);
  const [checked, setChecked] = useState(false); 
  const { currentUser, acceptAgreement } = useContext(CurrentUserContext);
  const { fetchSingleUser } = useContext(UsersContext);
  const { showLoading, hideLoading } = useLoading();
  const hasFetched = useRef(false); // Control de carga


  useEffect(() => {
    const fetchHTML = async () => {
      if (hasFetched.current) 
        return;
      try {
        showLoading();
        const response = await fetch('https://kuriosedu.s3.us-east-2.amazonaws.com/app/acuerdo.+grupo+kurios.html');
        if (response.ok) {
          const text = await response.text();
          setHtmlContent(text);
          hasFetched.current = true;
        }
      } catch (error) {
        console.error('Error fetching HTML file:', error);
        hideLoading();
      }
      finally {
        hideLoading();
      }
    };

    fetchHTML();
  }, []);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleAccept = () => {
    if (checked) {
      acceptAgreement();
      setOpenAgreement(false);
    } else {
      alert("Debes aceptar el acuerdo antes de continuar.");
    }
  };

  const handleCancel = () => {
    setOpenAgreement(false);
  };

  return (
    <Modal title={"Acuerdo de Confidencialidad"} modalOpen={setOpenAgreement}>
      <div className="pdf-container">
        {htmlContent ? (
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        ) : (
          <LoadingComponent />
        )}
      </div>

      <div className="buttons-container">
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleCheckboxChange} sx={{ '& .MuiSvgIcon-root': { fontSize: 20 }, marginBottom: '5px' }} />}
          label={`Yo ${currentUser?.first_name} ${currentUser?.last_name} he leído y acepto los términos del acuerdo de confidencialidad`}
          className="custom-label"
        />
        <div className="buttons">
          <Button
            variant="contained"
            color="primary"
            onClick={handleAccept}
            disabled={!checked}
            fullWidth
            style={{ marginRight: '10px', borderRadius: '20px' }}
          >
            Aceptar
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
            fullWidth
            style={{ borderRadius: '20px' }}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfidentialityAgreementModal;
