import React, {useCallback, useContext, useEffect} from 'react'
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { dateFormatter } from '../../../utils/dateFormatter';
import { EventsContext } from '../../../context/EventsProvider';
import Container from '../../Container';
import {Box, IconButton, Tooltip} from '@mui/material';
import styles from '../Events.module.css';

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};
const typeofEvents = (type) => {
  switch (type) {
    case 'Lost Equipment':
      return 'Equipo Perdido';
    case 'Damaged Equipment':
      return 'Equipo Dañado';
    case 'Other':
      return 'Otro';
    case 'Meeting':
      return 'Reunión';
    case 'Chat':
      return 'Chat';
    case 'Call':
      return 'Llamada';
  }
};
const getStatusIcon = (status) => {
  switch (status) {
    case 'Pendiente':
      return (
        <div style={{
          backgroundColor: 'var(--sec-orange)', 
          color: '#fff',
          padding: '5px 10px',
          borderRadius: '20px',
          fontSize: '14px',
        }}>
          Pendiente
        </div>
      );
    case 'En Proceso':
      return (
        <div style={{
          backgroundColor: 'var(--sec-blue)',
          color: '#fff',
          padding: '5px 10px',
          borderRadius: '20px',
          fontSize: '14px',
        }}>
          En Proceso
        </div>
      );
    case 'Resuelto':
      return (
        <div style={{
          backgroundColor: 'var(--sec-green)',
          color: '#fff',
          padding: '5px 10px',
          borderRadius: '20px',
          fontSize: '14px',
        }}>
          Resuelto
        </div>
      );
    case 'Cerrado':
      return (
        <div style={{
          backgroundColor: 'var(--sec-red)',
          color: '#fff',
          padding: '5px 10px',
          borderRadius: '20px',
          fontSize: '14px',
        }}>
          Cerrado
        </div>
      );
  }
};
function AllViewListExternal() {
  const {fetchEvents, events, dataAll, fetchNextPage, fetchPreviousPage, pagePre, pageNext, currentPage, totalPages, fetchForSearch } = useContext(EventsContext);
  const [filterInstitution, setFilterInstitution] = React.useState('');
  const [filterStatus, setFilterStatus] = React.useState('');
  const [filterType, setFilterType] = React.useState('');
  const [filterArea, setFilterArea] = React.useState('');

  useEffect(() => {
    fetchEvents();
  }, []);

  const navigate = useNavigate();

  const handleRowClick = (row) => {
    if (row.type_event === 'Solicitud') {
      navigate(`/events/request/${row.id}`, { relative: false });
    }
    if (row.type_event === 'Evento') {
      navigate(`/events/event/${row.id}`, { relative: false });
    }
    if (row.type_event === 'Incidencia') {
      navigate(`/events/incidence/${row.id}`, { relative: false });
    }
  };
  
  const columns = [
    {
      name: "Reportado por",
      selector: (row) => row.report_person?.first_name + " " + row.report_person?.last_name,
      sortable: true,
      cell: (cell) => {
        const fullName = `${cell.report_person?.first_name || ''} ${cell.report_person?.last_name || ''}`.trim();
        return fullName ? <p>{fullName}</p> : 'Por asignar';
      }
    },
    {
      name: "Institución",
      selector: (row) => row.institution?.name,
      sortable: true,
      cell: (cell) => <div>{cell.institution?.name}</div>
  },
  {
      name: "Estado",
      selector: (row) => row.status,
      sortable: true,
      cell: (cell) => getStatusIcon(cell.status)       

    },
    {
      name: "Tipo ",
      selector: (row) => row.type_event,
      sortable: true,
      cell: (cell) => <div>{cell.type_event}</div>
    },
    {
        name: "Detalles",
        selector: (row) => row.incident_type,
        sortable: true,
        cell: (cell) => <div>{typeofEvents(cell.incident_type)}</div>
    },
    {
      name: "Programa",
      selector: (row) => row.program?.name,
      sortable:true,
      cell: (cell) => <div>{cell.program?.name}</div>

    },
    {
      name: "Area Responsable",
      selector: (row) => row.area_responsible,
      sortable: true,
      cell: (cell) => 
      {
        let area = ""
        switch (cell.area_responsible) {
          case "Administration"
            : area = "Administración"
            break;
          case "Logistics and Inventory"
            : area = "Logística y Inventario"
            break;
          case "Human Resources"
            : area = "Recursos Humanos"
            break;
          case "IT"
            : area = "IT"
            break;
          case "Post Sales"
            : area = "Post Venta"
            break;
          case "Sales"
            : area = "Ventas"
            break;
          case "Monitoring"
            : area = "Seguimiento"
            break;
          case "Other"
            : area = "Otro"
            break;

      }

        return <div>{area}</div>
      }
      
    },
    {
      name: "Fecha",
      selector: (row) => row.event_date,
      cell: (cell) =>(
        <p>{dateFormatter(new Date(cell.event_date))}</p>
      )
    },
  ];

  const handleSearchType = (event) => {
    setFilterType(event.target.value);
  };

  const handleSearchInstitution = (event) => {
    setFilterInstitution(event.target.value);
  };

  const handleSearchStatus = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleSearchArea = (event) => {
    setFilterArea(event.target.value);
  };
  const handleAllSearch = () => {

    const params  = []
    if (filterInstitution) {
      params.push(`institution=${filterInstitution}`);
    }
    if (filterType) {
      params.push(`type=${filterType}`);
    }
    if (filterStatus) {
      params.push(`status=${filterStatus}`);
    }
    if (filterArea) {
      params.push(`area=${filterArea}`);
    }
    const url = params.join('&');
    fetchForSearch(url);
  }

  useEffect(() => {
    handleAllSearch();
  }, [filterInstitution,filterType, filterStatus, filterArea]);

  return (
    <Container >
        <i
      className="fal fa-arrow-left"
      style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
      onClick={() => navigate(-1)}
      ></i>
      <div className={styles.table}>
        <div className={styles["pagination"]} style={{ marginTop: "12px" }}>
          <i className="fas fa-arrow-left"
            style={{ fontSize: "16px", cursor: "pointer", marginLeft: "5px", marginRight: "5px", color: "var(--main-blue)" }}
            onClick={fetchPreviousPage}></i>
          <span>{`Página ${currentPage}  de ${totalPages}`}</span>
          <i className="fas fa-arrow-right"
            style={{ fontSize: "16px", cursor: "pointer", marginLeft: "5px", marginRight: "5px", color: "var(--main-blue)" }}
            onClick={fetchNextPage}></i>
        </div>
        <div className={styles.cont2}>
          <div className={styles["search-bar"]}>
              <select
                value={filterType}
                className={styles.filter_text}
                onChange={handleSearchType}
              >
                <option value={''}>Filtrar por tipo de evento</option>
                <option value={'Incidencia'}>Incidencia</option>
                <option value={'Evento'}>Evento</option>
                <option value={'Solicitud'}>Solicitud</option>
              </select>
        </div>
        <div className={styles["search-barthree"]}>
              <select
                value={filterStatus}
                className={styles.filter_text}
                onChange={handleSearchStatus}
              >
                <option value={''}>Filtrar por estado</option>
                <option value={'Pendiente'}>Pendiente</option>
                <option value={'En Proceso'}>En proceso</option>
                <option value={'Resuelto'}>Resuelto</option>
                <option value={'Cerrado'}>Cerrado</option>
              </select>
        </div>
        <div className={styles["search-barfour"]}>
              <select
                value={filterArea}
                className={styles.filter_text}
                onChange={handleSearchArea}
              >
                <option value={''}>Filtrar por Área</option>
                <option value={'IT'}>IT</option>
                <option value={'Administration'}>Administración</option>
                <option value={'Logistics and Inventory'}>Logística e Inventario</option>
                <option value={'Human Resources'}>Recursos Humanos</option>
                <option value={'Post Sales'}>Post Venta</option>
                <option value={'Sales'}>Ventas</option>
                <option value={'Monitoring'}>Seguimiento</option>
                <option value={'Other'}>Otro</option>
              </select>
        </div>
          <div className={styles["search-bartwo"]}>
            <input 
              type="text"
              placeholder="Filtrar por institución"
              value={filterInstitution}
              onChange={handleSearchInstitution}
              className={styles.filter_text}
            />
          </div>
        </div>
        <Box  sx={{ width: '100%', marginRight: '10px' }}>
          <div style={{borderRadius: '10px'}}>
          <DataTable
              columns={columns}
              data={events}
              customStyles={customStyles}
              sortIcon={
              <i
                  className="far fa-arrow-up"
                  style={{ marginLeft: "5px", fontSize: "14px" }}
              ></i>
              }
              highlightOnHover
              fixedHeader
              pointerOnHover
              onRowClicked={(row) => handleRowClick(row)}
              noDataComponent="No hay eventos"
              fixedHeaderScrollHeight="500px"
          />
          </div>
      </Box>
    </div>
  </Container>
  )
}

export default AllViewListExternal
