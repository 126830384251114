import React from "react";
import Modal from "./Modal";

function DiplomadoSTEM({ setOpenModal }) {
  return (
    <Modal modalOpen={setOpenModal} title={"Diplomado STEM"}>
      <div
        style={{
          width: "100%",
          maxHeight: "80vh",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <p>{`Disponible para todos los docentes de la institución en el que aprenderán a integrar prácticas e indicadores de STEM en todas las materias para convertirse en el maestro del siglo XXI. Al participar, obtienen la certificación de la Asociación Americana de Robótica y Tecnología, el cual es un certificado internacional.`}</p>
        <p>
          <span style={{ fontWeight: "bold" }}>Duración:</span> 3 meses (aprox)
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Modalidad:</span> Aprendizaje
          autónomo con apoyo de docentes de Kurios Education y la Asociación
          Americana de Robótica y Tecnología.
        </p>
        <div
          style={{ width: "100%", justifyContent: "center", display: "flex" }}
        >
          <a
            href="https://forms.gle/TQdgGfbbBS3N6QEd9"
            target="_blank"
            rel="noreferrer"
            style={{
              background: "#330072",
              color: "#ffffff",
              // cursor: "not-allowed",
              borderRadius: "10px",
              padding: "10px",
              width: "fit-content",
            }}
          >
            Inscribirse
          </a>
        </div>
      </div>
    </Modal>
  );
}

export default DiplomadoSTEM;
