import React, {useState, useContext} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MyTextInput, MySubmitButton } from "../FormFields";
import styles from './LessonsList.module.css'
import Modal from '../Modal';
import { ProgramsContext } from '../../context/ProgramsContext';
  

function EditLesson({setModalOpen, initialValues, lessonId, fetchLessons}) {
    const {updateLesson} = useContext(ProgramsContext);
    const [beginningText, setBeginningText] = useState(initialValues?.beginning?.replace(/^"|"$/g, ''));
    const [developmentText, setDevelopmentText] = useState(initialValues?.development?.replace(/^"|"$/g, ''));
    const [closureText, setClosureText] = useState(initialValues?.closure?.replace(/^"|"$/g, ''));
    const [achievementindicatorsText, setAchievementindicatorsText] = useState(initialValues?.achievement_indicators?.replace(/^"|"$/g, ''));

    const handleChangeBeginning = (content) => {
      setBeginningText(content);
    };
    const handleChangeDevelopment = (content) => {
      setDevelopmentText(content);
    };
    const handleChangeClosure = (content) => {
      setClosureText(content);
    };
    const handleChangeAchievementindicators = (content) => {
      setAchievementindicatorsText(content);
    };

    const handleEditLesson = async (values) => {
        const data = {
          beginning: JSON.stringify(beginningText),
          development: JSON.stringify(developmentText),
          closure: JSON.stringify(closureText),
          achievement_indicators: JSON.stringify(achievementindicatorsText),
          title: values.title,
          licens: values.licens,
          learning_subject: values.learning_subject,
          learning_topic:values.learning_topic,
          lesson_goal: values.lesson_goal,
          learning_outcomes: values.learning_outcomes,
          generating_topic: values.generating_topic,
          theoric_references: values.theoric_references,
          curriculum_content: values.curriculum_content,
          script: values.script,
          slides: values.slides,
          main_activities: values.main_activities,
          reference_number:values.reference_number
        };
        
        updateLesson({ lessonId, data });
        fetchLessons()
        setModalOpen(false)
    }

  return (
    <Modal title={"Editar Clase"} modalOpen={setModalOpen}>
      <div className={styles.content}>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          title: Yup.string()
            .min(1, "El campo no puede estar vacío.")
            .required(),
          description: Yup.string().min(
            2,
            "Debe contener más de 2 caracteres."
          ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setTimeout(() => {
            handleEditLesson(values);
            setSubmitting(false);
            setModalOpen(false)
          }, 400);
          resetForm();
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className={styles["form__fields"]}>
            <div className={styles.inputs}>
              <MyTextInput
                label="Título"
                name="title"
                type="text"
                placeholder="Título"
                icon="fa fa-user"
              />
              <MyTextInput
                label="Licencia"
                name="licens"
                type="text"
                placeholder="Licencia"
                icon="fa fa-user"
              />
              <MyTextInput
                label="Área de aprendizaje"
                name="lerning_subject"
                type="text"
                placeholder="Área de aprendizaje"
                icon="fa fa-user"
              />
              <MyTextInput
                label="learning_topic"
                name="learning_topic"
                type="text"
                placeholder="learning_topic"
                icon="fa fa-user"
              />
            </div>
            <div className={styles.inputs}>
              <MyTextInput
                label="Objetivos de la sesión"
                name="lesson_goal"
                type="text"
                placeholder="Objetivos de la sesión"
                icon="fa fa-user"
              />
              <MyTextInput
                label="learning_outcomes"
                name="learning_outcomes"
                type="text"
                placeholder="learning_outcomes"
                icon="fa fa-user"
              />
              <MyTextInput
                label="generating_topic"
                name="generating_topic"
                type="text"
                placeholder="generating_topic"
                icon="fa fa-user"
              />
              <MyTextInput
                label="theoric_references"
                name="theoric_references"
                type="text"
                placeholder="theoric_references"
                icon="fa fa-user"
              />
            </div>
            <div className={styles.inputs}>
                
              <MyTextInput
                label="Contenido curricular"
                name="curriculum_content"
                type="text"
                placeholder="Contenido curricular"
                icon="fa fa-user"
              />
              <MyTextInput
                label="Script"
                name="script"
                type="text"
                placeholder="Script"
                icon="fa fa-user"
              />
              <MyTextInput
                label="Presentaciones"
                name="slides"
                type="text"
                placeholder="Presentaciones"
                icon="fa fa-user"
              />
              <MyTextInput
                label="main_activities"
                name="main_activities"
                type="text"
                placeholder="main_activities"
                icon="fa fa-user"
              />
            </div>
            <div className={styles.inputs}>
              <div className={styles.texteditor} >
                <p style={{color: '#000', fontSize: '1.5rem'}}>Comienzo </p>
                <div style={{maxHeight: '250px', overflowY: 'scroll'}}>
                  <ReactQuill value={beginningText} 
                  onChange={handleChangeBeginning} />
                </div>
              </div>
              <div className={styles.texteditor}>
                <p style={{color: '#000', fontSize: '1.5rem'}}>Desarrollo </p>
                <div style={{maxHeight: '250px', overflowY: 'scroll'}}>
                <ReactQuill value={developmentText} 
                onChange={handleChangeDevelopment} />
                </div>
              </div>
            </div>
            <div className={styles.inputs}>
              <div className={styles.texteditor}>
                <p style={{color: '#000', fontSize: '1.5rem'}}>Cierre </p>
                <div style={{maxHeight: '250px', overflowY: 'scroll'}}>
                <ReactQuill value={closureText} 
                onChange={handleChangeClosure} />
                </div>
              </div>
              <div className={styles.texteditor}>
                <p style={{color: '#000', fontSize: '1.5rem'}}>Indicadores de logro </p>
                <div style={{maxHeight: '250px', overflowY: 'scroll'}}>
                <ReactQuill value={achievementindicatorsText} 
                onChange={handleChangeAchievementindicators} />
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <MySubmitButton name="Editar" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
      </div>
    </Modal>
  )
}

export default EditLesson
