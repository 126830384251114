export const INSTITUTIONS_OPTIONS = [
  {
    key: "list",
    name: "Instituciones",
    icon: <i className="fad fa-school"></i>,
    route: "list",
  },
  {
    key: "create",
    name: "Crear institución",
    icon: <i className="fas fa-plus-square"></i>,
    route: "create",
  },
  {
    key: "upload",
    name: "Creación Masiva",
    icon: <i className="fas fa-folder-plus"></i>,
    route: "upload",
  },
];

export const INSTITUTION_OPT = [
  {
    key: "docentes",
    name: "Docentes",
  },
  {
    key: "cursos",
    name: "Cursos",
  },
  {
    key: "grados",
    name: "Grados",
  },
  {
    key: "horarios",
    name: "Horarios",
  },
];
export const INSTITUTION_OPT_COORDINATOR = [
  {
    key: "docentes",
    name: "Docentes",
  },
  {
    key: "cursos",
    name: "Cursos",
  },
];

export const REFERENCE_LEVELS = [
  {
    key: "maternal",
    stage: "Preescolar",
    name: "Maternal",
  },
  {
    key: "1er Nivel",
    stage: "Preescolar",
    name: "1er Nivel",
  },
  {
    key: "2do Nivel",
    stage: "Preescolar",
    name: "2do Nivel",
  },
  {
    key: "3er Nivel",
    stage: "Preescolar",
    name: "3er Nivel",
  },
  {
    key: "1er Grado",
    stage: "Primaria Menor",
    name: "1er Grado",
  },
  {
    key: "2do Grado",
    stage: "Primaria Menor",
    name: "2do Grado",
  },
  {
    key: "3er Grado",
    stage: "Primaria Menor",
    name: "3er Grado",
  },
  {
    key: "4to Grado",
    stage: "Primaria Mayor",
    name: "4to Grado",
  },
  {
    key: "5to Grado",
    stage: "Primaria Mayor",
    name: "5to Grado",
  },
  {
    key: "6to Grado",
    stage: "Primaria Mayor",
    name: "6to Grado",
  },
  {
    key: "1er Año",
    stage: "Bachillerato",
    name: "1er Año",
  },
  {
    key: "2do Año",
    stage: "Bachillerato",
    name: "2do Año",
  },
  {
    key: "3er Año",
    stage: "Bachillerato",
    name: "3er Año",
  },
  {
    key: "4to Año",
    stage: "Bachillerato",
    name: "4to Año",
  },
  {
    key: "5to Año",
    stage: "Bachillerato",
    name: "5to Año",
  },
];

export const STAGES = [
  {
    key: "Preescolar",
    stage: "PREESCOLAR",
    name: "Preescolar",
  },
  {
    key: "Primaria Menor",
    stage: "PRIMARIA_MENOR",
    name: "Primaria Menor",
  },
  {
    key: "Primaria Mayor",
    stage: "PRIMARIA_MAYOR",
    name: "Primaria Mayor",
  },
  {
    key: "Bachillerato",
    stage: "BACHILLERATO",
    name: "Bachillerato",
  },
];
