import React, { useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CurrentUserContext } from "./CurrentUserProvider";

export const AuthProvider = ({ children }) => {
  const { setValidToken } = useContext(CurrentUserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            setValidToken(false);
            navigate('/login');
          }
        } else {
          console.log("Error sin respuesta HTTP:", error);
        }
        return Promise.reject(error);
      }
    );
  
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [navigate, setValidToken]);
  

  return <>{children}</>;
};