import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import styles from "./Reminders.module.css";
import Reminder from "./Reminders/Reminder";
import { timeLeftFormatter } from "../utils/timestampFormatter";
import { BASE_URL } from "../utils/constants";
import { get } from "../utils/http";
import { CurrentUserContext } from "../context/CurrentUserProvider";
import { CAPACITACION_ENDPOINT } from "../utils/constants";
import LoadPictures from "./LoadPictures";
import { AnnualTrainingsContext } from "../context/AnnualTrainingsContext";

const Reminders = () => {
  const [userForms, setUserForms] = useState([]);
  const [capacitaciones, setCapacitaciones] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const {fetchAnnualTrainings, annualTrainings} = useContext(AnnualTrainingsContext)
  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    fetchAnnualTrainings();
  }, []);

  useEffect(() => {
    const fetchForms = async () => {
      const url = `${BASE_URL}/accounts/forms/`;
      const response = await get({ url });
      if (!response.error) {
        setUserForms(response.data);
      }
    };
    fetchForms();
  }, []);
  useEffect(() => {
    const fetchCapacitaciones = async () => {
      const url = `${CAPACITACION_ENDPOINT}my_trainings/`;
      const response = await get({ url });
      setCapacitaciones(response.data);
    };
    fetchCapacitaciones();
  }, []);

  userForms?.sort((a, b) => {
    if (a.answered_at !== null) {
      return 1;
    } else if (b.answered_at === null) {
      return -1;
    } else {
      return 0;
    }
  });

  useEffect(() => {
    if (userForms.length > 0 && capacitaciones.length > 0) {
      setLoading(false);
    }
  }, [userForms, capacitaciones]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Timeout de 3 segundos para manejar casos donde ambos arrays estén vacíos
    return () => clearTimeout(timer);
  }, []);

  const currentDate = new Date();

  const trainingsWithNullSessions = annualTrainings?.map((training) => {
    const filteredSessions = training?.sessions?.filter((session) => {
      const sessionDate = new Date(session.date);
      return sessionDate >= currentDate && session?.teacher?.some((teacher) =>
        teacher.id === currentUser?.id && (teacher?.attendance_status === null || teacher?.attendance_status === 'pending')
      );
    })?.sort((a, b) => new Date(a.date) - new Date(b.date)); // Ordenar por fecha
    
    return filteredSessions?.length > 0 ? { ...training, sessions: filteredSessions } : null;
  })?.filter(training => training !== null);

  const instructorSessions = annualTrainings?.map((training) => {
    const filteredSessions = training?.sessions?.filter((session) => {
      const sessionDate = new Date(session.date);
      return sessionDate >= currentDate && session?.instructor?.some((instructor) =>
        instructor.id === currentUser?.id
      );
    })?.sort((a, b) => new Date(a.date) - new Date(b.date)); // Ordenar por fecha
    
    return filteredSessions?.length > 0 ? { ...training, sessions: filteredSessions } : null;
  })?.filter(training => training !== null);

  const currentDatetime = new Date();
  
  const filteredUserForms = userForms?.filter((item) => {
    const startDate = new Date(item.template.start_date);
    const endDate = new Date(item.template.end_date);
    return startDate <= currentDatetime && currentDatetime <= endDate;
  });

  const filteredCapacitaciones = capacitaciones && capacitaciones?.filter((item) => new Date(item.end_date) >= new Date())
  
  return (
    <section className={styles.rightside}>
      <h1 style={{margin: '0 0 10px 0'}}>Recordatorios</h1>
      <div className={styles.reminders}>
        {loading ? (
          <>
            {Array.from(new Array(5)).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width="100%"
                height={50}
                animation="wave"
                style={{ marginBottom: '5px', borderRadius: '5px' }}
              />
            ))}
          </>
        ) : 
        (<>
          {userForms && (
            <>
              {filteredUserForms?.map((form) => {
                return form.answered_at !== null ? (
                  <Reminder
                    form={form}
                    time={timeLeftFormatter(
                      form.template.start_date,
                      form.template.end_date
                    )}
                    key={form.id}
                    type={"surveys"}
                  />
                ) : (
                  <Link
                    to={form.answered_at === null && `/encuestas/${form.id}`}
                    style={{ textDecoration: "none", color: "#000" }}
                    key={form.id}
                  >
                    <Reminder
                      form={form}
                      key={form.id}
                      time={timeLeftFormatter(
                        form.template.start_date,
                        form.template.end_date
                      )}
                      type={"surveys"}
                    />
                  </Link>
                );
              })}
            </>
          )}
          {filteredCapacitaciones && (
            <>
              {filteredCapacitaciones?.map((capacitacion) => {
                return (
                  <div key={capacitacion.id}>
                    {capacitacion?.tasks?.length === 0 && (
                      <Link
                        to={`/capacitaciones/${capacitacion?.id}`}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        <Reminder
                          capacitacion={capacitacion}
                          time={timeLeftFormatter(
                            capacitacion?.start_date,
                            capacitacion?.end_date
                          )}
                          type={"capacitacion"}
                          key={capacitacion.id}
                        />
                      </Link>
                    )}
                  </div>
                );
              })}
            </>
          )}
          {trainingsWithNullSessions && (
            <>
              {trainingsWithNullSessions?.map((training, index) => {
              
                const confirm = training?.sessions?.filter(session =>
                  session?.teacher?.some(teacher =>
                    teacher.id === currentUser.id && (teacher?.attendance_status === null || teacher?.attendance_status === 'pending')
                  )
                );
                return (
                  <>
                  {confirm?.map((item, index) => {
                    const teacher = item?.teacher?.find((teac) => teac.id === currentUser.id)
                    
                    return(
                      <Link key={index}
                        to={`/capacitaciones/anuales/${training.id}`}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        <Reminder
                          capacitacion={item}
                          capacitacionName={training.name}
                          type={"capacitacionAnual"}
                          key={item.id}
                          i={index + 1}
                          status={teacher?.attendance_status}
                        />
                      </Link>
                    )
                  })}
                    
                  </>
                );
              })}
            </>
          )}
          {instructorSessions && (currentUser.role !== "external_teacher") &&
          <>
          {instructorSessions?.map((training, index) => {
          
            const confirm = training?.sessions?.filter(session =>
              session?.instructor?.some(instructor =>
                instructor.id === currentUser.id
              )
            );
            return (
              <>
              {confirm?.map((item, index) => {
                
                return(
                  <Link key={index}
                    to={`/seguimiento/capacitaciones/anuales/${training.id}`}
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <Reminder
                      capacitacion={item}
                      capacitacionName={training.name}
                      type={"instructor"}
                      key={item.id}
                      i={index + 1}
                    />
                  </Link>
                )
              })}
                
              </>
            );
          })}
        </>
          }
        </>) }
      </div>
      {openModal && <LoadPictures setOpenModal={setOpenModal} />}
    </section>
  );
};
export default Reminders;