const typeChoices = [
    { value: 'Solicitud', label: 'Solicitud' },
    { value: 'Incidencia', label: 'Incidencia' },
    { value: 'Evento', label: 'Evento' },
  ];
  
  const statusChoices = [
    { value: 'Pendiente', label: 'Pendiente' },
    { value: 'En Proceso', label: 'En Proceso' },
    { value: 'Resuelto', label: 'Resuelto' },
    { value: 'Cerrado', label: 'Cerrado' },
  ];
  
  const incidentTypeChoices = [
    { value: 'Lost Equipment', label: 'Perdida de equipo' },
    { value: 'Damaged Equipment', label: 'Equipo Dañado' },
    { value: 'Other', label: 'Otro' },
  ];
  
  const typeEventChoices = [
    { value: 'Meeting', label: 'Reunión' },
    { value: 'Chat', label: 'Chat' },
    { value: 'Call', label: 'Llamada' },
  ];
  
  const areaChoices = [
    { value: 'Administration', label: 'Administración' },
    { value: 'IT', label: 'IT' },
    { value: 'Logistics and Inventory', label: 'Logística e Inventario' },
    { value: 'Human Resources', label: 'Recursos Humanos' },
    { value: 'Post Sales', label: 'Post Venta' },
    { value: 'Sales', label: 'Ventas' },
    { value: 'Monitoring', label: 'Seguimiento' },
    { value: 'Other', label: 'Otro' },
  ];
  
  const stageChoices = [
    { value: 'Preescolar', label: 'Preescolar' },
    { value: 'Primaria Menor', label: 'Primaria Menor' },
    { value: 'Primaria Mayor', label: 'Primaria Mayor' },
    { value: 'Bachillerato', label: 'Bachillerato' },
  ];

  export {typeChoices, statusChoices,
    incidentTypeChoices,
    typeEventChoices,
    areaChoices,
    stageChoices}