import React, {useState, useEffect} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import styles from './LessonsList.module.css'
import { LESSONS_ENDPOINT } from '../../utils/constants';
import { get } from "../../utils/http";
import Container from "../Container";
import EditLesson from './EditLesson';

const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#000",
        fontSize: "13px",
        fontWeight: "bold",
        borderBottom: "1px solid #E0E0E0",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#f5f5f5",
        borderBottomColor: "#FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
};

let INITIAL_VALUES = {
  title: "",
  licens: "",
  learning_subject: "",
  learning_topic:"",
  lesson_goal: "",
  learning_outcomes: "",
  generating_topic: "",
  theoric_references: "",
  curriculum_content: "",
  beginning: "",
  development: "",
  closure: "",
  script: "",
  slides: "",
  achievement_indicators: "",
  main_activities: "",
  reference_number: 0
};

function LessonsList() {
    const [filterText, setFilterText] = useState("");
    const [filterProgram, setFilterProgram] = useState("");
    const [lessons, setLessons] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
    const [lessonId, setLessonId] = useState(0)

    const fetchLessons = async () => {
      const url = LESSONS_ENDPOINT;
      const response = await get({ url });
  
      if (!response.error) {
        setLessons(response.data);
      }
    };

    useEffect(() => {
      fetchLessons();
    }, []);

    const handleOpenModal = (row) => {
      setLessonId(row.id)
      INITIAL_VALUES = {
        title: row.title,
        licens: row.licens.name,
        lerning_subject: row.lerning_subject,
        learning_topic:row.learning_topic,
        lesson_goal: row.lesson_goal,
        learning_outcomes: row.learning_outcomes,
        generating_topic: row.generating_topic,
        theoric_references: row.theoric_references,
        curriculum_content: row.curriculum_content,
        beginning: row.beginning,
        development: row.development,
        closure: row.closure,
        script: row.script,
        slides: row.slides,
        achievement_indicators: row.achievement_indicators,
        main_activities: row.main_activities,
        reference_number: row.reference_number
      };
      setInitialValues(INITIAL_VALUES);
      setModalOpen(true)
    }

    const navigate = useNavigate();

    const lesson_programs =[]

    lessons?.forEach((lesson) => {
      if(!lesson_programs?.includes(lesson?.licens?.name) && lesson?.licens?.name !== null && lesson?.licens?.name !== ""){
          lesson_programs?.push(lesson?.licens?.name)
      }
    })

    const columns = [
        {
          name: "Clase",
          selector: (row) => row.title,
          sortable: true,
        },
        {
          name: "Programa",
          selector: (row) => row.licenss?.content,
          sortable: true,
          cell: (cell) => {
            const licen = cell.licenss?.content ? cell.licenss?.content : "Sin asignar"
            return(
                <p>{licen}</p>
            )
          }
        },
        {
          name: "Licencia",
          selector: (row) => row.licens?.name,
          sortable: true,
          
        },
    ];
    
    let filteredData = lessons.filter(
      (item) =>
        item?.title?.toLowerCase()?.includes(filterText?.toLowerCase()) &&
        item?.licens?.name?.includes(filterProgram)
    );

  return (
    <>
    <div className={styles.content}>
        <i
        className="fal fa-arrow-left"
        style={{ fontSize: "12px", cursor: "pointer" }}
        onClick={() => navigate(-1)}
        ></i>
        <div className={styles.filters}>
            <div className={styles["search-bar"]}>
                <input
                  id="search"
                  type="text"
                  placeholder="Buscar..."
                  aria-label="Search Input"
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  className={styles.filter_text}
                />
            </div>
            <select name="program_lesson" onChange={(e) => setFilterProgram(e.target.value)}>
                <option value="">
                 ---
                </option>
                {lesson_programs?.map((program)=>(
                    <option value={program}>{program}</option>
                ))}
            </select>
        </div>
        <Container direction={"column"}>
            <DataTable
              columns={columns}
              data={filteredData}
              customStyles={customStyles}
              sortIcon={
                <i
                  className="far fa-arrow-up"
                  style={{ marginLeft: "5px", fontSize: "14px" }}
                ></i>
              }
              highlightOnHover
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight="515px"
              onRowClicked={(row) => handleOpenModal(row)}
            />
      </Container>
      
    </div>
      {modalOpen && (
        <EditLesson 
        setModalOpen={setModalOpen} 
        initialValues={initialValues}
        lessonId={lessonId}
        fetchLessons={fetchLessons} />
      )}
    </>
  )
}

export default LessonsList