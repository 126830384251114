import React, {useState, useEffect} from 'react'
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import {
    MyTextInput,
    MySubmitButton,
    MySelect,
  } from "../../FormFields";
import styles from '../CourseSections.module.css'
import Modal from '../../Modal';
import Container from '../../Container';

const INITIALVALUES2 = {
    day: "",
    start_time: null,
    end_time: null,
};

const formatTime = (date) => {
    const options = {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    };
    return date?.toLocaleTimeString([], options);
};


function ClassSchedule({createSchedule, 
    currentUser, 
    filteredTeachers, 
    sections, 
    currentSection, 
    deleteSchedule,
    showLoading,
    hideLoading,
    updateSchedule}) {
    const [addSchedule, setAddSchedule] = useState(false);
    const [currentSchedule, setCurrentSchedule] = useState(null);
    const [initialValues2, setInitialValues2] = useState(INITIALVALUES2);
    const [openModal, setOpenModal] = useState(false);
    const [mappedEvents, setMappedEvents] = useState({})

    useEffect(() => {
        if (currentSchedule) {
          const definedInitialValues = {
            day: currentSchedule.day,
            start_time: currentSchedule.start_time,
            end_time: currentSchedule.end_time,
          };
          setInitialValues2(definedInitialValues);
        }
    }, [currentSchedule]);

    useEffect(() => {
        const events = [];
        currentSection?.schedule?.forEach(event => {
          const currentWeekStart = new Date();
          // Establece el inicio de la semana en domingo
          currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay());
      
          // Encuentra el índice del día correcto
          const dayIndex = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"].indexOf(event.day);
          const eventDate = new Date(currentWeekStart);
          eventDate.setDate(currentWeekStart.getDate() + dayIndex + 1);  // +1 porque el arreglo empieza en lunes y getDay() para lunes es 1
      
          const startTime = event.start_time.split(':'); // Asumiendo formato 'HH:MM'
          const endTime = event.end_time.split(':'); // Asumiendo formato 'HH:MM'
          const start = new Date(eventDate);
          const end = new Date(eventDate);
          start.setHours(parseInt(startTime[0]), parseInt(startTime[1]));
          end.setHours(parseInt(endTime[0]), parseInt(endTime[1]));
      
          events.push({
            start: start,
            end: end,
            extendedProps: {
              schedule: event,
            },
          });
        });
        setMappedEvents(events);
    }, [currentSection]);

    const handleCreateSchedule = (values) => {
        const fillData = {
          day: values.day,
          start_time: values.start_time,
          course_section: currentSection?.id,
          end_time: values.end_time,
          teacher: values.teacher,
        };
        createSchedule({ data: fillData });
        setAddSchedule(false);
    };

    const handleOpenModal = (entry) => {
        setCurrentSchedule(entry.extendedProps.schedule);
        const definedInitialValues = {
          day: entry.extendedProps.schedule.day,
          start_time: entry.extendedProps.schedule.start_time,
          end_time: entry.extendedProps.schedule.end_time,
        };
        setInitialValues2(definedInitialValues);
        setOpenModal(true);
    };

    const handleDeleteSchedule = () => {
        showLoading();
        const scheduleId = currentSchedule.id;
        deleteSchedule({ scheduleId });
        hideLoading();
        setOpenModal(false);
    };

    const handleEditSchedule = (values) => {
        showLoading();
        const scheduleId = currentSchedule.id;
        updateSchedule({ scheduleId, data: values });
        hideLoading();
        setOpenModal(false);
    };

    const currentDate = new Date();
  const currentWeekStart = new Date(currentDate?.getFullYear(), currentDate?.getMonth(), currentDate?.getDate() - currentDate?.getDay());
  const currentWeekEnd = new Date(currentWeekStart?.getFullYear(), currentWeekStart?.getMonth(), currentWeekStart?.getDate() + 6);
  
    const EventContent = ({ event }) => {
    // Tooltip content
    return (
        <Box sx={{display:'flex', }}>
            <Typography sx={{fontWeight:'bold', padding:'5px'}}>{formatTime(event.start)} - {formatTime(event.end)}</Typography>
            <IconButton size='small' onClick={() => handleOpenModal(event)}
            sx={{marginLeft: 'auto', marginRight: '5px'}}>
                <EditRoundedIcon sx={{color: '#fff'}} />
            </IconButton>
        </Box>
    );
    }

  return (
    <div>
        {addSchedule ? (
            <div className={styles.add_schedule}>
              <Formik
                initialValues={{
                  day: "",
                  start_time: null,
                  end_time: null,
                }}
                validationSchema={Yup.object({})}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    handleCreateSchedule(values);
                    setAddSchedule(false);
                    setSubmitting(false);
                  }, 400);
                }}
              >
                <Form className={styles["form__fields"]}>
                    <Grid container rowSpacing={2} columnSpacing={2} sx={{width:'50%'}}>
                        <Grid item md={12} xs={12}>
                            <MySelect label="Día" name="day">
                            <MenuItem value="MONDAY">Lunes</MenuItem>
                            <MenuItem value="TUESDAY">Martes</MenuItem>
                            <MenuItem value="WEDNESDAY">Miercoles</MenuItem>
                            <MenuItem value="THURSDAY">Jueves</MenuItem>
                            <MenuItem value="FRIDAY">Viernes</MenuItem>
                            </MySelect>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <MyTextInput
                            label="Hora inicio"
                            name="start_time"
                            type="time"
                            placeholder="Hora"
                            icon="fa fa-user"
                            width={'100%'}
                            />

                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <MyTextInput
                                label="Hora fin"
                                name="end_time"
                                type="time"
                                placeholder="Hora"
                                icon="fa fa-user"
                                width={'100%'}
                            />
                        </Grid>
                    </Grid>
                  {(currentUser.role === "IT" ||
                    currentUser.role === "academic_coordination" ||currentUser.role === "academy_coordination") && (
                    <div className={styles.inputs}>
                      <MySelect label="Docente" name="teacher">
                        {filteredTeachers?.map((teacher) => {
                          return (
                            <MenuItem value={teacher.info.id}>
                              {`${teacher.info.first_name} ${teacher.info.last_name}`}
                            </MenuItem>
                          );
                        })}
                      </MySelect>
                    </div>
                  )}
                  <div className={styles.inputs}>
                    <MySubmitButton
                      name="Cancelar"
                      onClick={() => setAddSchedule(false)}
                    />
                    <MySubmitButton name="Aceptar" type="submit" />
                  </div>
                </Form>
              </Formik>
            </div>
          ) : (
            <div className={styles.section_info}>
              {sections.length > 0 &&
              <Button sx={{borderRadius: '10px', fontSize: '1.1rem'}} 
              variant='contained' size='large'
                className={styles.add_but}
                onClick={() => setAddSchedule(true)}
                endIcon={<AddCircleOutlineRoundedIcon/>}
              >
                Agregar horario
              </Button>}
            </div>
          )}
        {sections?.length > 0 && currentSection && (
        <Container>
          <div className={styles.section_content}>
            <div className={styles.schedule}>
              <div className={styles["schedule-title"]}>Horario</div>
              <FullCalendar
                plugins={[timeGridPlugin]}
                initialView="timeGridWeek"
                height={"78vh"}
                events={mappedEvents}
                slotDuration="00:15:00"
                nowIndicator={true} 
                slotMinTime={"07:00:00"}
                slotMaxTime={"16:00:00"}
                weekends={false}
                headerToolbar={{
                  left: '',
                  center: 'title',
                  right: 'timeGridWeek,timeGridDay'
                }}
                allDaySlot={false}
                eventContent={({ event }) => (
                    <EventContent event={event} />
                  )}
                validRange={{ start: currentWeekStart, end: currentWeekEnd }}
                />
            </div>
            
          </div>
        </Container>
      )}
       {openModal && (
        <Modal title={"Editar Horario"} modalOpen={setOpenModal}>
          <div>
            <Formik
              initialValues={initialValues2}
              validationSchema={Yup.object()}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                  handleEditSchedule(values);
                  setSubmitting(false);
                }, 400);
                resetForm();
              }}
            >
              <Form className={styles["form__fields"]}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item md={12}>
                        <MySelect label="Día" name="day">
                            <MenuItem value="MONDAY">Lunes</MenuItem>
                            <MenuItem value="TUESDAY">Martes</MenuItem>
                            <MenuItem value="WEDNESDAY">Miercoles</MenuItem>
                            <MenuItem value="THURSDAY">Jueves</MenuItem>
                            <MenuItem value="FRIDAY">Viernes</MenuItem>
                        </MySelect>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <MyTextInput
                        label="Hora inicio"
                        name="start_time"
                        type="time"
                        placeholder="Hora"
                        icon="fa fa-user"
                        width={'100%'}
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <MyTextInput
                        label="Hora fin"
                        name="end_time"
                        type="time"
                        placeholder="Hora"
                        icon="fa fa-user"
                        width={'100%'}
                        />
                    </Grid>
                </Grid>
                  
                <div className={styles.inputs_button}>
                  <Button
                    variant='outlined'
                    sx={{
                      borderRadius: "10px",
                      fontSize: '1.1rem'
                    }}
                    onClick={handleDeleteSchedule}
                  >
                    Eliminar
                  </Button>
                  <MySubmitButton name="Editar" type="submit" />
                </div>
              </Form>
            </Formik>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default ClassSchedule
