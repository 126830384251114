import React, { useState, useEffect, useCallback, useReducer } from 'react'
import { useNavigate } from "react-router-dom";
import Container from '../components/Container'
import styles from './RegistroFotografico.module.css'
import LoadingComponent from '../components/LoadingComponent';
import Modal from '../components/Modal'
import { useLoading } from '../context/LoadingContext';
import { get } from '../utils/http';
import { handleDownload } from '../utils/downloadFile';
import { MONITOR_PICTURES_ENDPOINT } from '../utils/constants';
import { dateFormatter } from '../utils/dateFormatter';

const initialState = {
  pictures: [],
  pagePre: null,
  pageNext: null,
  currentPage: 1,
  totalPages: 0,
  searchText: "",
  filterTerm: "",
  currentPictures: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_STATE':
      return { ...state, ...action.payload };
    case 'SET_PICTURES':
      return { ...state, ...action.payload, currentPage: state.currentPage + (action.next ? 1 : -1) };
    default:
      return state;
  }
}

function RegistroFotografico() {
  const [{ pictures, pagePre, pageNext, currentPage, totalPages, searchText, filterTerm, currentPictures }, dispatch] = useReducer(reducer, initialState);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const [openModal, setOpenModal] = useState(false)
  const [searchTimer, setSearchTimer] = useState(null); // Temporizador para retrasar la búsqueda
  const navigate = useNavigate();

  useEffect(() => {
      showLoading()
        const fetchPictures = async () => {
          const url = `${MONITOR_PICTURES_ENDPOINT}`;
          const response = await get({ url });
          if (!response.error) {
            dispatch({
              type: 'SET_STATE',
              payload: {
                pictures: response.data.results,
                pagePre: response.data.previous,
                pageNext: response.data.next,
                totalPages: Math.ceil(response.data.count / 10)
              }
            });        
          }
          hideLoading()
        };
        fetchPictures(MONITOR_PICTURES_ENDPOINT);
    }, []);

    const handleNextPage = useCallback(async () => {
      if (pageNext) {
        showLoading();
        const response = await get({ url: pageNext });
        if (!response.error) {
          dispatch({
            type: 'SET_STATE',
            payload: {
              pictures: response.data.results,
              pagePre: response.data.previous,
              pageNext: response.data.next,
              currentPage: currentPage + 1 
            }
          });
        }
        hideLoading();
      }
    }, [pageNext, showLoading, hideLoading, currentPage])
    
    const handlePreviousPage = useCallback(async () => {
      if (pagePre) {
        showLoading();
        const response = await get({ url: pagePre });
        if (!response.error) {
          dispatch({
            type: 'SET_PICTURES',
            payload: {
              pictures: response.data.results,
              pagePre: response.data.previous,
              pageNext: response.data.next,
              currentPage: currentPage - 1
            },
          });
        }
        hideLoading();
      }
    }, [pagePre, showLoading, hideLoading, currentPage]);
  
    const handleOpenPictures = useCallback((pictures) => {
      dispatch({ type: 'SET_STATE', payload: { currentPictures: pictures} });
      setOpenModal(true)
    }, []);
      // Busqueda
      const handleSearch = useCallback(async () => {
        showLoading();
      
        const queryParams = [];
      
        if (searchText) {
          queryParams.push(`search=${encodeURIComponent(searchText)}`);
        }
      
        if (filterTerm) {
          queryParams.push(`lapse=${encodeURIComponent(filterTerm)}`);
        }
      
        const queryString = queryParams.join('&');
        const url = `${MONITOR_PICTURES_ENDPOINT}?${queryString}`;
      
        try {
          const response = await get({ url });
          if (!response.error) {
            dispatch({
              type: 'SET_STATE',
              payload: {
                pictures: response.data.results,
                pagePre: response.data.previous,
                pageNext: response.data.next,
                currentPage: 1,
                totalPages: Math.ceil(response.data.count / response.data.results.length)
              }
            });
          }
        } catch (error) {
          console.error('Error fetching pictures:', error);
        }
      
        hideLoading();
      }, [showLoading, hideLoading, searchText, filterTerm, dispatch]);
      
    useEffect(() => {
      // Limpia el temporizador si se desmonta el componente
      return () => {
          clearTimeout(searchTimer);
      };
    }, [searchTimer]);

  const delayedSearch = useCallback(() => {
    clearTimeout(searchTimer);
    const timer = setTimeout(() => {
      handleSearch();
    }, 700);
    setSearchTimer(timer);
  }, [searchTimer, searchText, filterTerm, handleSearch]);
  
  useEffect(() => {
    delayedSearch(searchText, filterTerm);
  }, [searchText, filterTerm]);
      
  const handleFilterTermChange = useCallback((e) => {
    const newFilterTerm = e.target.value;
    // Actualizamos el filterTerm usando dispatch
    dispatch({ type: 'SET_STATE', payload: { filterTerm: newFilterTerm } });
  
    // Realiza la búsqueda con el nuevo término de filtro
    delayedSearch(searchText, newFilterTerm);
  }, [dispatch, searchText, delayedSearch]);

  const handleSearchTextChange = useCallback((e) => {
    const newSearchText = e.target.value;
    // Actualizamos el searchText usando dispatch
    dispatch({ type: 'SET_STATE', payload: { searchText: newSearchText } });
  
    // Realiza la búsqueda diferida con el nuevo valor de searchText
    delayedSearch(newSearchText, filterTerm);
  }, [dispatch, filterTerm, delayedSearch]);
 
    return (
      <Container>
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
          <i
            className="fal fa-arrow-left"
            style={{
              fontSize: "12px",
              cursor: "pointer",
              marginBottom: "10px",
            }}
            onClick={() => navigate(-1)}
          ></i>
          <div className={styles.cont}>
          <div className={styles["search-bar"]}>
          <select
            value={filterTerm}
            className={styles.filter_text}
            onChange={(e) => handleFilterTermChange(e)}
          >
            <option value={""}>Filtrar por lapso</option>
            <option value={1}>I Lapso</option>
            <option value={2}>II Lapso</option>
            <option value={3}>III Lapso</option>
          </select>
          </div>
        <div className={styles["search-bar2"]}>
          <input
            id="search"
            type="text"
            placeholder="Buscar por institución"
            aria-label="Search Input"
            value={searchText}
            onChange={(e) => handleSearchTextChange(e)}
            className={styles.filter_text}
          />
        </div>
  
        </div>
        <div className={styles["pagination"]}>
          <i className="fas fa-arrow-left" 
          style={{ fontSize: "18px", cursor: "pointer", marginLeft: "5px", 
          marginRight: "5px", color: "var(--main-blue)" }}
          onClick={handlePreviousPage}></i>
           <span>{`Página ${currentPage}  de ${totalPages}`}</span>
          <i className="fas fa-arrow-right" 
          style={{ fontSize: "18px", cursor: "pointer", 
          marginLeft: "5px", marginRight: "5px" , color: "var(--main-blue)"}}
          onClick={handleNextPage}></i>
        </div>
        {pictures?.map((entrega) => (
          <div className={styles.task}>
              <p style={{ fontSize: "14px", width: "20%" }}>
                {entrega?.teacher}
              </p>
              <div style={{ width: "30%", display: "flex", flexDirection: "column" }}>
                  <p style={{ fontSize: "11px", textAlign: "center" }}>
                      Institución
                  </p>
                  <p style={{ fontSize: "13px", textAlign: "center", paddingTop: "5px" }}>
                      {entrega?.institution_name}
                  </p>
              </div>
              <p style={{ fontSize: "13px", width: "10%", textAlign: 'center' }}>
                  {entrega?.lapse} Lapso
              </p>
              <div className={styles.entregas}>
                <p style={{ fontSize: "11px", textAlign: "center" }}>
                  # de entregas
                </p>
                <p
                  style={{
                    fontSize: "13px",
                    textAlign: "center",
                    paddingTop: "5px",
                  }}
                >
                  {entrega?.picturesdeliveries?.length}
                </p>
              </div>
              <p
                  className={styles.comment}
                  onClick={() => handleOpenPictures(entrega?.picturesdeliveries)}
              >
                  Ver Entregas
              </p>
          </div>
        ))}        
        </div>
        {isLoading && <LoadingComponent />}
        {openModal &&
        <Modal modalOpen={setOpenModal} title={"Registro"} >
          <div style={{width: '100%', height: '100%', overflowY: 'scroll', 
            display: 'flex', flexDirection: 'column', gap: '15px'}}>
          {currentPictures?.map((pic) => {
            return(
              <div style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                <p>{dateFormatter(pic?.created_at)}</p>
                <p
                className={styles.comment}
                onClick={() => handleDownload(pic?.url)}
              >
                Descargar Entrega
              </p>
              </div>
            )
          })}
          </div>
        </Modal>}
        {isLoading && <LoadingComponent />}
    </Container>
  )
}

export default RegistroFotografico