import React, {useContext, useEffect} from 'react'
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { dateFormatter } from '../../utils/dateFormatter';
import { EventsContext } from '../../context/EventsProvider';
import styles from './Events.module.css';

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

const getStatusIcon = (status) => {
  switch (status) {
    case 'Pendiente':
      return (
        <div style={{
          backgroundColor: 'var(--sec-orange)', 
          color: '#fff',
          padding: '5px 10px',
          borderRadius: '20px',
          fontSize: '14px',
        }}>
          Pendiente
        </div>
      );
    case 'En Proceso':
      return (
        <div style={{
          backgroundColor: 'var(--sec-blue)',
          color: '#fff',
          padding: '5px 10px',
          borderRadius: '20px',
          fontSize: '14px',
        }}>
          En Proceso
        </div>
      );
    case 'Resuelto':
      return (
        <div style={{
          backgroundColor: 'var(--sec-green)',
          color: '#fff',
          padding: '5px 10px',
          borderRadius: '20px',
          fontSize: '14px',
        }}>
          Resuelto
        </div>
      );
    case 'Cerrado':
      return (
        <div style={{
          backgroundColor: 'var(--sec-red)',
          color: '#fff',
          padding: '5px 10px',
          borderRadius: '20px',
          fontSize: '14px',
        }}>
          Cerrado
        </div>
      );
  }
};

function EventViewList() {
  const {fetchByEvent , onlyEvents, fetchNextPageEvent, fetchPreviousPageEvent, currentPage, totalPages} = useContext(EventsContext);

  useEffect(() => {
    fetchByEvent();
  }, []);

  const navigate = useNavigate();

  const handleRowClick = (row) => {
    navigate(`/seguimiento/events/event/${row.id}`, { relative: false });
  };
  
  const columns = [
    {
      name: "Agendando por",
      selector: (row) => row.report_person?.first_name + " " + row.report_person?.last_name,
      sortable: true,
      cell: (cell) => {
        const fullName = `${cell.report_person?.first_name || ''} ${cell.report_person?.last_name || ''}`.trim();
        return fullName ? <p>{fullName}</p> : 'Por asignar';
      }
    },
    {
      name: "Institución",
      selector: (row) => row.institution?.name,
      sortable: true,
      cell: (cell) => <div>{cell.institution?.name}</div>
  },
  {
      name: "Estado",
      selector: (row) => row.status,
      sortable: true,
      cell: (cell) => getStatusIcon(cell.status)       

    },
    {
      name: "Tipo ",
      selector: (row) => row.type_event,
      sortable: true,
      cell: (cell) => <div>{cell.type_event}</div>
    },
    {
      name: "Programa",
      selector: (row) => row.program?.name,
      sortable:true,
      cell: (cell) => <div>{cell.program?.name}</div>

    },
    {
      name: "Area Responsable",
      selector: (row) => row.area_responsible,
      sortable: true,
      cell: (cell) => 
      {
        let area = ""
        switch (cell.area_responsible) {
          case "Administration"
            : area = "Administración"
            break;
          case "Logistics and Inventory"
            : area = "Logística y Inventario"
            break;
          case "Human Resources"
            : area = "Recursos Humanos"
            break;
          case "IT"
            : area = "IT"
            break;
          case "Post Sales"
            : area = "Post Venta"
            break;
          case "Sales"
            : area = "Ventas"
            break;
          case "Monitoring"
            : area = "Seguimiento"
            break;
          case "Other"
            : area = "Otro"
            break;

      }

        return <div>{area}</div>
      }
      
    },
    {
      name: "Fecha",
      selector: (row) => row.event_date,
      cell: (cell) =>(
        <p>{dateFormatter(new Date(cell.event_date))}</p>
      )
    },
  ];

  return (
    <div style={{borderRadius: '10px'}}>
      <div className={styles["pagination"]} style={{ marginBottom: "10px" }}>
          <i className="fas fa-arrow-left"
            style={{ fontSize: "18px", cursor: "pointer", marginLeft: "5px", marginRight: "5px", color: "var(--main-blue)" }}
            onClick={fetchPreviousPageEvent}></i>
          <span>{`Página ${currentPage}  de ${totalPages}`}</span>
          <i className="fas fa-arrow-right"
            style={{ fontSize: "18px", cursor: "pointer", marginLeft: "5px", marginRight: "5px", color: "var(--main-blue)" }}
            onClick={fetchNextPageEvent}></i>
        </div>
      <DataTable
        columns={columns}
        data={onlyEvents}
        customStyles={customStyles}
        sortIcon={
          <i
            className="far fa-arrow-up"
            style={{ marginLeft: "5px", fontSize: "14px" }}
          ></i>
        }
        highlightOnHover
        fixedHeader
        pointerOnHover
        onRowClicked={(row) => handleRowClick(row)}
        noDataComponent="No hay eventos"
        fixedHeaderScrollHeight="500px"
      />
    </div>
  )
}

export default EventViewList
