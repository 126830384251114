export const SEGUIMIENTO_OPTIONS = [
  {
    name: "Seguimiento",
    icon: <i className="fad fa-users-cog"></i>,
    route: "",
  },
  {
    name: "Docentes",
    icon: <i className="fad fa-users"></i>,
    route: "/seguimiento/teachers",
  },
  {
    name: "Encuestas",
    icon: <i className="fas fa-poll"></i>,
    route: "/encuestas",
  },
  {
    name: "Planilla",
    icon: <i className="far fa-file-alt"></i>,
    route: "/seguimiento/planilla",
  },
  {
    name: "Capacitación",
    icon: <i className="fad fa-chalkboard-teacher"></i>,
    route: "/seguimiento/capacitaciones",
  },
  {
    name: "Reportes",
    icon: <i className="far fa-chart-bar"></i>,
    route: "/seguimiento/reports",
  },
  {
    name: "Programas",
    icon: <i className="fad fa-robot"></i>,
    route: "/programs/list",
  },
  {
    name: "Registro fotográfico",
    icon: <i class="fas fa-images"></i>,
    route: "/seguimiento/registro-fotografico",
  },
  {
    name: "Panel de actividades",
    icon: <i className="fas fa-exclamation-triangle"></i>,
    route: "/seguimiento/events",
  },
];

export const COORDI_ACADEMIC_OPTIONS = [
  {
    name: "Coordinación",
    icon: <i className="fad fa-users-cog"></i>,
    route: "",
  },
  {
    name: "Docentes",
    icon: <i className="fad fa-users"></i>,
    route: "/seguimiento/teachers",
  },
  {
    name: "Planilla",
    icon: <i className="far fa-file-alt"></i>,
    route: "/seguimiento/planilla",
  },
  {
    name: "Capacitación",
    icon: <i className="fad fa-chalkboard-teacher"></i>,
    route: "/seguimiento/capacitaciones",
  },
  
];
