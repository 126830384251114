import React, { useState, useContext, useEffect } from "react";
import { useParams, Outlet, Link } from "react-router-dom";
import { get } from "../../utils/http";
import styles from "./InstitutionView.module.css";
import Container from "../Container";
import {
  INSTITUTION_OPT,
  INSTITUTION_OPT_COORDINATOR,
} from "../../utils/constants/institutionsConstants";
import InstitutionsLevels from "./InstitutionsLevels";
import InstitutionCourses from "./InstitutionCourses";
import InstitutionTeachers from "./InstitutionTeachers";
import { INSTITUTIONS_ENDPOINT } from "../../utils/constants";
import InstitutionCalendar from "./InstitutionCalendar";
import { CurrentUserContext } from "../../context/CurrentUserProvider";
import InstitutionPDF from "./Resume/InstitutionPDF";

function InstitutionView() {
  const [currentOption, setCurrentOption] = useState("docentes");
  const [modalOpen, setModalOpen] = useState(false);
  const [institution, setInstitution] = useState([]);
  const [courses, setCourses] = useState([]);
  const { currentUser } = useContext(CurrentUserContext);

  const { institutionId } = useParams();

  let INSTITUTION_OPTIONS;

  if (currentUser.role === "external_coordinator") {
    INSTITUTION_OPTIONS = INSTITUTION_OPT_COORDINATOR;
  } else {
    INSTITUTION_OPTIONS = INSTITUTION_OPT;
  }

  useEffect(() => {
    const fetchInstitution = async () => {
      const url = `${INSTITUTIONS_ENDPOINT}${institutionId}/`;
      const response = await get({ url });
      setInstitution(response.data);
    };
    fetchInstitution();
  }, [institutionId]);

  useEffect(() => {
    const fetchCourses = async () => {
      const url = `${INSTITUTIONS_ENDPOINT}${institutionId}/courses/`;
      const response = await get({ url });
      setCourses(response.data);
    };
    fetchCourses();
  }, [institutionId]);

  let seg_teacher;

  if (institution?.monitor) {
    seg_teacher = `${institution?.monitor.first_name} ${institution?.monitor.last_name}`;
  } else {
    seg_teacher = "Sin asignar";
  }

  return (
    <div className={styles.content}>
      <section className={styles.leftside}>
        <Container direction={"row"}>
          <div className={styles.leftside_content}>
            <div className={styles.left_header_content}>
              <div className={styles["logo-img"]}>
                <img src={institution.logo ?? "/IsotipoK.png"} alt="institution" />
              </div>
              <div className={styles.info} style={{width: '70%'}}>
                <h2 style={{fontSize: '2.2rem'}}>{`${institution?.name}`}</h2>
                {/*<p>{`Años TK: 3`}</p>*/}
                <p>{`Tipo de docente: ${institution?.teacher_service}`}</p>
              </div>
            </div>
            <div className={styles.leftside_info}>
              <div className={styles.basic_info}>
                <i className="fal fa-map-marker-alt"></i>
                <p>{`${institution?.address} ${institution?.city}`}</p>
              </div>
              <div className={styles.basic_info}>
                <i className="fal fa-chalkboard"></i>
                <p>{`Cursos: ${courses?.length}`}</p>
              </div>
              <div className={styles.basic_info}>
                <i className="fal fa-users"></i>
                <p>{`Profesores: ${institution?.teachers?.length}`}</p>
              </div>
              {/* <div className={styles.basic_info}>
                <i className="fal fa-users-class"></i>
                <p>{`Secciones por nivel: ${institution?.classrooms_per_level}`}</p>
            </div>*/}
            </div>
          </div>
        </Container>
        {/*<div className={styles.left_content2}>
          <Container direction={"row"}>
            <div className={styles.content_secondary}>
              <p className={styles.title}>Foro</p>
            </div>
          </Container>
  </div>*/}
        <Link
          to={`/profile/${institution?.monitor?.id}`}
          style={{ textDecoration: "none", color: "#000" }}
        >
          <div className={styles.left_content2}>
            <Container direction={"row"}>
              <div className={styles.content_secondary}>
                <p className={styles.title2}>{`Docente seguimiento: `}</p>
                <p className={styles.title3}>{`${seg_teacher}`}</p>
              </div>
            </Container>
          </div>
        </Link>
        {/**<a href="www.google.com" target="_blank">
          <div className={styles.left_content2}>
            <Container direction={"row"}>
              <div className={styles.content_secondary}>
                <p className={styles.title2}>Inventario general</p>
              </div>
            </Container>
          </div>
        </a>**/}

        {(currentUser.role === "IT" || currentUser.role === "post_sales") && (
          <div className={styles.left_content2}>
            <div
              className={styles.content_download}
              onClick={() => setModalOpen(true)}
            >
              <p
                className={styles.title2}
              >{`Descargar Reporte de Seguimiento`}</p>
            </div>
          </div>
        )}
      </section>
      <section className={styles.rightside}>
        <div className={styles.options}>
          {INSTITUTION_OPTIONS.map((opt) => {
            return (
              <div
                onClick={() => setCurrentOption(opt.key)}
                className={
                  currentOption === opt.key ? styles.option2 : styles.option
                }
                key={opt.key}
              >
                <p>{opt.name}</p>
              </div>
            );
          })}
        </div>
        <div className={styles.right_content}>
          {currentOption === "cursos" && (
            <InstitutionCourses
              institution={institution}
              currentUser={currentUser}
            />
          )}
          {currentOption === "grados" && (
            <InstitutionsLevels institution={institution} />
          )}
          {currentOption === "docentes" && (
            <InstitutionTeachers institution={institution} />
          )}
          {currentOption === "horarios" && (
            <InstitutionCalendar institutionId={institutionId} />
          )}
          <Outlet />
        </div>
      </section>
      {(currentUser.role === "IT" || currentUser.role === "post_sales") && <InstitutionPDF
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        institution={institution}
        courses={courses}
      />}
    </div>
  );
}

export default InstitutionView;
