import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import styles from "./InstitutionTeachers.module.css";
import Container from "../Container";
import { timestampFormatter } from "../../utils/timestampFormatter";

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

function InstitutionTeachers({ institution }) {
  const [filterTeacher, setFilterTeacher] = useState("");

  const navigate = useNavigate();

  let filteredTeachers = institution?.teachers?.filter(
    (item) =>
      item?.info?.first_name?.toLowerCase()?.includes(filterTeacher?.toLowerCase()) ||
      item?.info?.last_name?.toLowerCase()?.includes(filterTeacher?.toLowerCase())
  );

  const handleChangeFilterTeachers = (e) => {
    setFilterTeacher(e.target.value);
  };

  const columns_teachers = [
    {
      name: "Nombre",
      selector: (row) => row?.info?.first_name,
      cell: (cell) => (
        <div data-tag="allowRowEvents">
          <p>{`${cell?.info?.first_name} ${cell?.info?.last_name}`}</p>
        </div>
      ),
    },
    {
      name: "Última conexión",
      selector: (row) => row?.info?.last_login,
      cell: (cell) => (
        <div data-tag="allowRowEvents">
          <p>{`${timestampFormatter(
            new Date(cell?.info?.last_login).getTime()
          )}`}</p>
        </div>
      ),
    },
  ];

  const handleRowClick = (row) => {
    navigate(`/profile/${row?.info?.id}`, { relative: false });
  };

  return (
    <Container direction={"column"}>
      <div className={styles["search-bar"]}>
        <input
          id="search"
          type="text"
          placeholder="Buscar por nombre"
          aria-label="Search Input"
          value={filterTeacher}
          onChange={(e) => handleChangeFilterTeachers(e)}
          className={styles.filter_text}
        />
      </div>
      <div className={styles.data_table}>
        <DataTable
          columns={columns_teachers}
          data={filteredTeachers}
          customStyles={customStyles}
          sortIcon={
            <i
              className="far fa-arrow-up"
              style={{ marginLeft: "5px", fontSize: "14px" }}
            ></i>
          }
          highlightOnHover
          pointerOnHover
          fixedHeader
          fixedHeaderScrollHeight="500px"
          onRowClicked={(row) => handleRowClick(row)}
        />
      </div>
    </Container>
  );
}

export default InstitutionTeachers;
