import React, { useState, useEffect } from 'react'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; 
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { INSTITUTIONS_ENDPOINT } from '../../utils/constants';
import { get } from '../../utils/http';
import { useLoading } from '../../context/LoadingContext';
import LoadingComponent from '../LoadingComponent';

function EventContent({ event }) {
  // Tooltip content
  const tooltipContent = (
    <>
    <p>{event.extendedProps.grade} - Sec: {event.extendedProps.section}</p>
      <p>{event.title}</p>
      <p>{event.extendedProps.teacher}</p>
      <p>{formatTime(event.start)} - {formatTime(event.end)}</p>
    </>
  );

  return (
    <Tippy content={tooltipContent} allowHTML={true} placement="top">
      <div>
        <b style={{ fontSize: '11px' }}>{`${event.extendedProps.grade} ${event.extendedProps.section} - ${event.title}`}</b>
        <p>{event.extendedProps.teacher}</p>
      </div>
    </Tippy>
  );
}

const formatTime = (date) => {
  const options = {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  };
  return date?.toLocaleTimeString([], options);
};

function InstitutionCalendar({institutionId}) {
  const [institutionCalendar, setInstitutionCalendar] = useState(null)
  const [mappedEvents, setMappedEvents] = useState({})
  const { showLoading, hideLoading, isLoading } = useLoading();

  useEffect(() => {
    showLoading()
    const fetchInstitution = async () => {
      const url = `${INSTITUTIONS_ENDPOINT}${institutionId}/calendar/`;
      const response = await get({ url });
      setInstitutionCalendar(response.data);
      hideLoading()
    };
    fetchInstitution();
  }, [institutionId]);

  useEffect(() => {
    const events = [];
    institutionCalendar?.forEach(event => {
      const currentWeekStart = new Date();
      // Establece el inicio de la semana en domingo
      currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay());
  
      // Encuentra el índice del día correcto
      const dayIndex = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"].indexOf(event.day);
      const eventDate = new Date(currentWeekStart);
      eventDate.setDate(currentWeekStart.getDate() + dayIndex + 1);  // +1 porque el arreglo empieza en lunes y getDay() para lunes es 1
  
      const startTime = event.start_time.split(':'); // Asumiendo formato 'HH:MM'
      const endTime = event.end_time.split(':'); // Asumiendo formato 'HH:MM'
      const start = new Date(eventDate);
      const end = new Date(eventDate);
      start.setHours(parseInt(startTime[0]), parseInt(startTime[1]));
      end.setHours(parseInt(endTime[0]), parseInt(endTime[1]));
  
      events.push({
        title: event.program,
        start: start,
        end: end,
        extendedProps: {
          teacher: event.teacher,
          section: event.section,
          grade: event.grade,
        },
      });
    });
    setMappedEvents(events);
  }, [institutionCalendar]);

  const currentDate = new Date();
  const currentWeekStart = new Date(currentDate?.getFullYear(), currentDate?.getMonth(), currentDate?.getDate() - currentDate?.getDay());
  const currentWeekEnd = new Date(currentWeekStart?.getFullYear(), currentWeekStart?.getMonth(), currentWeekStart?.getDate() + 6);

  return (
    <div>
        <FullCalendar
      plugins={[timeGridPlugin]}
      initialView="timeGridWeek"
      height={"78vh"}
      events={mappedEvents}
      slotDuration="00:15:00"
      nowIndicator={true} 
      slotMinTime={"07:00:00"}
      slotMaxTime={"16:00:00"}
      weekends={false}
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'timeGridWeek,timeGridDay'
      }}
      allDaySlot={false}
      eventContent={({ event }) => (
        <EventContent event={event} />
      )}
      validRange={{ start: currentWeekStart, end: currentWeekEnd }}
    />
    {isLoading && <LoadingComponent />}
    </div>
  )
}

export default InstitutionCalendar
