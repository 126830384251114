// import React, {useState, useEffect} from 'react'
// //import ReactDOM from "react-dom";
// import PptxGenJS from 'pptxgenjs';
// import { CAPACITACION_ENDPOINT, USER_FORM_ENDPOINT } from '../../../utils/constants';
// import { get } from '../../../utils/http';
// import Modal from '../../Modal'
// import styles from './InstitutionPDF.module.css'
// import { indicators, proyeccion } from '../../../utils/programsIndicators';
// import { resultadosTorneo } from '../../../utils/constants/competitionResults';
// //import MyDocumentPDF from './MyDocumentPDF';

// function chunkObjectKeys(obj, chunkSize) {
//   const keys = Object.keys(obj);
//   const chunks = [];
//   for (let i = 0; i < keys.length; i += chunkSize) {
//       chunks.push(keys.slice(i, i + chunkSize));
//   }
//   return chunks;
// }

// function InstitutionPDF({institution, courses, modalOpen, setModalOpen}) {
//   const [teachers, setTeachers] = useState(institution?.teachers)
//   const [images, setImages] = useState([]);
//   const coursesFilter = courses?.reduce((acc, curso) => {
//     const existe = acc.some(c => c.program?.split(' - ')[0].trim() === curso.program?.split(' - ')[0].trim());
//     if (!existe) {
//       acc.push(curso);
//     }
//     return acc;
//   }, []);
//   useEffect(() => {
//     if (coursesFilter && coursesFilter?.length > 0) {
//       const newInitialState = coursesFilter?.map(course => ({ image: null, program: course.program }));
//       setImages(newInitialState);
//     }
//   }, [courses]);
//   const [notes, setNotes] = useState([]);
//   const [notesModal, setNotesModal] = useState(false)
//   const [moreModal, setMoreModal] = useState(false)
//   const [optional, setOptional] = useState([
//       { id: 1, name: "Capacitaciones en Impresión 3D",
//       valor: false},
//     { id: 2, name: "Visita a Kurios para docentes destacados",
//       valor: false},
//       { id: 3, name: "Actividad en los colegios (STEM)",
//       valor: false},
//       { id: 4, name: "Feria de robótica",
//       valor: false},
//       { id: 5, name: "Clase abierta",
//       valor: false},
//       { id: 6, name: "Clases demostrativas",
//       valor: false},
//       { id: 7, name: "Video alianza con Kurios",
//       valor: false},
//       { id: 8, name: "Participación en eventos del colegio",
//       valor: false},
//       { id: 9, name: "Reconocimiento profesores",
//       valor: false},
//       { id: 10, name: "Charlas para padres",
//       valor: false},
    
//   ])
//   const [openModal, setOpenModal] = useState(false)
//   const [teacherData, setTeacherData] = useState([]);
        
//   useEffect(() => {
//     const fetchAllData = async () => {
//     const fetchedTeacherData = await Promise.all(
//       institution?.teachers?.map(async (teacher) => {
//         const capacitacionesResponse = await get({
//           url: `${CAPACITACION_ENDPOINT}user/${teacher.info.id}/`,
//         });
//         const capacitacionesFiltered = capacitacionesResponse.data.filter((item) => item.term.name === "II")
//         const encuestasResponse = await get({
//           url: `${USER_FORM_ENDPOINT}user/${teacher.info.id}/`,
//         });
//         const capacitacionesConTareas = capacitacionesResponse.error
//         ? 0
//         : capacitacionesFiltered?.reduce((count, capacitacion) => {
//             return (capacitacion.training_task_user > 0 || capacitacion.quiz_user) ? count + 1 : count;
//           }, 0);
//         const encuestasListas = encuestasResponse.error
//         ? 0
//         : encuestasResponse?.data?.reduce((count, form) => {
//             return form.completed ? count + 1 : count;
//           }, 0);
//         return {
//           ...teacher,
//           capacitaciones: capacitacionesResponse.error ? null : capacitacionesFiltered,
//           encuestas: encuestasResponse.error ? null : encuestasResponse.data,
//           capacitacionesConTareas,
//           encuestasListas
//         };
//       }) || []
//     );
//     setTeacherData(fetchedTeacherData);
//     };
//   fetchAllData();
//   }, [institution?.teachers]);
//   const coursesByStage = () => {
//     const levels = {
//       "Preescolar": ["Maternal", "I Nivel", "II Nivel", "III Nivel", "ll Nivel"],
//       "Primaria": ["1er Grado", "2do Grado", "3er Grado", "4to Grado", "5to Grado", "6to Grado"],
//       "Bachillerato": ["1er Año", "2do Año", "3er Año", "4to Año", "5to Año"]
//     };
  
//     const resultado = [];
  
//     for (const [stage, level] of Object.entries(levels)) {
//       const cursosEtapa = courses?.filter(course => level?.includes(course?.institution_level));
//       const cursosFiltrados = cursosEtapa?.reduce((acc, curso) => {
//         const existe = acc.some(c => c.program?.split(' - ')[0].trim() === curso.program?.split(' - ')[0].trim());
//         if (!existe) {
//           acc.push(curso);
//         }
//         return acc;
//       }, []);

//       cursosFiltrados?.sort((a, b) => {
//         const indexA = level?.indexOf(a.institution_level);
//         const indexB = level?.indexOf(b.institution_level);
//         return indexA - indexB;
//       });
      
//       resultado.push({
//         stage,
//         courses: cursosFiltrados
//       });
//     }
  
//     return resultado;
//   };
  
//   const coursesStage = coursesByStage();

//   const handleAddNote = () => {
//     setNotes([...notes, ""]); 
//   };

//   const handleNoteChange = (e, index) => {
//     const newNotes = [...notes];
//     newNotes[index] = e.target.value;
//     setNotes(newNotes);
//   };

//   const handleDeleteNote = (index) => {
//     const newNotes = [...notes];
//     newNotes.splice(index, 1); 
//     setNotes(newNotes);
//   };

//   const selectedImages = images?.slice(0, 3);

//   const maxNotesPerColumn = notes.length > 6 ? 4 : 3;
//   const columns = [];
//   for(let i = 0; i < notes.length; i += maxNotesPerColumn) {
//       const slice = notes.slice(i, i + maxNotesPerColumn);
//       columns.push(slice);
//   }
  
//   const handleOpenFeedback = () => {
//     setMoreModal(false)
//     setOpenModal(true)
//   }

//   const [docenteInputs, setDocenteInputs] = useState({});

//   const handleInputChange = (docenteId, value) => {
//     setDocenteInputs({
//       ...docenteInputs,
//       [docenteId]: value
//     });
//   };

//   const handleImageUpload = (e, index) => {
//     const file = e.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         const newImages = [...images];
//         newImages[index] = { ...newImages[index], image: reader.result };
//         setImages(newImages);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleOpenNotesModal = () => {
//     setModalOpen(false)
//     setNotesModal(true)
//   }

//   const handleOpenMoreModal = () => {
//     setNotesModal(false)
//     setMoreModal(true)
//   }

//   const handleCheckboxChange = (index) => {
//     const newOptional = [...optional];
//     newOptional[index].valor = !newOptional[index].valor;
//     setOptional(newOptional);
//   }

//   const getValueByName = (name) => {
//     const option = optional?.find(option => option.name === name);
//     return option ? option.valor : false;
//   }

//   const generatePPT = () => {
//     let pptx = new PptxGenJS();

//     // DIAPOSITIVA PORTADA
//     const slide1 = pptx.addSlide();
//     slide1.addImage({ path: '/portada.png', x: 0, y: 0, w: '100%', h: '100%' });
//     slide1.addImage({ path: institution?.logo, x: 8, y: 0.5, w: 1, h: 1 });
//     // DIAPOSITIVA DE TEMAS
//     const slide2 = pptx.addSlide();
//     slide2.addImage({ path: '/ptosTratar.png', x: 0, y: 0, w: '100%', h: '100%' });

//     //DIAPOSITIVA DE CURSOS
//     coursesStage?.forEach(course => {
//       const programs = [];
//       course?.courses?.forEach(cour => {
//           if (!programs.includes(cour.program)) {
//               programs.push(cour.program);
//           }
//       });
//       const programsSorted = programs?.sort((a, b) => a.localeCompare(b));
      
//       programsSorted?.forEach(program => {
//           const filterImg = images?.filter(item => item.program === program);
//           const firstCut = program.replace(/^\d+\)\s*/, '');
//           const result = firstCut.split(' - ')[0].trim();
//           const ind = indicators?.filter(item => item.program === result);
//           if (result !== "B-Block") {
//               // Primera Diapositiva: Título y Descripción
//               const slide1 = pptx.addSlide();
//               slide1.addImage({ path: course?.stage === "Preescolar" ?  '/subtitle3.png' :( course?.stage === "Primaria" ? '/subtitle4.png' : '/subtitle.png'), x: 0, y: 0, w: '100%', h: '100%' });
//               slide1.addText(`${course?.stage}`, {
//                   x: 0.5,
//                   y: 2.5,
//                   fontSize: 22,
//                   color: 'FFFFFF',
//                   bold: true,
//                   fontFace:'Soho Gothic'
//               });
//               slide1.addText(`${result}: ${ind[0]?.frase}`, {
//                   x: 0.5,
//                   y: 3,
//                   fontSize: 16,
//                   color: 'e4e4e4',
//                   fontFace:'Soho Gothic'
//               });
//               // Segunda Diapositiva: Imagen y Lista de Indicadores
//               const slide2 = pptx.addSlide();
//               slide2.addImage({ path: filterImg[0]?.image, x: 0, y: 0, w: 4.3, h: '100%' });
//               ind[0]?.indicators?.forEach((item, index) => {
//                 slide2.addImage({path: `/doodles/${index}.png`, x: 4.5, y: 0.6 + index * 1, w:0.6, h: 0.6 } )
//                 slide2.addText(`${item.title}`, {
//                   x: 5.1,
//                   w: 4.5,
//                   y: 0.6 + index * 1,
//                   fontSize: 12,
//                   color: '330072',
//                   fontFace:'Open Sans',
//                   bold: true,
//                 });
//                 slide2.addText(`${item.description}`, {
//                     x: 5.1,
//                     w: 4.7,
//                     y: 1 + index * 1,
//                     fontSize: 10,
//                     color: '828284',
//                     fontFace:'Open Sans',
//                 });
//               });
//           }
//       });
//     });

//     //LAMINA VISITAS DE SUPERVISIÓN
//     if (Object.keys(docenteInputs).length > 0) {
//       const docenteInputGroups = chunkObjectKeys(docenteInputs, 4);
//       // Crear una nueva diapositiva
//       docenteInputGroups?.forEach(group => {
//         // Crear una nueva diapositiva para cada grupo
//         const slide = pptx.addSlide();
//         // Agregar título a la diapositiva
//         slide.addText('Visitas de acompañamiento', {
//             x: 0.5,
//             y: 0.3,
//             w: '90%',
//             h: 1,
//             fontSize: 24,
//             color: '330072',
//             align: 'center',
//             bold: true,
//             fontFace:'Soho Gothic'
//         });
//         // Variables para posicionar los textos de los docentes en la diapositiva
//         let currentY = 1.5;
//         group?.forEach((docenteId, index) => {
//             const docente = institution?.teachers?.find(d => d.id === parseInt(docenteId));
//             const feedback = docenteInputs[docenteId];
//             // Calcular la posición X para distribuir dos elementos por fila
//             const posX = index % 2 === 0 ? 0.5 : 5.5;
//             // Si se trata del tercer o cuarto elemento, ajustar la posición Y para la segunda fila
//             if (index === 2 || index === 3) {
//                 currentY = 4;
//             }
//             // Agregar nombre del docente
//             slide.addText(`${docente?.info?.first_name} ${docente?.info?.last_name}`, {
//                 x: posX,
//                 y: currentY,
//                 w: 4.5,
//                 fontSize: 18,
//                 color: '330072',
//                 fontFace:'Soho Gothic'
//             });
//             // Agregar feedback del docente
//             slide.addText(feedback, {
//                 x: posX,
//                 y: currentY + 0.7,
//                 w: 4.5,
//                 fontSize: 12,
//                 color: '000000',
//                 fontFace:'Open Sans'
//             });
//         });
//       });
//     }
//     //PROYECCIÓN ACADEMICA
//     const slide3 = pptx.addSlide();
//     slide3.addImage({ path: '/subtitle2.png', x: 0, y: 0, w: '100%', h: '100%' });
//     slide3.addText("Proyección Académica", {
//         x: 0.5,
//         y: 2.5,
//         fontSize: 22,
//         color: 'FFFFFF',
//         bold: true,
//         fontFace:'Soho Gothic'
//     });

//     const slide18 = pptx.addSlide();
//     slide18.addImage({ path: '/proyec2.png', x: 0, y: 0, w: '100%', h: '100%' });
//     slide18.addImage({path: '/bblock2.png', x:0, y:0.8, w:2.6, h: 2})
//     slide18.addImage({path: '/playblock2.png', x:0, y:3, w:2.6, h:2})
//     slide18.addImage({path: proyeccion["proyeccion1"][0].logo, x:2.7, y:1.4, w:1.7, h: 0.8})
//     slide18.addText(proyeccion["proyeccion1"][0].description, 
//     {x:4.5, y:1.8, w:5.3, fontSize: 14, fontFace:'Open Sans', color: '828284'})
//     slide18.addImage({path: proyeccion["proyeccion1"][1].logo, x:2.7, y:3.4, w:1.7, h: 0.8})
//     slide18.addText(proyeccion["proyeccion1"][1].description, 
//     {x:4.5, y:3.8, w:5.3, fontSize: 14, fontFace:'Open Sans', color: '828284'})

//     const slide19 = pptx.addSlide();
//     slide19.addImage({ path: '/proyec2.png', x: 0, y: 0, w: '100%', h: '100%' });
//     slide19.addImage({path: '/block+2.png', x:0, y:0.8, w:2.6, h: 2})
//     slide19.addImage({path: '/makerlab2.png', x:0, y:3, w:2.6, h:2})
//     slide19.addImage({path: proyeccion["proyeccion2"][0].logo, x:2.7, y:1.4, w:1.7, h: 0.8})
//     slide19.addText(proyeccion["proyeccion2"][0].description, 
//     {x:4.5, y:1.8, w:5.3, fontSize: 14, fontFace:'Open Sans', color: '828284'})
//     slide19.addImage({path: proyeccion["proyeccion2"][1].logo, x:2.7, y:3.4, w:1.7, h: 0.8})
//     slide19.addText(proyeccion["proyeccion2"][1].description, 
//     {x:4.5, y:3.8, w:5.3, fontSize: 14, fontFace:'Open Sans', color: '828284'})
    
//     const slide20 = pptx.addSlide();
//     slide20.addImage({ path: '/proyec2.png', x: 0, y: 0, w: '100%', h: '100%' });
//     slide20.addImage({path: '/eboard2.png', x:0, y:0.8, w:2.6, h: 2})
//     slide20.addImage({path: '/ucode2.png', x:0, y:3, w:2.6, h:2})
//     slide20.addImage({path: proyeccion["proyeccion3"][0].logo, x:2.7, y:1.4, w:1.7, h: 0.8})
//     slide20.addText(proyeccion["proyeccion3"][0].description, 
//     {x:4.5, y:1.8, w:5.3, fontSize: 14, fontFace:'Open Sans', color: '828284'})
//     slide20.addImage({path: proyeccion["proyeccion3"][1].logo, x:2.7, y:3.4, w:1.7, h: 0.8})
//     slide20.addText(proyeccion["proyeccion3"][1].description, 
//     {x:4.5, y:3.8, w:5.3, fontSize: 14, fontFace:'Open Sans', color: '828284'})

//     const slide16 = pptx.addSlide();
//     slide16.addImage({ path: '/proyec.png', x: 0, y: 0, w: '100%', h: '100%' });
//     const slide17 = pptx.addSlide();
//     slide17.addImage({ path: '/proyect1.png', x: 0, y: 0, w: '100%', h: '100%' });
    
//     //NUEVOS PROGRAMAS
//     const slide4 = pptx.addSlide();
//     slide4.addImage({ path: '/subtitle2.png', x: 0, y: 0, w: '100%', h: '100%' });
//     slide4.addText("Nuevos Programas", {
//           x: 0.5,
//           y: 2.5,
//           fontSize: 22,
//           color: 'FFFFFF',
//           bold: true,
//           fontFace:'Soho Gothic'
//     });
//     const slide5 = pptx.addSlide();
//     slide5.addImage({ path: '/nuevos1.png', x: 0, y: 0, w: '100%', h: '100%' });
//     const slide6 = pptx.addSlide();
//     slide6.addImage({ path: '/nuevos2.png', x: 0, y: 0, w: '100%', h: '100%' });

//     //PROXIMAS INNOVACIONES
//     const slide7 = pptx.addSlide();
//     slide7.addImage({ path: '/subtitle2.png', x: 0, y: 0, w: '100%', h: '100%' });
//     slide7.addText("Próximas Innovaciones", {
//       x: 0.5,
//       y: 2.5,
//       fontSize: 22,
//       color: 'FFFFFF',
//       bold: true,
//       fontFace:'Soho Gothic'
//     });
//     const slide8 = pptx.addSlide();
//     slide8.addImage({ path: '/prox1.png', x: 0, y: 0, w: '100%', h: '100%' });
//     const slide9 = pptx.addSlide();
//     slide9.addImage({ path: '/prox2.png', x: 0, y: 0, w: '100%', h: '100%' });
//     const slide10 = pptx.addSlide();
//     slide10.addImage({ path: '/prox3.png', x: 0, y: 0, w: '100%', h: '100%' });
//     const slide11 = pptx.addSlide();
//     slide11.addImage({ path: '/prox4.png', x: 0, y: 0, w: '100%', h: '100%' });

//     //KURIOS COMPETITION
//     const datos = resultadosTorneo?.find((item) => item.name === institution.name)
//     const levels = datos && Object.keys(datos.data)
//     const preescolar = levels && levels?.filter((item) => item === "Level 1" || item === "Level 2" || item === "Level 3")
//     preescolar?.sort((a,b) => a.localeCompare(b))
//     const primaria = levels && levels?.filter((item) => item !== "Level 1" && item !== "Level 2" && item !== "Level 3")
//     primaria?.sort((a,b) => b.localeCompare(a))
//     if(datos){
//       const slide12 = pptx.addSlide();
//       slide12.addImage({ path: '/subtitle2.png', x: 0, y: 0, w: '100%', h: '100%' });
//       slide12.addText("Kurios Competition", {
//           x: 0.5,
//           y: 2.5,
//           fontSize: 22,
//           color: 'FFFFFF',
//           bold: true,
//           fontFace:'Soho Gothic'
//       });
//     }
//     if(preescolar?.length > 0){
//       const slide18 = pptx.addSlide();
//       slide18.addImage({ path: '/competition.png', x: 0, y: 0, w: '100%', h: '100%' });
//       slide18.addImage({ path: institution?.logo, x: 7, y: 0.9, w: 0.6, h: 0.6 });
//       slide18.addText(institution?.name, {
//         x: 3.8,
//         y: 1.9,
//         fontSize: 18,
//         color: 'f06400',
//         bold: true,
//         fontFace:'Open Sans'
//       });
//       slide18.addText("Datos de participación", {
//         x: 3.6,
//         y: 2.2,
//         fontSize: 16,
//         color: 'f06400',
//         fontFace:'Open Sans'
//       });
//       preescolar?.forEach((level, index) => {
//         slide18.addText(`${level}`, 
//         { x: 2.6, 
//           y: 3 + index * 0.6, 
//           fontSize: 14, 
//           color: '330072', 
//           bold: true,
//           fontFace:'Open Sans' 
//         });
//         slide18.addShape(pptx.shapes.RECTANGLE, {
//             x: 3.7,
//             y: 2.8 + index * 0.6, 
//             w: 2.7,
//             h: 0.4,
//             rectRadius: 10,
//             line: { color: '330072' },
//             fill: { color: 'FFFFFF' }
//         });
//         }); 
//     }
//     if(primaria?.length > 0){
//       const slide18 = pptx.addSlide();
//       slide18.addImage({ path: '/competition2.png', x: 0, y: 0, w: '100%', h: '100%' });
//       slide18.addImage({ path: institution?.logo, x: 7, y: 0.9, w: 0.6, h: 0.6 });
//       slide18.addText(institution?.name, {
//         x: 4.2,
//         y: 1.6,
//         fontSize: 14,
//         color: 'f06400',
//         bold: true,
//         fontFace:'Open Sans'
//       });
//       slide18.addText("Datos de participación", {
//         x: 4,
//         y: 1.8,
//         fontSize: 12,
//         color: 'f06400',
//         fontFace:'Open Sans'
//       });
//       slide18.addText("Tiempo", {
//         x: 3.7,
//         y: 2.2,
//         fontSize: 10,
//         color: 'f06400',
//         bold: true,
//         fontFace:'Open Sans'
//       });
//       slide18.addText("Posición", {
//         x: 5,
//         y: 2.2,
//         fontSize: 10,
//         color: 'f06400',
//         bold: true,
//         fontFace:'Open Sans'
//       });
//       slide18.addText("Premio", {
//         x: 6.3,
//         y: 2.2,
//         fontSize: 10,
//         color: 'f06400',
//         bold: true,
//         fontFace:'Open Sans'
//       });
//       primaria?.forEach((level, index) => {
//         slide18.addText(`${level}`, 
//         { x: 2.3, 
//           y: 2.5 + index * 0.4, 
//           fontSize: 14, 
//           color: '330072', 
//           bold: true,
//           fontFace:'Open Sans' 
//         });
//         slide18.addShape(pptx.shapes.RECTANGLE, {
//             x: 3.3,
//             y: 2.3 + index * 0.4, 
//             w: 1.5,
//             h: 0.3,
//             rectRadius: 3,
//             line: { color: '330072' },
//             fill: { color: 'FFFFFF' }
//         });
//         slide18.addShape(pptx.shapes.RECTANGLE, {
//           x: 5.1,
//           y: 2.3 + index * 0.4, 
//           w: 0.6,
//           h: 0.3,
//           rectRadius: 3,
//           line: { color: 'f06400' },
//           fill: { color: 'FFFFFF' }
//         })
//       });
//     }

//     //ACTIVIDADES ESPECIALES
//     const slide13 = pptx.addSlide();
//     slide13.addImage({ path: '/subtitle2.png', x: 0, y: 0, w: '100%', h: '100%' });
//     slide13.addText("Actividades Especiales Desarrolladas", {
//           x: 0.5,
//           y: 2.5,
//           fontSize: 22,
//           color: 'FFFFFF',
//           bold: true,
//           fontFace:'Soho Gothic'
//     });
//     if(getValueByName("Capacitaciones en Impresión 3D")){
//       const slide15 = pptx.addSlide();
//       slide15.addImage({ path: '/actesp1.png', x: 0, y: 0, w: '100%', h: '100%' });
//     }
//     if(getValueByName("Visita de docentes destacados")){
//       const slide16 = pptx.addSlide();
//       slide16.addImage({ path: '/actesp2.png', x: 0, y: 0, w: '100%', h: '100%' });
//     }
//     if(getValueByName("Actividad en los colegios (STEM)")){
//       const slide16 = pptx.addSlide();
//       slide16.addImage({ path: '/actesp3.png', x: 0, y: 0, w: '100%', h: '100%' });
//     }
//     if(getValueByName("Feria de robótica")){
//       const slide16 = pptx.addSlide();
//       slide16.addImage({ path: '/actesp4.png', x: 0, y: 0, w: '100%', h: '100%' });
//     }
//     if(getValueByName("Clase abierta")){
//       const slide16 = pptx.addSlide();
//       slide16.addImage({ path: '/actesp5.png', x: 0, y: 0, w: '100%', h: '100%' });
//     }
//     if(getValueByName("Clases demostrativas")){
//       const slide16 = pptx.addSlide();
//       slide16.addImage({ path: '/actesp6.png', x: 0, y: 0, w: '100%', h: '100%' });
//     }
//     if(getValueByName("Video alianza con Kurios")){
//       const slide16 = pptx.addSlide();
//       slide16.addImage({ path: '/actesp7.png', x: 0, y: 0, w: '100%', h: '100%' });
//     }
//     if(getValueByName("Participación en eventos del colegio")){
//       const slide16 = pptx.addSlide();
//       slide16.addImage({ path: '/actesp8.png', x: 0, y: 0, w: '100%', h: '100%' });
//     }
//     if(getValueByName("Reconocimiento profesores")){
//       const slide16 = pptx.addSlide();
//       slide16.addImage({ path: '/actesp9.png', x: 0, y: 0, w: '100%', h: '100%' });
//     }
//     if(getValueByName("Charlas para padres")){
//       const slide16 = pptx.addSlide();
//       slide16.addImage({ path: '/actesp10.png', x: 0, y: 0, w: '100%', h: '100%' });
//     }
    
//     //DIAPOSITIVAS ASUNTOS EMERGENTES
//     if(notes?.length > 0 ){
//       const slide16 = pptx.addSlide();
//       slide16.addImage({ path: '/asuntos.png', x: 0, y: 0, w: '100%', h: '100%' });
//       notes?.forEach((topic, index) => {
//         slide16.addText(`• ${topic}`, 
//         { x: 0.5, 
//           y: 1.5 + index * 1.1, 
//           fontSize: 14, 
//           color: 'FFFFFF', 
//           fontFace:'Open Sans' });
//       });
//     }
    
//     //encuesta
//     const slide40 = pptx.addSlide();
//     slide40.addImage({
//       path: '/encuesta.png',
//       x: 0,
//       y: 0,
//       w: '100%',
//       h: '100%'
//     });
  
//     //final - gracias
//     const slide14 = pptx.addSlide();
//     slide14.addImage({
//       path: '/thanks.png',
//       x: 0,
//       y: 0,
//       w: '100%',
//       h: '100%'
//     });

//     // Descargar la presentación
//     pptx.writeFile({ fileName: 'PresentacionReact.pptx' });
//   };

//   return (
//     <div>
//       {modalOpen &&
//       <Modal title={"Cargar imágenes"} modalOpen={setModalOpen}>
//         <div style={{height:'100%', overflowY:'scroll'}}>
//           {coursesFilter?.map((course, index) => {
//             return(
//              <div key={index} style={{minWidth: '650px'}}>
//               <label>
//                   Foto para {course.program}:
//                   <input type="file" onChange={(e) => handleImageUpload(e, index)} />
//               </label>
//             </div>)
//           })}
//           <div 
//           style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
//           <button onClick={handleOpenNotesModal}
//           className={styles.button}>Siguiente</button>
//           </div>
//           </div>
//       </Modal>}
//       {notesModal &&
//       <Modal title={"Cargar asuntos emergentes"} modalOpen={setNotesModal}>
//         <div style={{height:'100%', overflowY:'scroll'}}>
//           {notes.map((note, index) => (
//             <div key={index} style={{minWidth: '650px', display: 'flex', alignItems: 'center'}}>
//               <textarea 
//                 value={note}
//                 onChange={(e) => handleNoteChange(e, index)}
//                 placeholder="Ingrese el asunto aquí..."
//                 rows="3"
//                 style={{flex: 1, marginRight: '1rem', borderRadius: '10px', 
//                 padding: '5px', marginTop: '5px'}}
//               />
//               <i class="fas fa-times-circle" onClick={() => handleDeleteNote(index)}
//               style={{cursor: 'pointer',  color: '#ee3c3a'}} ></i>
//             </div>
//           ))}
//           <button className={styles.buttonNotes} onClick={handleAddNote}>
//             Agregar Asunto
//           </button>
//           <div 
//           style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
//           <button onClick={handleOpenMoreModal}
//           className={styles.button}>Aceptar</button>
//           </div>
//           </div>
//       </Modal>}
//       {moreModal &&
//       <Modal title={"Seleccionar laminas"} modalOpen={setMoreModal}>
//         <div style={{height:'100%', overflowY:'scroll'}}>
//         <p style={{margin: '5px', padding: '10px'}}>Seleccione las láminas que se deben de incluir en esta presentación</p>
//         <form >
//           {optional.map((option, index) => (
//             <label key={index} style={{marginTop: '3px'}}>
//               {option.name}
//               <input
//                 type="checkbox"
//                 checked={option.valor}
//                 onChange={() => handleCheckboxChange(index)}
//               />
//             </label>
//           ))}
//         </form>
//           <div 
//           style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
//           <button onClick={handleOpenFeedback}
//           className={styles.button}>Aceptar</button>
//           </div>
//           </div>
//       </Modal>}
//       {openModal &&
//         <Modal title={"Feedback Trimestral"} modalOpen={setOpenModal}>
//           <div style={{height:'100%', overflowY:'scroll'}}>
//           {institution?.teachers.map(docente => (
//             <div key={docente.id}>
//               <label>{`${docente.info?.first_name} ${docente.info?.last_name}`}</label>
//               <textarea
//                 value={docenteInputs[docente.id] || ""}
//                 onChange={(e) => handleInputChange(docente.id, e.target.value)}
//                 style={{width: '300px', borderRadius: '10px', padding: '5px'}}
//                 rows={3}
//               />
//             </div>
//           ))}
//             <div style={{width: '100%', justifyContent:'center', display: 'flex'}}>
//               <button onClick={generatePPT} 
//               className={styles.button}>Aceptar</button>
//             </div>
//             </div>
//         </Modal>}
//     </div>
//   )
// }

// export default InstitutionPDF

import React, {useState, useEffect} from 'react'
//import ReactDOM from "react-dom";
import PptxGenJS from 'pptxgenjs';
import { CAPACITACION_ENDPOINT, USER_FORM_ENDPOINT } from '../../../utils/constants';
import { get } from '../../../utils/http';
import Modal from '../../Modal'
import styles from './InstitutionPDF.module.css'
import { indicators, proyeccion } from '../../../utils/programsIndicators';
import { resultadosTorneo } from '../../../utils/constants/competitionResults';
//import MyDocumentPDF from './MyDocumentPDF';

function chunkObjectKeys(obj, chunkSize) {
  const keys = Object.keys(obj);
  const chunks = [];
  for (let i = 0; i < keys.length; i += chunkSize) {
      chunks.push(keys.slice(i, i + chunkSize));
  }
  return chunks;
}

function InstitutionPDF({institution, courses, modalOpen, setModalOpen}) {
  const [teachers, setTeachers] = useState(institution?.teachers)
  const [images, setImages] = useState([]);

  const coursesFilter = courses?.reduce((acc, curso) => {
    const existe = acc.some(c => c.program?.split(' - ')[0].trim() === curso.program?.split(' - ')[0].trim());
    if (!existe) {
      acc.push(curso);
    }
    return acc;
  }, []);
  useEffect(() => {
    if (coursesFilter && coursesFilter?.length > 0) {
      const newInitialState = coursesFilter?.map(course => ({ image: null, program: course.program }));
      setImages(newInitialState);
    }
  }, [courses]);

  const [images1, setImages1] = useState([]);


  useEffect(() => {
    const fetchImage = async () => {
      if (!institution?.logo) return;
      try {
        const response = await fetch(institution.logo, 
          { method: 'GET',
           mode: 'no-cors' ,
           headers: {
             'Access-Control-Allow-Origin': '*'
           }});
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          setImages1(prevState => {
            if (prevState.length === 0) {
              return [{ image: reader.result }];
            } else {
              return prevState.map((image, index) => {
                if (index === 0) {
                  return { ...image, image: reader.result };
                }
                return image;
              });
            }
          });
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };

    fetchImage();
  }, [institution]);
  const [notes, setNotes] = useState([]);
  const [notesModal, setNotesModal] = useState(false)
  const [moreModal, setMoreModal] = useState(false)
  const [optional, setOptional] = useState([
      { id: 1, name: "Capacitaciones en Impresión 3D",
      valor: false},
    { id: 2, name: "Visita a Kurios para docentes destacados",
      valor: false},
      { id: 3, name: "Actividad en los colegios (STEM)",
      valor: false},
      { id: 4, name: "Feria de robótica",
      valor: false},
      { id: 5, name: "Clase abierta",
      valor: false},
      { id: 6, name: "Clases demostrativas",
      valor: false},
      { id: 7, name: "Video alianza con Kurios",
      valor: false},
      { id: 8, name: "Participación en eventos del colegio",
      valor: false},
      { id: 9, name: "Reconocimiento profesores",
      valor: false},
      { id: 10, name: "Charlas para padres",
      valor: false},
    
  ])
  const [openModal, setOpenModal] = useState(false)
  const [teacherData, setTeacherData] = useState([]);
        
  useEffect(() => {
    const fetchAllData = async () => {
    const fetchedTeacherData = await Promise.all(
      institution?.teachers?.map(async (teacher) => {
        const capacitacionesResponse = await get({
          url: `${CAPACITACION_ENDPOINT}user/${teacher.info.id}/`,
        });
        const capacitacionesFiltered = capacitacionesResponse.data.filter((item) => item.term.name === "II")
        const encuestasResponse = await get({
          url: `${USER_FORM_ENDPOINT}user/${teacher?.info?.id}/`,
        });
        const capacitacionesConTareas = capacitacionesResponse.error
        ? 0
        : capacitacionesFiltered?.reduce((count, capacitacion) => {
            return (capacitacion.training_task_user > 0 || capacitacion.quiz_user) ? count + 1 : count;
          }, 0);
        const encuestasListas = encuestasResponse.error
        ? 0
        : encuestasResponse?.data?.reduce((count, form) => {
            return form.completed ? count + 1 : count;
          }, 0);
        return {
          ...teacher,
          capacitaciones: capacitacionesResponse.error ? null : capacitacionesFiltered,
          encuestas: encuestasResponse.error ? null : encuestasResponse.data,
          capacitacionesConTareas,
          encuestasListas
        };
      }) || []
    );
    setTeacherData(fetchedTeacherData);
    };
  fetchAllData();
  }, [institution?.teachers]);
  const coursesByStage = () => {
    const levels = {
      "Preescolar": ["Maternal", "I Nivel", "II Nivel", "III Nivel", "ll Nivel"],
      "Primaria": ["1er Grado", "2do Grado", "3er Grado", "4to Grado", "5to Grado", "6to Grado"],
      "Bachillerato": ["1er Año", "2do Año", "3er Año", "4to Año", "5to Año"]
    };
  
    const resultado = [];
  
    for (const [stage, level] of Object.entries(levels)) {
      const cursosEtapa = courses?.filter(course => level?.includes(course?.institution_level));
      const cursosFiltrados = cursosEtapa?.reduce((acc, curso) => {
        const existe = acc.some(c => c.program?.split(' - ')[0].trim() === curso.program?.split(' - ')[0].trim());
        if (!existe) {
          acc.push(curso);
        }
        return acc;
      }, []);

      cursosFiltrados?.sort((a, b) => {
        const indexA = level?.indexOf(a.institution_level);
        const indexB = level?.indexOf(b.institution_level);
        return indexA - indexB;
      });
      
      resultado.push({
        stage,
        courses: cursosFiltrados
      });
    }
  
    return resultado;
  };
  
  const coursesStage = coursesByStage();

  const handleAddNote = () => {
    setNotes([...notes, ""]); 
  };

  const handleNoteChange = (e, index) => {
    const newNotes = [...notes];
    newNotes[index] = e.target.value;
    setNotes(newNotes);
  };

  const handleDeleteNote = (index) => {
    const newNotes = [...notes];
    newNotes.splice(index, 1); 
    setNotes(newNotes);
  };

  const selectedImages = images?.slice(0, 3);

  const maxNotesPerColumn = notes.length > 6 ? 4 : 3;
  const columns = [];
  for(let i = 0; i < notes.length; i += maxNotesPerColumn) {
      const slice = notes.slice(i, i + maxNotesPerColumn);
      columns.push(slice);
  }
  
  const handleOpenFeedback = () => {
    setMoreModal(false)
    setOpenModal(true)
  }

  const [docenteInputs, setDocenteInputs] = useState({});

  const handleInputChange = (docenteId, value) => {
    setDocenteInputs({
      ...docenteInputs,
      [docenteId]: value
    });
  };

  const handleImageUpload = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newImages = [...images];
        newImages[index] = { ...newImages[index], image: reader.result };
        setImages(newImages);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOpenNotesModal = () => {
    setModalOpen(false)
    setNotesModal(true)
  }

  const handleOpenMoreModal = () => {
    setNotesModal(false)
    setMoreModal(true)
  }

  const handleCheckboxChange = (index) => {
    const newOptional = [...optional];
    newOptional[index].valor = !newOptional[index].valor;
    setOptional(newOptional);
  }

  const getValueByName = (name) => {
    const option = optional?.find(option => option.name === name);
    return option ? option.valor : false;
  }

  const generatePPT = () => {
    let pptx = new PptxGenJS();
    // DIAPOSITIVA PORTADA
    const slide1 = pptx.addSlide();
    slide1.addImage({ path: '/portada.png', x: 0, y: 0, w: '100%', h: '100%' });
    if (images1.length > 0 && images1[0].image) {
      slide1.addImage({ data: images1[0].image, x: 8, y: 0.5, w: 1, h: 1 });
    } else {
      console.error('No image data available for the institution logo');
    }    // DIAPOSITIVA DE TEMAS
    const slide2 = pptx.addSlide();
    slide2.addImage({ path: '/ptosTratar.png', x: 0, y: 0, w: '100%', h: '100%' });

    //DIAPOSITIVA DE CURSOS
    coursesStage?.forEach(course => {
      const programs = [];
      course?.courses?.forEach(cour => {
          if (!programs.includes(cour.program)) {
              programs.push(cour.program);
          }
      });
      const programsSorted = programs?.sort((a, b) => a.localeCompare(b));
      
      programsSorted?.forEach(program => {
          const filterImg = images?.filter(item => item.program === program);
          const firstCut = program.replace(/^\d+\)\s*/, '');
          const result = firstCut.split(' - ')[0].trim();
          const ind = indicators?.filter(item => item.program === result);
          if (result !== "B-Block") {
              // Primera Diapositiva: Título y Descripción
              const slide1 = pptx.addSlide();
              slide1.addImage({ path: course?.stage === "Preescolar" ?  '/subtitle3.png' :( course?.stage === "Primaria" ? '/subtitle4.png' : '/subtitle.png'), x: 0, y: 0, w: '100%', h: '100%' });
              slide1.addText(`${course?.stage}`, {
                  x: 0.5,
                  y: 2.5,
                  fontSize: 22,
                  color: 'FFFFFF',
                  bold: true,
                  fontFace:'Soho Gothic'
              });
              slide1.addText(`${result}: ${ind[0]?.frase}`, {
                  x: 0.5,
                  y: 3,
                  fontSize: 16,
                  color: 'e4e4e4',
                  fontFace:'Soho Gothic'
              });
              // Segunda Diapositiva: Imagen y Lista de Indicadores
              const slide2 = pptx.addSlide();
              slide2.addImage({ path: filterImg[0]?.image, x: 0, y: 0, w: 4.3, h: '100%' });
              ind[0]?.indicators?.forEach((item, index) => {
                slide2.addImage({path: `/doodles/${index}.png`, x: 4.5, y: 0.6 + index * 1, w:0.6, h: 0.6 } )
                slide2.addText(`${item.title}`, {
                  x: 5.1,
                  w: 4.5,
                  y: 0.6 + index * 1,
                  fontSize: 12,
                  color: '330072',
                  fontFace:'Open Sans',
                  bold: true,
                });
                slide2.addText(`${item.description}`, {
                    x: 5.1,
                    w: 4.7,
                    y: 1 + index * 1,
                    fontSize: 10,
                    color: '828284',
                    fontFace:'Open Sans',
                });
              });
          }
      });
    });

    //LAMINA VISITAS DE SUPERVISIÓN
    if (Object.keys(docenteInputs).length > 0) {
      const docenteInputGroups = chunkObjectKeys(docenteInputs, 4);
      // Crear una nueva diapositiva
      docenteInputGroups?.forEach(group => {
        // Crear una nueva diapositiva para cada grupo
        const slide = pptx.addSlide();
        // Agregar título a la diapositiva
        slide.addText('Visitas de acompañamiento', {
            x: 0.5,
            y: 0.3,
            w: '90%',
            h: 1,
            fontSize: 24,
            color: '330072',
            align: 'center',
            bold: true,
            fontFace:'Soho Gothic'
        });
        // Variables para posicionar los textos de los docentes en la diapositiva
        let currentY = 1.5;
        group?.forEach((docenteId, index) => {
            const docente = institution?.teachers?.find(d => d.id === parseInt(docenteId));
            const feedback = docenteInputs[docenteId];
            // Calcular la posición X para distribuir dos elementos por fila
            const posX = index % 2 === 0 ? 0.5 : 5.5;
            // Si se trata del tercer o cuarto elemento, ajustar la posición Y para la segunda fila
            if (index === 2 || index === 3) {
                currentY = 4;
            }
            // Agregar nombre del docente
            slide.addText(`${docente?.info?.first_name} ${docente?.info?.last_name}`, {
                x: posX,
                y: currentY,
                w: 4.5,
                fontSize: 18,
                color: '330072',
                fontFace:'Soho Gothic'
            });
            // Agregar feedback del docente
            slide.addText(feedback, {
                x: posX,
                y: currentY + 0.7,
                w: 4.5,
                fontSize: 12,
                color: '000000',
                fontFace:'Open Sans'
            });
        });
      });
    }
    //PROYECCIÓN ACADEMICA
    const slide3 = pptx.addSlide();
    
    slide3.addImage({ path: '/subtitle2.png', x: 0, y: 0, w: '100%', h: '100%' });
    slide3.addText("Proyección Académica", {
        x: 0.5,
        y: 2.5,
        fontSize: 22,
        color: 'FFFFFF',
        bold: true,
        fontFace:'Soho Gothic'
    });

    const slide18 = pptx.addSlide();
    slide18.addImage({ path: '/proyec2.png', x: 0, y: 0, w: '100%', h: '100%' });
    slide18.addImage({path: '/bblock2.png', x:0, y:0.8, w:2.6, h: 2})
    slide18.addImage({path: '/playblock2.png', x:0, y:3, w:2.6, h:2})
    slide18.addImage({path: proyeccion["proyeccion1"][0].logo, x:2.7, y:1.4, w:1.7, h: 0.8})
    slide18.addText(proyeccion["proyeccion1"][0].description, 
    {x:4.5, y:1.8, w:5.3, fontSize: 14, fontFace:'Open Sans', color: '828284'})
    slide18.addImage({path: proyeccion["proyeccion1"][1].logo, x:2.7, y:3.4, w:1.7, h: 0.8})
    slide18.addText(proyeccion["proyeccion1"][1].description, 
    {x:4.5, y:3.8, w:5.3, fontSize: 14, fontFace:'Open Sans', color: '828284'})

    const slide19 = pptx.addSlide();
    slide19.addImage({ path: '/proyec2.png', x: 0, y: 0, w: '100%', h: '100%' });
    slide19.addImage({path: '/block+2.png', x:0, y:0.8, w:2.6, h: 2})
    slide19.addImage({path: '/makerlab2.png', x:0, y:3, w:2.6, h:2})
    slide19.addImage({path: proyeccion["proyeccion2"][0].logo, x:2.7, y:1.4, w:1.7, h: 0.8})
    slide19.addText(proyeccion["proyeccion2"][0].description, 
    {x:4.5, y:1.8, w:5.3, fontSize: 14, fontFace:'Open Sans', color: '828284'})
    slide19.addImage({path: proyeccion["proyeccion2"][1].logo, x:2.7, y:3.4, w:1.7, h: 0.8})
    slide19.addText(proyeccion["proyeccion2"][1].description, 
    {x:4.5, y:3.8, w:5.3, fontSize: 14, fontFace:'Open Sans', color: '828284'})
    
    const slide20 = pptx.addSlide();
    slide20.addImage({ path: '/proyec2.png', x: 0, y: 0, w: '100%', h: '100%' });
    slide20.addImage({path: '/eboard2.png', x:0, y:0.8, w:2.6, h: 2})
    slide20.addImage({path: '/ucode2.png', x:0, y:3, w:2.6, h:2})
    slide20.addImage({path: proyeccion["proyeccion3"][0].logo, x:2.7, y:1.4, w:1.7, h: 0.8})
    slide20.addText(proyeccion["proyeccion3"][0].description, 
    {x:4.5, y:1.8, w:5.3, fontSize: 14, fontFace:'Open Sans', color: '828284'})
    slide20.addImage({path: proyeccion["proyeccion3"][1].logo, x:2.7, y:3.4, w:1.7, h: 0.8})
    slide20.addText(proyeccion["proyeccion3"][1].description, 
    {x:4.5, y:3.8, w:5.3, fontSize: 14, fontFace:'Open Sans', color: '828284'})

    const slide16 = pptx.addSlide();
    slide16.addImage({ path: '/proyec.png', x: 0, y: 0, w: '100%', h: '100%' });
    const slide17 = pptx.addSlide();
    slide17.addImage({ path: '/proyect1.png', x: 0, y: 0, w: '100%', h: '100%' });
    
    //NUEVOS PROGRAMAS
    const slide4 = pptx.addSlide();
    slide4.addImage({ path: '/subtitle2.png', x: 0, y: 0, w: '100%', h: '100%' });
    slide4.addText("Nuevos Programas", {
          x: 0.5,
          y: 2.5,
          fontSize: 22,
          color: 'FFFFFF',
          bold: true,
          fontFace:'Soho Gothic'
    });
    const slide5 = pptx.addSlide();
    slide5.addImage({ path: '/nuevos1.png', x: 0, y: 0, w: '100%', h: '100%' });
    const slide6 = pptx.addSlide();
    slide6.addImage({ path: '/nuevos2.png', x: 0, y: 0, w: '100%', h: '100%' });

    //PROXIMAS INNOVACIONES
    const slide7 = pptx.addSlide();
    slide7.addImage({ path: '/subtitle2.png', x: 0, y: 0, w: '100%', h: '100%' });
    slide7.addText("Próximas Innovaciones", {
      x: 0.5,
      y: 2.5,
      fontSize: 22,
      color: 'FFFFFF',
      bold: true,
      fontFace:'Soho Gothic'
    });
    const slide8 = pptx.addSlide();
    slide8.addImage({ path: '/prox1.png', x: 0, y: 0, w: '100%', h: '100%' });
    const slide9 = pptx.addSlide();
    slide9.addImage({ path: '/prox2.png', x: 0, y: 0, w: '100%', h: '100%' });
    const slide10 = pptx.addSlide();
    slide10.addImage({ path: '/prox3.png', x: 0, y: 0, w: '100%', h: '100%' });
    const slide11 = pptx.addSlide();
    slide11.addImage({ path: '/prox4.png', x: 0, y: 0, w: '100%', h: '100%' });

    //KURIOS COMPETITION
    const datos = resultadosTorneo?.find((item) => item.name === institution.name)
    const levels = datos && Object.keys(datos.data)
    const preescolar = levels && levels?.filter((item) => item === "Level 1" || item === "Level 2" || item === "Level 3")
    preescolar?.sort((a,b) => a.localeCompare(b))
    const primaria = levels && levels?.filter((item) => item !== "Level 1" && item !== "Level 2" && item !== "Level 3")
    primaria?.sort((a,b) => b.localeCompare(a))
    if(datos){
      const slide12 = pptx.addSlide();
      slide12.addImage({ path: '/subtitle2.png', x: 0, y: 0, w: '100%', h: '100%' });
      slide12.addText("Kurios Competition", {
          x: 0.5,
          y: 2.5,
          fontSize: 22,
          color: 'FFFFFF',
          bold: true,
          fontFace:'Soho Gothic'
      });
    }
    if(preescolar?.length > 0){
      const slide18 = pptx.addSlide();
      slide18.addImage({ path: '/competition.png', x: 0, y: 0, w: '100%', h: '100%' });
      if (images1.length > 0 && images1[0].image) {
        slide18.addImage({ data: images1[0].image, x: 7, y: 0.9, w: 0.6, h: 0.6 });
      } else {
        console.error('No image data available for the institution logo');
      }
      // slide18.addImage({ data: images[0].image, x: 7, y: 0.9, w: 0.6, h: 0.6 });
      slide18.addText(institution?.name, {
        x: 3.8,
        y: 1.9,
        fontSize: 18,
        color: 'f06400',
        bold: true,
        fontFace:'Open Sans'
      });
      slide18.addText("Datos de participación", {
        x: 3.6,
        y: 2.2,
        fontSize: 16,
        color: 'f06400',
        fontFace:'Open Sans'
      });
      preescolar?.forEach((level, index) => {
        slide18.addText(`${level}`, 
        { x: 2.6, 
          y: 3 + index * 0.6, 
          fontSize: 14, 
          color: '330072', 
          bold: true,
          fontFace:'Open Sans' 
        });
        slide18.addShape(pptx.shapes.RECTANGLE, {
            x: 3.7,
            y: 2.8 + index * 0.6, 
            w: 2.7,
            h: 0.4,
            rectRadius: 10,
            line: { color: '330072' },
            fill: { color: 'FFFFFF' }
        });
        }); 
    }
    if(primaria?.length > 0){
      const slide18 = pptx.addSlide();
      slide18.addImage({ path: '/competition2.png', x: 0, y: 0, w: '100%', h: '100%' });
      if (images1.length > 0 && images1[0].image) {
        slide18.addImage({ data: images1[0].image, x: 7, y: 0.9, w: 0.6, h: 0.6});
      } else {
        console.error('No image data available for the institution logo');
      }
      // slide18.addImage({ data: images[0].image, x: 7, y: 0.9, w: 0.6, h: 0.6 });
      slide18.addText(institution?.name, {
        x: 4.2,
        y: 1.6,
        fontSize: 14,
        color: 'f06400',
        bold: true,
        fontFace:'Open Sans'
      });
      slide18.addText("Datos de participación", {
        x: 4,
        y: 1.8,
        fontSize: 12,
        color: 'f06400',
        fontFace:'Open Sans'
      });
      slide18.addText("Tiempo", {
        x: 3.7,
        y: 2.2,
        fontSize: 10,
        color: 'f06400',
        bold: true,
        fontFace:'Open Sans'
      });
      slide18.addText("Posición", {
        x: 5,
        y: 2.2,
        fontSize: 10,
        color: 'f06400',
        bold: true,
        fontFace:'Open Sans'
      });
      slide18.addText("Premio", {
        x: 6.3,
        y: 2.2,
        fontSize: 10,
        color: 'f06400',
        bold: true,
        fontFace:'Open Sans'
      });
      primaria?.forEach((level, index) => {
        slide18.addText(`${level}`, 
        { x: 2.3, 
          y: 2.5 + index * 0.4, 
          fontSize: 14, 
          color: '330072', 
          bold: true,
          fontFace:'Open Sans' 
        });
        slide18.addShape(pptx.shapes.RECTANGLE, {
            x: 3.3,
            y: 2.3 + index * 0.4, 
            w: 1.5,
            h: 0.3,
            rectRadius: 3,
            line: { color: '330072' },
            fill: { color: 'FFFFFF' }
        });
        slide18.addShape(pptx.shapes.RECTANGLE, {
          x: 5.1,
          y: 2.3 + index * 0.4, 
          w: 0.6,
          h: 0.3,
          rectRadius: 3,
          line: { color: 'f06400' },
          fill: { color: 'FFFFFF' }
        })
      });
    }

    //ACTIVIDADES ESPECIALES
    const slide13 = pptx.addSlide();
    slide13.addImage({ path: '/subtitle2.png', x: 0, y: 0, w: '100%', h: '100%' });
    slide13.addText("Actividades Especiales Desarrolladas", {
          x: 0.5,
          y: 2.5,
          fontSize: 22,
          color: 'FFFFFF',
          bold: true,
          fontFace:'Soho Gothic'
    });
    if(getValueByName("Capacitaciones en Impresión 3D")){
      const slide15 = pptx.addSlide();
      slide15.addImage({ path: '/actesp1.png', x: 0, y: 0, w: '100%', h: '100%' });
    }
    if(getValueByName("Visita de docentes destacados")){
      const slide16 = pptx.addSlide();
      slide16.addImage({ path: '/actesp2.png', x: 0, y: 0, w: '100%', h: '100%' });
    }
    if(getValueByName("Actividad en los colegios (STEM)")){
      const slide16 = pptx.addSlide();
      slide16.addImage({ path: '/actesp3.png', x: 0, y: 0, w: '100%', h: '100%' });
    }
    if(getValueByName("Feria de robótica")){
      const slide16 = pptx.addSlide();
      slide16.addImage({ path: '/actesp4.png', x: 0, y: 0, w: '100%', h: '100%' });
    }
    if(getValueByName("Clase abierta")){
      const slide16 = pptx.addSlide();
      slide16.addImage({ path: '/actesp5.png', x: 0, y: 0, w: '100%', h: '100%' });
    }
    if(getValueByName("Clases demostrativas")){
      const slide16 = pptx.addSlide();
      slide16.addImage({ path: '/actesp6.png', x: 0, y: 0, w: '100%', h: '100%' });
    }
    if(getValueByName("Video alianza con Kurios")){
      const slide16 = pptx.addSlide();
      slide16.addImage({ path: '/actesp7.png', x: 0, y: 0, w: '100%', h: '100%' });
    }
    if(getValueByName("Participación en eventos del colegio")){
      const slide16 = pptx.addSlide();
      slide16.addImage({ path: '/actesp8.png', x: 0, y: 0, w: '100%', h: '100%' });
    }
    if(getValueByName("Reconocimiento profesores")){
      const slide16 = pptx.addSlide();
      slide16.addImage({ path: '/actesp9.png', x: 0, y: 0, w: '100%', h: '100%' });
    }
    if(getValueByName("Charlas para padres")){
      const slide16 = pptx.addSlide();
      slide16.addImage({ path: '/actesp10.png', x: 0, y: 0, w: '100%', h: '100%' });
    }
    
    //DIAPOSITIVAS ASUNTOS EMERGENTES
    if(notes?.length > 0 ){
      const slide16 = pptx.addSlide();
      slide16.addImage({ path: '/asuntos.png', x: 0, y: 0, w: '100%', h: '100%' });
      notes?.forEach((topic, index) => {
        slide16.addText(`• ${topic}`, 
        { x: 0.5, 
          y: 1.5 + index * 1.1, 
          fontSize: 14, 
          color: 'FFFFFF', 
          fontFace:'Open Sans' });
      });
    }
    
    //encuesta
    const slide40 = pptx.addSlide();
    slide40.addImage({
      path: '/encuesta.png',
      x: 0,
      y: 0,
      w: '100%',
      h: '100%'
    });
  
    //final - gracias
    const slide14 = pptx.addSlide();
    slide14.addImage({
      path: '/thanks.png',
      x: 0,
      y: 0,
      w: '100%',
      h: '100%'
    });

    // Descargar la presentación
    pptx.writeFile({ fileName: 'PresentacionReact.pptx' });
  };

  return (
    <div>
      {modalOpen &&
      <Modal title={"Cargar imágenes"} modalOpen={setModalOpen}>
        <div style={{height:'100%', overflowY:'scroll'}}>
          {coursesFilter?.map((course, index) => {
            return(
             <div key={index} style={{minWidth: '650px'}}>
              <label>
                  Foto para {course.program}:
                  <input type="file" onChange={(e) => handleImageUpload(e, index)} />
              </label>
            </div>)
          })}
          <div 
          style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
          <button onClick={handleOpenNotesModal}
          className={styles.button}>Siguiente</button>
          </div>
          </div>
      </Modal>}
      {notesModal &&
      <Modal title={"Cargar asuntos emergentes"} modalOpen={setNotesModal}>
        <div style={{height:'100%', overflowY:'scroll'}}>
          {notes.map((note, index) => (
            <div key={index} style={{minWidth: '650px', display: 'flex', alignItems: 'center'}}>
              <textarea 
                value={note}
                onChange={(e) => handleNoteChange(e, index)}
                placeholder="Ingrese el asunto aquí..."
                rows="3"
                style={{flex: 1, marginRight: '1rem', borderRadius: '10px', 
                padding: '5px', marginTop: '5px'}}
              />
              <i class="fas fa-times-circle" onClick={() => handleDeleteNote(index)}
              style={{cursor: 'pointer',  color: '#ee3c3a'}} ></i>
            </div>
          ))}
          <button className={styles.buttonNotes} onClick={handleAddNote}>
            Agregar Asunto
          </button>
          <div 
          style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
          <button onClick={handleOpenMoreModal}
          className={styles.button}>Aceptar</button>
          </div>
          </div>
      </Modal>}
      {moreModal &&
      <Modal title={"Seleccionar laminas"} modalOpen={setMoreModal}>
        <div style={{height:'100%', overflowY:'scroll'}}>
        <p style={{margin: '5px', padding: '10px'}}>Seleccione las láminas que se deben de incluir en esta presentación</p>
        <form >
          {optional.map((option, index) => (
            <label key={index} style={{marginTop: '3px'}}>
              {option.name}
              <input
                type="checkbox"
                checked={option.valor}
                onChange={() => handleCheckboxChange(index)}
              />
            </label>
          ))}
        </form>
          <div 
          style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
          <button onClick={handleOpenFeedback}
          className={styles.button}>Aceptar</button>
          </div>
          </div>
      </Modal>}
      {openModal &&
        <Modal title={"Feedback Trimestral"} modalOpen={setOpenModal}>
          <div style={{height:'100%', overflowY:'scroll'}}>
          {institution?.teachers.map(docente => (
            <div key={docente.id}>
              <label>{`${docente.info?.first_name} ${docente.info?.last_name}`}</label>
              <textarea
                value={docenteInputs[docente.id] || ""}
                onChange={(e) => handleInputChange(docente.id, e.target.value)}
                style={{width: '300px', borderRadius: '10px', padding: '5px'}}
                rows={3}
              />
            </div>
          ))}
            <div style={{width: '100%', justifyContent:'center', display: 'flex'}}>
              <button onClick={generatePPT} 
              className={styles.button}>Aceptar</button>
            </div>
            </div>
        </Modal>}
    </div>
  )
}

export default InstitutionPDF

