export const resultadosTorneo = [
    {
        "name": "Colegio Champagnat",
        "data": {
            "Level 12": {
                "position": "2",
                "time": "12:34"
            },
            "Level 4": {
                "position": "5",
                "time": "12:34"
            },
            "Level 6": {
                "position": "8",
                "time": "28:17"
            },
            "Level 11": {
                "position": "12",
                "time": "35:59"
            }
        }
    },
    {
        "name": "Colegio Chaves",
        "data": {
            "Level 12": {
                "position": "9",
                "time": "Reto no finalizado"
            },
            "Level 11": {
                "position": "18",
                "time": "Reto no finalizado"
            },
            "Level 6": {
                "position": "16",
                "time": "37:19"
            }
        }
    },
    {
        "name": "Instituto Santa Cruz",
        "data": {
            "Level 2": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "CEAMM",
        "data": {
            "Level 1": {
                "aproved": "Reto aprobado",
            },
            "Level 7": {
                "position": "-",
                "time": "Reto no finalizado"
            },
            "Level 8": {
                "position": "-",
                "time": "Reto no finalizado"
            },
            "Level 5": {
                "position": "-",
                "time": "Reto no finalizado"
            },
            "Level 3": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Italo",
        "data": {
            "Level 16": {
                "position": "1",
                "time": "144"
            },
            "Level 14": {
                "position": "2",
                "time": "14:14"
            },
            "Level 5": {
                "position": "3",
                "time": "12:22"
            },
            "Level 10": {
                "position": "6",
                "time": "30:33"
            },
            "Level 7": {
                "position": "7",
                "time": "37:04"
            },
            "Level 9": {
                "position": "5",
                "time": "32:09"
            },
            "Level 13": {
                "position": "-",
                "time": "Reto no finalizado"
            },
            "Level 3": {
                "aproved": "Reto aprobado",
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Simón Bolívar II",
        "data": {
            "Level 14": {
                "position": "-",
                "time": "Reto no finalizado"
            },
            "Level 13": {
                "position": "-",
                "time": "Reto no finalizado"
            },
            "Level 10": {
                "position": "1",
                "time": "11:23"
            },
            "Level 9": {
                "position": "1",
                "time": "09:21"
            },
            "Level 15": {
                "position": "5",
                "time": "08:23"
            }
        }
    },
    {
        "name": "Colegio San Agustín El Marqués",
        
        "data": {
            "Level 16": {
                "position": "5",
                "time": "118"
            },
            "Level 7": {
                "position": "6",
                "time": "29:42"
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            },
            "Level 8": {
                "position": "-",
                "time": "Reto no finalizado"
            },
            "Level 15": {
                "position": "12",
                "time": "39:33"
            },
            "Level 14": {
                "position": "-",
                "time": "Reto no finalizado"
            },
            "Level 13": {
                "position": "2",
                "time": "28:22"
            },
            "Level 3": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Nuestra Señora de Pompei",
        "data": {
            "Level 14": {
                "position": "-",
                "time": "Reto no finalizado"
            },
            "Level 7": {
                "position": "-",
                "time": "Reto no finalizado"
            },
            "Level 5": {
                "position": "15",
                "time": "32:45"
            },
            "Level 8": {
                "position": "1",
                "time": "07:57"
            },
            "Level 3": {
                "aproved": "Reto aprobado",
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Instituto Educacional Henry Clay",
       
        "data": {
            "Level 1": {
                "aproved": "Reto aprobado",
            },
            "Level 3": {
                "aproved": "Reto aprobado",
            },
            "Level 13": {
                "position": "-",
                "time": "Reto no finalizado"
            },
            "Level 8": {
                "position": "11",
                "time": "35:28"
            },
            "Level 7": {
                "position": "4",
                "time": "24:06"
            },
            "Level 5": {
                "position": "1",
                "time": "09:17"
            }
        }
    },
    {
        "name": "Colegio San Agustín El Paraíso",
        "data": {
            "Level 3": {
                "aproved": "Reto aprobado",
            },
            "Level 13": {
                "position": "",
                "time": ""
            },
            "Level 15": {
                "position": "",
                "time": ""
            },
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            },
            "Level 10": {
                "position": "",
                "time": ""
            },
            "Level 9": {
                "position": "",
                "time": ""
            },
            "Level 14": {
                "position": "",
                "time": ""
            },
            "Level 7": {
                "position": "",
                "time": ""
            }
        }
    },
    {
        "name": "Colegio Madre Matilde",
        
        "data": {
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 15": {
                "position": "",
                "time": ""
            },
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 13": {
                "position": "",
                "time": ""
            },
            "Level 14": {
                "position": "",
                "time": ""
            },
            "Level 8": {
                "position": "",
                "time": ""
            },
            "Level 3": {
                "aproved": "Reto aprobado",
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Don Bosco",
        
        "data": {
            "Level 14": {
                "position": "",
                "time": ""
            },
            "Level 13": {
                "position": "",
                "time": ""
            },
            "Level 15": {
                "position": "",
                "time": ""
            },
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 8": {
                "position": "",
                "time": ""
            },
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Bolivar y Giuseppe Garibaldi",
       
        "data": {
            "Level 12": {
                "position": "",
                "time": ""
            },
            "Level 11": {
                "position": "",
                "time": ""
            },
            "Level 4": {
                "position": "",
                "time": ""
            },
            "Level 6": {
                "position": "",
                "time": ""
            },
            "Level 2": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Kurios Academy",
        
        "data": {
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 15": {
                "position": "",
                "time": ""
            },
            "Level 11": {
                "position": "",
                "time": ""
            },
            "Level 6": {
                "position": "",
                "time": ""
            },
            "Level 8": {
                "position": "",
                "time": ""
            },
            "Level 13": {
                "position": "",
                "time": ""
            },
            "Level 14": {
                "position": "",
                "time": ""
            },
            "Level 3": {
                "aproved": "Reto aprobado",
            },
            "Level 2": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Instituto Andes",
       
        "data": {
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 8": {
                "position": "",
                "time": ""
            },
            "Level 3": {
                "aproved": "Reto aprobado",
            },
            "Level 13": {
                "position": "",
                "time": ""
            },
            "Level 14": {
                "position": "",
                "time": ""
            },
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Academia Merici",
       
        "data": {
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            },
            "Level 13": {
                "position": "",
                "time": ""
            },
            "Level 10": {
                "position": "",
                "time": ""
            },
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 9": {
                "position": "",
                "time": ""
            }
        }
    },
    {
        "name": "Colegio El Angel",
       
        "data": {
            "Level 2": {
                "aproved": "Reto aprobado",
            },
            "Level 12": {
                "position": "",
                "time": ""
            },
            "Level 6": {
                "position": "",
                "time": ""
            },
            "Level 4": {
                "position": "",
                "time": ""
            }
        }
    },
    {
        "name": "Colegio Los Campitos",
        "data": {
            "Level 14": {
                "position": "",
                "time": ""
            },
            "Level 15": {
                "position": "",
                "time": ""
            },
            "Level 9": {
                "position": "",
                "time": ""
            },
            "Level 13": {
                "position": "",
                "time": ""
            },
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 10": {
                "position": "",
                "time": ""
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            },
            "Level 3": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Mater Salvatoris",
        "data": {
            "Level 13": {
                "position": "",
                "time": ""
            },
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 8": {
                "position": "",
                "time": ""
            },
            "Level 14": {
                "position": "",
                "time": ""
            },
            "Level 16": {
                "position": "",
                "time": ""
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            },
            "Level 3": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Mater Salvatoris Maracaibo",
       
        "data": {
            "Level 14": {
                "position": "",
                "time": ""
            },
            "Level 2": {
                "aproved": "Reto aprobado",
            },
            "Level 15": {
                "position": "",
                "time": ""
            },
            "Level 16": {
                "position": "",
                "time": ""
            },
            "Level 6": {
                "position": "",
                "time": ""
            },
            "Level 13": {
                "position": "",
                "time": ""
            },
            "Level 4": {
                "position": "",
                "time": ""
            }
        }
    },
    {
        "name": "Instituto Cumbres",
        
        "data": {
            "Level 15": {
                "position": "",
                "time": ""
            },
            "Level 8": {
                "position": "",
                "time": ""
            },
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 14": {
                "position": "",
                "time": ""
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Caniguá",
        
        "data": {
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 9": {
                "position": "",
                "time": ""
            },
            "Level 14": {
                "position": "",
                "time": ""
            },
            "Level 10": {
                "position": "",
                "time": ""
            },
            "Level 3": {
                "aproved": "Reto aprobado",
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Pequeñitos de Mamá Margarita",
        "data": {
            "Level 1": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Cristo Rey Altamira",
        "data": {
            "Level 1": {
                "aproved": "Reto aprobado",
            },
            "Level 11": {
                "position": "",
                "time": ""
            },
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 8": {
                "position": "",
                "time": ""
            },
            "Level 2": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Santa Rosa de Lima",
        "data": {
            "Level 14": {
                "position": "",
                "time": ""
            },
            "Level 13": {
                "position": "",
                "time": ""
            },
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            },
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 6": {
                "position": "",
                "time": ""
            },
            "Level 2": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Agustiniano Cristo Rey",
        "data": {
            "Level 4": {
                "position": "",
                "time": ""
            },
            "Level 6": {
                "position": "",
                "time": ""
            },
            "Level 2": {
                "aproved": "Reto aprobado",
            },
            "Level 0": {
                "position": "",
                "time": ""
            }
        }
    },
    {
        "name": "Colegio Santa Rosa - Valencia",
       
        "data": {
            "Level 11": {
                "position": "",
                "time": ""
            },
            "Level 15": {
                "position": "",
                "time": ""
            }
        }
    },
    {
        "name": "Colegio Santo Tomás de Villanueva",
        
        "data": {
            "Level 11": {
                "position": "",
                "time": ""
            },
            "Level 4": {
                "position": "",
                "time": ""
            },
            "Level 6": {
                "position": "",
                "time": ""
            },
            "Level 12": {
                "position": "",
                "time": ""
            },
            "Level 2": {
                "aproved": "Reto aprobado",
            },
            
        }
    },
    {
        "name": "Colegio Andrés Bello",
        "data": {
            "Level 14": {
                "position": "",
                "time": ""
            },
            "Level 13": {
                "position": "",
                "time": ""
            },
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 6": {
                "position": "",
                "time": ""
            },
            "Level 4": {
                "position": "",
                "time": ""
            }
        }
    },
    {
        "name": "Alimenta La Solidaridad",
        "data": {
            "Level 2": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Francia",
        "data": {
            "Level 6": {
                "position": "",
                "time": ""
            },
            "Level 4": {
                "position": "",
                "time": ""
            },
           
        }
    },
    {
        "name": "Colegio 12 de Febrero",
       
        "data": {
            "Level 2": {
                "aproved": "Reto aprobado",
            },
            
        }
    },
    {
        "name": "Colegio María Auxiliadora Altamira",
        
        "data": {
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 8": {
                "position": "",
                "time": ""
            },
            "Level 15": {
                "position": "",
                "time": ""
            },
            "Level 14": {
                "position": "",
                "time": ""
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Kalil Gibran",
       
        "data": {
            "Level 12": {
                "position": "",
                "time": ""
            },
            "Level 11": {
                "position": "",
                "time": ""
            },
            "Level 2": {
                "aproved": "Reto aprobado",
            },
            "Level 4": {
                "position": "",
                "time": ""
            },
            "Level 6": {
                "position": "",
                "time": ""
            },
            
        }
    },
    {
        "name": "Colegio Casagrande",
        "data": {
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            },
            "Level 3": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio La Concepción Club Hípico",
        
        "data": {
            "Level 6": {
                "position": "",
                "time": ""
            },
            "Level 4": {
                "position": "",
                "time": ""
            },
            "Level 11": {
                "position": "",
                "time": ""
            },
            "Level 2": {
                "aproved": "Reto aprobado",
            },
           
        }
    },
    {
        "name": "Colegio La Salle",
        
        "data": {
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 2": {
                "aproved": "Reto aprobado",
            },
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 12": {
                "position": "",
                "time": ""
            },
            "Level 8": {
                "position": "",
                "time": ""
            },
            "Level 11": {
                "position": "",
                "time": ""
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Simón Bolívar I",
        "data": {
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 4": {
                "position": "",
                "time": ""
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Teresiano La Castellana",
        
        "data": {
            "Level 6": {
                "position": "",
                "time": ""
            },
            "Level 4": {
                "position": "",
                "time": ""
            },
            "Level 11": {
                "position": "",
                "time": ""
            },
            "Level 2": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Donaldo García",
        
        "data": {
            "Level 8": {
                "position": "",
                "time": ""
            },
            "Level 15": {
                "position": "",
                "time": ""
            },
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 13": {
                "position": "",
                "time": ""
            },
            "Level 14": {
                "position": "",
                "time": ""
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            },
            "Level 3": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Monte Alto",
        
        "data": {
            "Level 3": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Roraima",
        
        "data": {
            "Level 9": {
                "position": "",
                "time": ""
            },
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 10": {
                "position": "",
                "time": ""
            },
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            },
            "Level 3": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Fray Luis Amigó",
        "data": {
            "Level 12": {
                "position": "",
                "time": ""
            },
            "Level 11": {
                "position": "",
                "time": ""
            },
            "Level 6": {
                "position": "",
                "time": ""
            },
            "Level 4": {
                "position": "",
                "time": ""
            },
           
        }
    },
    {
        "name": "Colegio Madison",
        
        "data": {
            "Level 12": {
                "position": "",
                "time": ""
            },
            "Level 11": {
                "position": "",
                "time": ""
            },
            "Level 6": {
                "position": "",
                "time": ""
            },
            "Level 4": {
                "position": "",
                "time": ""
            },
           
            "Level 2": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Centro de Estudios Los Caminos",
        
        "data": {
            "Level 13": {
                "position": "",
                "time": ""
            },
            "Level 5": {
                "position": "",
                "time": ""
            },
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 15": {
                "position": "",
                "time": ""
            },
            "Level 8": {
                "position": "",
                "time": ""
            },
            "Level 14": {
                "position": "",
                "time": ""
            },
            "Level 2": {
                "aproved": "Reto aprobado",
            },
            "Level 1": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio La Concepción Montalbán",
       
        "data": {
            "Level 4": {
                "position": "",
                "time": ""
            },
            "Level 11": {
                "position": "",
                "time": ""
            },
            
            "Level 2": {
                "aproved": "Reto aprobado",
            },
            "Level 12": {
                "position": "",
                "time": ""
            },
            "Level 6": {
                "position": "",
                "time": ""
            }
        }
    },
    {
        "name": "Colegio La Concordia",
        "data": {
            "Level 6": {
                "position": "",
                "time": ""
            },
            "Level 4": {
                "position": "",
                "time": ""
            },
            "Level 11": {
                "position": "",
                "time": ""
            },
            "Level 12": {
                "position": "",
                "time": ""
            },
            "Level 2": {
                "aproved": "Reto aprobado",
            },
            
        }
    },
    {
        "name": "Colegio JJ Thomson",
        "data": {
            "Level 4": {
                "position": "",
                "time": ""
            },
            "Level 6": {
                "position": "",
                "time": ""
            },
            
        }
    },
    {
        "name": "Colegio La Concepción Maracay",
      
        "data": {
            "Level 2": {
                "aproved": "Reto aprobado",
            },
            
        }
    },
    {
        "name": "Unidad Educativa Ecológica Santísima Trinidad",
       
        "data": {
            "Level 6": {
                "position": "",
                "time": ""
            },
            
        }
    },
    {
        "name": "Colegio Yale",
        
        "data": {
            "Level 4": {
                "position": "",
                "time": ""
            },
            "Level 6": {
                "position": "",
                "time": ""
            },
            "Level 11": {
                "position": "",
                "time": ""
            },
            "Level 8": {
                "position": "",
                "time": ""
            },
            "Level 2": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Altamira Maracaibo",
        
        "data": {
            "Level 11": {
                "position": "",
                "time": ""
            },
            "Level 7": {
                "position": "",
                "time": ""
            },
            "Level 8": {
                "position": "",
                "time": ""
            }
        }
    },
    {
        "name": "UE Generalisimo Francisco de Miranda",
        "data": {
            "Level 2": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "UEP José de San Martín",
       
        "data": {
            "Level 4": {
                "position": "",
                "time": ""
            },
            "Level 2": {
                "aproved": "Reto aprobado",
            },
            
        }
    },
    {
        "name": "Colegio Nuestra Señora de La Consolación Barcelona",
        
        "data": {
            "Level 11": {
                "position": "",
                "time": ""
            },
            "Level 6": {
                "position": "",
                "time": ""
            },
            
            "Level 2": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Cruz Carrillo",
        
        "data": {
           
        }
    },
    {
        "name": "Centro Infantil América",
        
        "data": {
            "Level 2": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Colegio Bellas Artes",
        
        "data": {
            "Level 2": {
                "aproved": "Reto aprobado",
            },
            
        }
    },
    {
        "name": "Preescolar Los Pinitos",
        
        "data": {
            "Level 3": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Preescolar Puki Puki",
        
        "data": {
            "Level 3": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Preescolar My Spot",
        
        "data": {
            "Level 3": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Step by Step",
        
        "data": {
            "Level 3": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Preescolar Las Tunitas",
        
        "data": {
            "Level 3": {
                "aproved": "Reto aprobado",
            }
        }
    },
    {
        "name": "Preescolar Simón Bolívar",
        
        "data": {
            "Level 3": {
                "aproved": "Reto aprobado",
            }
        }
    }
]