import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Grid,
  IconButton,
  Button,
  Tooltip,
  Drawer,
  Stack,
  List, ListItem, ListItemText
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import {
  useParams,
  useOutletContext,
} from "react-router-dom";
import {
  TERM_OPTIONS,
  secondaryPlanHeaders,
  TERM_OPTIONS_CHILE,
} from "../../utils/constants/coursesConstants";
import { COURSES_ENDPOINT } from "../../utils/constants";
import { get } from "../../utils/http";
import { useCourses } from "../../context/CoursesContext";
import LessonContent from "./Lessons/LessonContent";
import LessonHeader from "./Lessons/LessonHeader";
import LoadingComponent from "../LoadingComponent";
import { useLoading } from "../../context/LoadingContext";
import { CurrentUserContext } from "../../context/CurrentUserProvider";

const especialBooks = [
  {
    programs: ['B-Block', 'BB-Block I', 'BB-Block II', 'BB-Block III', 'Block +'],
    link: 'https://online.fliphtml5.com/zjfpz/dtdd/'
  },
  {
    programs: ['Makerlab I'],
    link: 'https://online.fliphtml5.com/zjfpz/muxc/'
  }
];

const Sidebar = ({ lessons, activeLesson, handleChangeActiveLesson, isDrawerOpen, setIsDrawerOpen, isDesktop }) => {
  return (
    <>

      {/* Drawer */}
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        <Box
          sx={{
            width: 250,
            backgroundColor: 'primary.main',
            height: '100%',
            color: 'white',
            padding: 2,
          }}
        >
          <List>
            {lessons?.map((lesson, idx) => (
              <ListItem
                button
                key={lesson.id}
                
                onClick={() => {
                  handleChangeActiveLesson(lesson.id, idx);
                  setIsDrawerOpen(false);
                }}
                sx={{
                  backgroundColor: activeLesson?.id === lesson?.id ? 'common.white' : 'transparent',
                  color: activeLesson?.id === lesson.id ? 'primary.main' : 'common.white',
                  borderRadius: '10px',
                  '&:hover': {
                    backgroundColor: 'common.white',
                    color: 'primary.main',
                  },
                }}
              >
                <ListItemText
                primary={`Clase ${idx + 1}`} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      {/* Sidebar en pantallas grandes */}
      <Box
        sx={{
          display: isDesktop ? 'flex' : 'none',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          padding: 2,
          backgroundColor: 'primary.main',
          color: 'white',
          borderRadius: 2,
          width: '15%',
          textAlign: 'center',
          height: '88vh',
          overflowY: 'scroll',
        }}
      >
        {lessons?.map((lesson, idx) => (
          <Box
            key={lesson.id}
            sx={{
              fontSize: '1.2rem',
              padding: 1,
              marginY: 0.5,
              borderRadius: '10px',
              backgroundColor: activeLesson?.id === lesson.id ? 'common.white' : 'transparent',
              color: activeLesson?.id === lesson.id ? 'primary.main' : 'common.white',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'common.white',
                color: 'primary.main',
              },
            }}
            onClick={() => handleChangeActiveLesson(lesson.id, idx)}
          >
            {`Clase ${idx + 1}`}
          </Box>
        ))}
      </Box>
    </>
  );
}

function CourseView() {
  const { fetchSingleCourse } = useCourses();
  const [lessons, setLessons] = useState({});
  const [termLessons, setTermLessons] = useState([]);
  const [activeTerm, setActiveTerm] = useState("first_term");
  const [activeLesson, setActiveLesson] = useState(null);
  const [activeLessonIndex, setActiveLessonIndex] = useState(1)
  const [course, setCourse] = useState({});
  const [activeSection, setActiveSection] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const { currentUser } = useContext(CurrentUserContext);
  const {
    sections: [sections],
    getCourse: [getCourse],
  } = useOutletContext();
  const { courseId } = useParams();

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(950))

  const handleChangeActiveTerm = (term) => {
    setActiveTerm(term);
  };

  const handleChangeActiveLesson = (lessonId, index) => {
    setActiveLessonIndex(index + 1)
    const selectedLesson = termLessons?.find((lesson) => lesson.id === lessonId);
    setActiveLesson(selectedLesson);
  };

  useEffect(() => {
    const fetchCourseLessons = async () => {
      showLoading();
      const url = `${COURSES_ENDPOINT}${courseId}/lessons/`;
      const response = await get({ url });
      if (!response.error) {
        setLessons(response.data);
      }
      hideLoading();
    };
    fetchCourseLessons();
  }, [courseId]);

  useEffect(() => {
    setTermLessons(lessons[activeTerm]);
  }, [lessons, activeTerm]);

  useEffect(() => {
    if (termLessons && termLessons.length > 0) {
      setActiveLesson(termLessons[0]);
    }
  }, [termLessons]);

  const getCourseInfo = async () => {
    const courseInfo = await fetchSingleCourse(courseId);
    setCourse(courseInfo);
  };

  useEffect(() => {
    getCourseInfo();
  }, [courseId]);

  const filterBooks = especialBooks?.filter((item) =>
    item?.programs?.includes(course?.program)
  );

  return (
    <Box sx={{width:'100%', display:'flex', flexDirection: 'column'}}>
      {termLessons?.length > 0 ? 
      <>
      <Stack direction={"row"} spacing={2} sx={{marginBottom: '10px'}}>
      {!isDesktop && 
          <>
          {course?.tips_ia && course?.tips_ia.length > 0 && (
            <Tooltip
              title={
                <Box component="ul" sx={{ paddingLeft: 2, margin: 0 }}>
                  {course?.tips_ia?.map((item, index) => (
                    <li key={index}>{item.content}</li>
                  ))}
                </Box>
              }
              placement="top"
              arrow
              sx={{ fontSize: '0.9rem' }}
            >
              <Box sx={{backgroundColor: 'var(--main-blue)', 
                borderRadius: '10px', display:'flex', alignItems: 'center'}}>
                <img src="/ai_icon.png" alt="AI Tips" style={{ cursor: 'pointer', width: '35px',
                height: '35px', margin: '2px'}} />
              </Box>
            </Tooltip>
          )}
          </>}
      {filterBooks?.length > 0 && !isDesktop && (
            <Button
              variant="outlined"
              href={filterBooks[0]?.link}
              target="_blank"
              rel="noreferrer"
              fullWidth
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                marginBottom: '10px',
                fontSize: '1rem',
              }}
            >
              Guías exploratorias
            </Button>
      )}
      </Stack>
    <Box sx={{ width: '100%', display: 'flex', gap: 1 }}>
      <Sidebar isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      lessons={termLessons}  activeLesson={activeLesson}
      handleChangeActiveLesson={handleChangeActiveLesson} isDesktop={isDesktop}/>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: 1,
          borderRadius: 2,
          width: '85%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'primary.main',
            borderRadius: 2,
            paddingY: 1,
            color: 'common.white',
            paddingX: 2, 
          }}
        >
          {/* AI Tips */}
          {isDesktop && 
          <>
          {course?.tips_ia && course?.tips_ia.length > 0 ? (
            <Tooltip
              title={
                <Box component="ul" sx={{ paddingLeft: 2, margin: 0 }}>
                  {course.tips_ia.map((item, index) => (
                    <li key={index}>{item.content}</li>
                  ))}
                </Box>
              }
              placement="top"
              arrow
              sx={{ fontSize: '0.9rem' }}
            >
               <img src="/ai_icon.png" alt="AI Tips" style={{ cursor: 'pointer', width: '35px',
                height: '35px'}} />
            </Tooltip>
          ) : (
            // Si no hay AI Tips, agregar un espacio para mantener el diseño
            <Box sx={{ width: 48 }} /> // Ancho aproximado de IconButton
          )}
          </>}

          {/* Term Selector */}
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              flexWrap: 'wrap',
              flexGrow: 1,
              justifyContent: 'center',
            }}
          >
            {Object.keys(lessons)
              .filter((term) => lessons[term].length > 0)
              .map((term) => (
                <Button
                  key={term}
                  variant={activeTerm === term ? 'contained' : 'outlined'}
                  color="primary"
                  onClick={() => handleChangeActiveTerm(term)}
                  sx={{
                    borderRadius: 2,
                    textTransform: 'none',
                    fontSize: '1rem',
                    color: activeTerm === term ? 'primary.main' : 'common.white',
                    backgroundColor: activeTerm === term ? 'common.white' : 'transparent',
                    borderColor: '#fff',
                    '&:hover': {
                      backgroundColor: '#fff',
                      borderColor: 'common.white',
                      color: 'var(--main-blue)'
                    },
                  }}
                >
                  {course?.institution?.name !== 'Colegio Compañía de María' &&
                  course?.institution?.name !== 'Colegio Maimonides' &&
                  course?.institution?.name !== 'Colegio Everest'
                    ? TERM_OPTIONS[term]
                    : TERM_OPTIONS_CHILE[term]}
                </Button>
              ))}
          </Box>
            
          {/* Guías exploratorias */}
          {filterBooks?.length > 0 && isDesktop && (
            <Button
              variant="outlined"
              href={filterBooks[0]?.link}
              target="_blank"
              rel="noreferrer"
              sx={{
                color: 'common.white',
                borderColor: 'common.white',
                borderRadius: 2,
                textTransform: 'none',
                fontSize: '1rem',
                '&:hover': {
                  backgroundColor: 'common.white',
                  color: 'primary.main',
                  borderColor: 'common.white',
                },
              }}
            >
              Guías exploratorias
            </Button>
          )}
        </Box>
        {!isDesktop && 
        <Box>
          <Button variant="contained" sx={{borderRadius: '10px'}}
          onClick={() => setIsDrawerOpen(true)}
          startIcon={<MenuIcon />}>
            {`Clase ${activeLessonIndex}`}
          </Button>
        </Box>}

        <Box
          sx={{
            backgroundColor: 'var(--background-gray)',
            borderRadius: 2,
            padding: 2,
            height: '78vh',
            overflowY: 'auto',
          }}
        >
          <LessonHeader
            activeLesson={activeLesson}
            course={course}
            sections={sections}
            activeSection={activeSection}
            getCourse={getCourse}
            setActiveSection={setActiveSection}
            currentUser={currentUser}
          />
          <LessonContent
            activeLesson={activeLesson}
            secondaryPlanHeaders={secondaryPlanHeaders}
            course={course}
          />
        </Box>
      </Box>
      {isLoading && <LoadingComponent />}
    </Box>
    </> :
    <Box sx={{backgroundColor: 'var(--background-gray)', borderRadius: '10px', padding:'15px'}}>
      <h3 style={{textAlign: 'center'}}>
        En estos momentos la planificación no se encuentra disponible, por favor intenta más tarde o comunícate con tu docente de seguimiento
      </h3>
    </Box> 
    }
    </Box>
  );
}

export default CourseView;