import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { INSTITUTIONS_OPTIONS } from "../utils/constants/institutionsConstants";
import styles from "./InstitutionsPage.module.css";
import SettingsBar from "../components/Navbars/SettingsBar";

const InstitutionsPage = () => {
  const [institutionsOption, setInstitutionOption] = useState("list");

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!location.pathname.split("/")[2]) {
      navigate("list", { replace: true });
    } else {
      setInstitutionOption(location.pathname.split("/")[2]);
    }
  }, [location.pathname]);

  return (
    <div className={styles.content}>
      {(institutionsOption === "list" ||
        institutionsOption === "create" ||
        institutionsOption === "upload") && (
        <SettingsBar
          optionsList={INSTITUTIONS_OPTIONS}
          option={institutionsOption}
          setOption={setInstitutionOption}
        />
      )}
      <section className={styles.leftside}>
        <Outlet />
      </section>
    </div>
  );
};

export default InstitutionsPage;
