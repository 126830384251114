import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { Grid, Stack, Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/system';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import QuizRoundedIcon from '@mui/icons-material/QuizRounded';
import NextSessionComp from './NextSessionComp';
import { ANNUAL_CERTIFICATE_USER_ENDPOINT } from '../../../utils/constants';
import { get } from '../../../utils/http';
import { AnnualTrainingsContext } from '../../../context/AnnualTrainingsContext';

const Title2 = styled('p')({
    fontSize: '1.4rem',
    fontWeight:'bold',
    marginBottom: '5px'
});
const Info = styled('p')({
    fontSize: '1.2rem',
    color: '#787878',
});


function RightSideComponent({
    nextSessionsFilter, 
    userAnnualTraining, 
    handleOpenAnswers, 
    currentUser,
    setViewQuiz, trainingId, userId}) {
  const {updateUserCertificate} = useContext(AnnualTrainingsContext)
  
  const [certificateStatus, setCertificateStatus] = useState(null)

  const getCertificateStatus = async () => {
    const url = `${ANNUAL_CERTIFICATE_USER_ENDPOINT}?user=${userId}&annualtraining=${trainingId}`;
    const response = await get({ url });
    if (!response.error) {
      setCertificateStatus(response.data[0]);
    }
  };

  useEffect(() => {
    getCertificateStatus();
  }, [userId,trainingId ]);

  const handleUpdateCertificate = (type) => {
    const certificateId = certificateStatus?.id
    if(type === "survey"){
      const data = {
        survey: true
      }
      if(certificateStatus?.survey === false){
        updateUserCertificate(certificateId, data)
        getCertificateStatus()
      }
    }else if(type === "download"){
      const data ={
        status: "downloaded"
      }
      if(certificateStatus?.status === "approved"){
        updateUserCertificate(certificateId, data)
        getCertificateStatus()
      }
    }
  }

  const showCertificateSection = userAnnualTraining?.sessions?.length > 0 &&
    userAnnualTraining.sessions[0]?.teacher?.length > 0 &&
    userAnnualTraining.sessions[0].teacher[0]?.quiz_status;

  return (
    <>
    {nextSessionsFilter?.length > 0 ?
          <>
          <Title2>Próximas sesiones</Title2>
          {nextSessionsFilter?.map((session, index) => {
            return(
              <NextSessionComp session={session} num={index} />
            )
          })}
          </> :
          <>
          {((userAnnualTraining?.quiz || userAnnualTraining?.quiz_url && !certificateStatus?.survey)) && 
          <Title2>Evaluación</Title2>}
            {(userAnnualTraining?.quiz || userAnnualTraining?.quiz_url) && <Grid
              container
              direction={"row"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#fff",
                padding:'10px',
                borderRadius: '10px'
              }}>
              <Grid
              item
              xs={9}
              sx={{ display: "flex", justifyContent: "flex-start" }}>
                
                <Stack direction={"column"}>
                  <Info>Quiz</Info>
                  <Link target='_blank' onClick={() => handleUpdateCertificate("survey")}
                  to={userAnnualTraining?.quiz_url} style={{textDecoration: 'none'}}>
                    <Button endIcon={<ArrowForwardIcon />} size='small'
                      variant='outlined' onClick={() => setViewQuiz(true)}
                      sx={{borderRadius: '10px', fontSize: '11px', marginTop: '5px'}}>
                        Responder quiz
                    </Button>
                  </Link>
                  {/*{!userAnnualTraining?.user_quiz?.completed ? 
                  <Button endIcon={<ArrowForwardIcon />} size='small'
                  variant='outlined' onClick={() => setViewQuiz(true)}
                  sx={{borderRadius: '10px', fontSize: '11px', marginTop: '5px'}}>
                    Responder quiz
                  </Button> : 
                  <>
                  <Chip
                  label={"Respondido"}
                  sx={{
                    backgroundColor:"#D7FFE0",
                    textTransform: "capitalize",
                    width: "fit-content",
                    margin: 0,
                    height: 25,
                    borderRadius: "10px",
                    fontWeight: "bold",
                    color: '#55BB5A',
                    fontSize: '12px',
                    marginTop: '5px'
                  }}
                  deleteIcon={<ArrowForwardIcon />}
                  onDelete={(e) => handleOpenAnswers(e, currentUser?.id)}
                />
                  </>
                  }*/}
                </Stack>
              </Grid>
              <Grid item xs={3} sx={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                {!userAnnualTraining?.user_quiz?.completed ? 
                <QuizRoundedIcon
                sx={{backgroundColor: 'var(--ter-blue)', 
                color: '#fff',  borderRadius: '7px', padding: '5px', fontSize:'30px'}}/> : 
                <p style={{backgroundColor: 'var(--ter-blue)', 
                  color: '#fff',  borderRadius: '7px', padding: '5px', fontSize:'14px'}}>{`${userAnnualTraining?.user_quiz?.grade}/20`}</p>}
              </Grid>
            </Grid>}
            <div style={{marginTop: '10px'}}>
            {/*<Title2>Encuesta</Title2>
            <Grid
              container
              direction={"row"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#fff",
                padding:'10px',
                borderRadius: '10px'
              }}>
              <Grid
              item
              xs={9}
              sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Stack direction={"column"}>
                  <Info>Encuesta</Info>
                  <Link target='_blank' onClick={() => handleUpdateCertificate("survey")}
                  to={userAnnualTraining?.link_survey} style={{textDecoration: 'none'}}>
                  <Button variant='outlined' size='small'
                  endIcon={<ArrowForwardIcon />}
                  sx={{borderRadius: '10px', fontSize: '11px', marginTop: '5px'}}>
                    Ir a la encuesta
                  </Button> 
                  </Link> 
                </Stack>
              </Grid>
              <Grid item xs={3} sx={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                  <i class="fas fa-poll" style={{backgroundColor: 'var(--ter-blue)', 
                color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
              </Grid>
            </Grid>*/}
            </div>
           {showCertificateSection &&
           <div style={{marginTop: '10px'}}>
           <Title2>Certificado</Title2>
            <Grid
              container
              direction={"row"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#fff",
                padding:'10px',
                borderRadius: '10px'
              }}>
              <Grid
              item
              xs={9}
              sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Stack direction={"column"} spacing={1}>
                  <Info>Certificado</Info>
                  {(certificateStatus && certificateStatus?.survey && userAnnualTraining?.sessions[0]?.teacher[0]?.nda_agreement) &&
                  (certificateStatus[0]?.status === "approved" || 
                  certificateStatus[0]?.status === "downloaded") ?
                  <Button variant='outlined' size='small'
                  endIcon={<ArrowForwardIcon />}
                  sx={{borderRadius: '10px', fontSize: '11px', marginTop: '5px'}}>
                    Ver Certificado
                  </Button> : 
                  <>
                {!userAnnualTraining?.sessions[0]?.teacher[0]?.nda_agreement &&
                  <p style={{color: 'red'}}>Para poder acceder al certificado debes de firmar el acuerdo de confidencialidad</p>
                  }
                  {certificateStatus?.survey ?
                  <Chip
                  label={"Esperando Aprobación"}
                  sx={{
                    backgroundColor:"#FFFBE4",
                    textTransform: "capitalize",
                    width: "fit-content",
                    margin: 0,
                    height: 25,
                    borderRadius: "10px",
                    fontWeight: "bold",
                    color: '#F09624',
                    fontSize: '12px',
                    marginTop: '5px'
                  }} /> : 
                  <p style={{color: 'red'}}>Para poder acceder al certificado por favor contesta la encuesta</p>}
                  </>
                  }
                </Stack>
              </Grid>
              <Grid item xs={3} sx={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                  <i class="fas fa-certificate" style={{backgroundColor: 'var(--ter-blue)', 
                color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
              </Grid>
            </Grid> </div>}
          </>
          }
      
    </>
  )
}

export default RightSideComponent
