export const indicators = [
    {
        program: "BB-Block I",
        frase: "Donde cada armado es una nueva aventura",
        indicators: [
            {
                title: "Solución de Problemas:",
                description: "Los estudiantes resuelven de manera autónoma y creativa los desafíos que surgen durante la construcción de armados, desarrollando habilidades de resolución de problemas y pensamiento crítico."
            },
            {
                title: "Creación Tecnológica:",
                description: "Los estudiantes elaboran armados que representan objetos tecnológicos de la vida real, fomentando la imaginación y la comprensión de conceptos tecnológicos desde una edad temprana."
            },
            {
                title: "Identificación Visual: ",
                description: "Los estudiantes identifican cuerpos, objetos y animales representados en sus armados, promoviendo la observación detallada y la comprensión del entorno."
            },
            {
                title: "Independencia en el Proceso de Aprendizaje:",
                description: "Los estudiantes buscan recursos como alternativa antes de solicitar asistencia en el proceso de las actividades de la clase."
            },
    ]
    },
    {
        program: "BB-Block I - Comprimido",
        frase: "Despertando la Innovación y el Trabajo en Equipo con",
        indicators: [
            {
                title: "Fomento del Respeto y Disciplina",
                description: "Los estudiantes aprenden a seguir reglas mientras se divierten y exploran en el mundo de la robótica."
            },
            {
                title: "Herramientas para el Aprendizaje Autónomo",
                description: "Utilizan piezas KNex para descubrir y entender conceptos complejos por sí mismos."
            },
            {
                title: "Curiosidad Innovadora ",
                description: "Alentamos a los estudiantes a hacer preguntas desafiantes, desarrollando su vocabulario y conocimientos específicos de robótica."
            },
            {
                title: "Solución de Problemas Creativos",
                description: "Los alumnos demuestran habilidades para resolver desafíos durante la construcción de modelos robóticos."
            },
            {
                title: "Trabajo en Equipo y Colaboración",
                description: "Los estudiantes aprenden a seguir reglas mientras se divierten y exploran en el mundo de la robótica."
            },
    ]
    },
    {
        program: "BB-Block II",
        frase: "La creatividad y la colaboración se fusionan con la tecnología",
        indicators: [{
            title: "Participación Activa:",
            description: "Los estudiantes participan activamente en las actividades grupales de robótica, promoviendo la colaboración y el trabajo en equipo."
        },
        {
            title: "Comunicación Efectiva:",
            description: "Los estudiantes se comunican utilizando los términos técnicos empleados durante la clase, mejorando su vocabulario y habilidades de comunicación."
        },
        {
            title: "Resolución de Problemas Avanzada:",
            description: "Los estudiantes resuelven problemas complejos mediante la comparación, relación y anticipación, demostrando un alto nivel de pensamiento crítico y adaptabilidad."
        },
        {
            title: "Creatividad Artística: ",
            description: "Los estudiantes crean juegos y dramatizaciones con sus armados, distinguiendo roles, fomentando la creatividad y la expresión artística."
        },
        ]
    },
    {
        program: "BB-Block II - Comprimido",
        frase: "La creatividad y la colaboración se fusionan con la tecnología",
        indicators: [{
            title: "Participación Activa:",
            description: "Los estudiantes participan activamente en las actividades grupales de robótica, promoviendo la colaboración y el trabajo en equipo."
        },
        {
            title: "Comunicación Efectiva:",
            description: "Los estudiantes se comunican utilizando los términos técnicos empleados durante la clase, mejorando su vocabulario y habilidades de comunicación."
        },
        {
            title: "Resolución de Problemas Avanzada:",
            description: "Los estudiantes resuelven problemas complejos mediante la comparación, relación y anticipación, demostrando un alto nivel de pensamiento crítico y adaptabilidad."
        },
        {
            title: "Creatividad Artística: ",
            description: "Los estudiantes crean juegos y dramatizaciones con sus armados, distinguiendo roles, fomentando la creatividad y la expresión artística."
        },
        ]
    },
    {
        program: "BB-Block III",
        frase: "Fortaleciendo habilidades como la expresión verbal y la colaboración innovadora",
        indicators: [{
            title: "Colaboración Innovadora:",
            description: "Los estudiantes elaboran nuevos armados en grupos, promoviendo la colaboración y la creatividad en el proceso de diseño."
        },
        {
            title: "Uso Avanzado de Tecnología:",
            description: "Los estudiantes utilizan la tecnología como herramienta integral para actividades de robótica, desarrollando habilidades digitales."
        },
        {
            title: "Habilidad Cuantitativa:",
            description: "Los estudiantes cuantifican con precisión las piezas necesarias para los armados, fortaleciendo sus habilidades matemáticas y de medición."
        },
        {
            title: "Expresión verbal:",
            description: "Los estudiantes expresan oralmente situaciones, ideas o vivencias durante la clase de robótica, mejorando su capacidad de comunicación y expresión personal."
        }
        ]
    },
    {
        program: "BB-Block III - Comprimido",
        frase: "Desarrollando Habilidades para el Futuro con",
        indicators: [{
            title: "Aprendizaje Colaborativo y Apoyo",
            description: "Los estudiantes aprenden a solicitar ayuda de manera efectiva, fomentando un ambiente de apoyo y colaboración."
        },
        {
            title: "Pensamiento Lógico y Creatividad",
            description: "Combinan piezas de manera lógica y creativa, construyendo modelos basados en sus intereses personales."
        },
        {
            title: "Comunicación efectiva",
            description: "Expresan ideas, hechos y experiencias personales mientras construyen, mejorando sus habilidades comunicativas."
        },
        {
            title: "Trabajo en Equipo Avanzado",
            description: "Trabajan en grupos para construir modelos complejos, aprendiendo a planificar y ejecutar proyectos en equipo."
        }
        ]
    },
    {
        program: "Block +",
        frase: "Fomentando el pensamiento crítico, la conexión interdisciplinaria y la participación activa",
        indicators: [{
            title: "Pensamiento Crítico:",
            description: "Los estudiantes generan preguntas y respuestas relacionadas con la clase de robótica, demostrando habilidades de pensamiento crítico y curiosidad intelectual."
        },
        {
            title: "Conexión Interdisciplinaria:",
            description: "Los estudiantes reconocen y comprenden las funciones de las profesiones relacionadas con los medios de transporte trabajados en clase."
        },
        {
            title: "Habilidad Comparativa:",
            description: "Los estudiantes establecen relaciones entre los armados según sus características, desarrollando habilidades de comparación y análisis."
        },
        {
            title: "Participación Activa: ",
            description: "Los estudiantes participan en actividades grupales kinestésicas y óculo-manuales, promoviendo la colaboración y el aprendizaje interactivo."
        }
        ]
    },
    {
        program: "Block + - Comprimido",
        frase: "Fomentando el pensamiento crítico, la conexión interdisciplinaria y la participación activa",
        indicators: [{
            title: "Pensamiento Crítico:",
            description: "Los estudiantes generan preguntas y respuestas relacionadas con la clase de robótica, demostrando habilidades de pensamiento crítico y curiosidad intelectual."
        },
        {
            title: "Conexión Interdisciplinaria:",
            description: "Los estudiantes reconocen y comprenden las funciones de las profesiones relacionadas con los medios de transporte trabajados en clase."
        },
        {
            title: "Habilidad Comparativa:",
            description: "Los estudiantes establecen relaciones entre los armados según sus características, desarrollando habilidades de comparación y análisis."
        },
        {
            title: "Participación Activa: ",
            description: "Los estudiantes participan en actividades grupales kinestésicas y óculo-manuales, promoviendo la colaboración y el aprendizaje interactivo."
        }
        ]
    },
    {
        program: "Block - Comprimido",
        frase: "Exploración Lúdica con",
        indicators: [{
            title: "Aprendizaje Integral con K´nex",
            description: "Desarrollan habilidades como motricidad fina, ubicación espacial y mecánica mediante actividades con piezas K´nex."
        },
        {
            title: "Conexión con el Entorno",
            description: "Relacionan elementos del entorno con las temáticas de la clase, enriqueciendo la experiencia educativa."
        },
        {
            title: "Resolución Creativa de Problemas",
            description: "Abordan problemas simples durante las clases de robótica, estimulando el pensamiento crítico."
        },
        {
            title: "Creatividad y Trabajo en Equipo",
            description: "Participan en actividades lúdicas que fomentan la creatividad y el trabajo en equipo, creando un ambiente de aprendizaje divertido."
        }
        ]
    },
    {
        program: "Digital Skills I",
        frase: "impulsando las carreras profesionales con presentaciones gráficas que impacten",
        indicators: [{
            title: `Fundamentos de PowerPoint:`,
            description: `Los estudiantes aprenden los conceptos básicos de PowerPoint, comprendiendo su utilidad y funciones, y ubicando las herramientas esenciales dentro del programa, como la inserción y edición de texto`
        },
        {
            title: "Diseño y multimedia en presentaciones:",
            description: "Aprenden a insertar y modificar videos, imágenes y audios, así como utilizar formas, cuadros de texto y WordArt para mejorar el diseño. También aprenden a incluir tablas desde Excel y crear presentaciones dinámicas."
        },
        {
            title: "Creación de documentos dinámicos:",
            description: "Los estudiantes aprenden a crear presentaciones dinámicas con gráficos representativos, como gráficos de columnas, filas, círculos y barras. Utilizan hipervínculos para una navegación eficiente y agregar transiciones para mejorar la experiencia visual durante la presentación."
        },
        {
            title: "Preparación para el mundo corporativo:",
            description: "los estudiantes se preparan para el mundo corporativo al enseñarles habilidades esenciales de PowerPoint, lo que les permite crear presentaciones profesionales que serán útiles en sus futuras carreras."
        }
        ]
    },
    {
        program: "Digital Skills II",
        frase: "diseñando la identidad digital que refleja el valor intangible de las empresas",
        indicators: [{
            title: `Fundamentos de imagen empresarial:`,
            description: `Identifican qué es una imagen empresarial, comprenden el significado de un manual de marca y exploran cómo diferentes empresas tecnológicas gestionan su imagen corporativa.`
        },
        {
            title: "Aplicación de identidad corporativa: ",
            description: "Crean una identidad corporativa sólida. Aprenden sobre la composición y utilidad de la identidad corporativa, cómo crear un BrandBoard, la diferencia entre logotipo, isotipo e imagotipo, y cómo desarrollar un eslogan efectivo."
        },
        {
            title: "Diseño de manual de marca:",
            description: "Los alumnos diseñan un manual de marca para una empresa tecnológica ficticia. Seleccionan tipografías y colores adecuados, comparan estilos fotográficos, utilizan MockUps para visualizar su diseño"
        },
        {
            title: "Desarrollo Práctico:",
            description: "El enfoque está en el desarrollo práctico de habilidades de diseño gráfico. Los estudiantes aplican lo que han aprendido en Adobe Illustrator y Photoshop para crear materiales visuales que reflejen la identidad y el mensaje de una empresa tecnológica."
        }
        ]
    },
    {
        program: "E-Board I",
        frase: "Control de Arduino para impulsar la innovación y precisión en proyectos electrónicos.",
        indicators: [{
            title: "Dominio del Control de Flujo:",
            description: "Habilidad para comparar de manera precisa y eficaz la función del ciclo While y Do While, permitiendo elegir la estructura más adecuada para cada situación y garantizando un control de flujo óptimo en el desarrollo de programas."
        },
        {
            title: "Configuración Eficiente con Ciclo While:",
            description: "Habilidad para emplear de manera adecuada el ciclo While en la configuración de circuitos electrónicos, garantizando un control preciso y eficiente del funcionamiento de los dispositivos conectados."
        },
        {
            title: "Precisión en la Manipulación del Potenciómetro: ",
            description: "Dominio en el uso adecuado del potenciómetro, aprovechando su versatilidad y precisión para controlar diversos parámetros en circuitos electrónicos con Arduino y otros sistemas."
        },
        {
            title: "Conocimiento en Emisión Sonora con Buzzer:",
            description: "Reconocimiento del funcionamiento del buzzer para la emisión de sonidos, permitiendo incorporar de manera efectiva en proyectos electrónicos para agregar una dimensión auditiva y mejorar la interacción con el usuario."
        },
        ]
    },
    {
        program: "E-board I Comprimido",
        frase: "Control de Arduino para impulsar la innovación y precisión en proyectos electrónicos.",
        indicators: [{
            title: "Dominio del Control de Flujo:",
            description: "Habilidad para comparar de manera precisa y eficaz la función del ciclo While y Do While, permitiendo elegir la estructura más adecuada para cada situación y garantizando un control de flujo óptimo en el desarrollo de programas."
        },
        {
            title: "Configuración Eficiente con Ciclo While:",
            description: "Habilidad para emplear de manera adecuada el ciclo While en la configuración de circuitos electrónicos, garantizando un control preciso y eficiente del funcionamiento de los dispositivos conectados."
        },
        {
            title: "Precisión en la Manipulación del Potenciómetro: ",
            description: "Dominio en el uso adecuado del potenciómetro, aprovechando su versatilidad y precisión para controlar diversos parámetros en circuitos electrónicos con Arduino y otros sistemas."
        },
        {
            title: "Conocimiento en Emisión Sonora con Buzzer:",
            description: "Reconocimiento del funcionamiento del buzzer para la emisión de sonidos, permitiendo incorporar de manera efectiva en proyectos electrónicos para agregar una dimensión auditiva y mejorar la interacción con el usuario."
        },
        ]
    },
    {
        program: "E-Board II",
        frase: "encendiendo la chispa del futuro digital",
        indicators: [{
            title: "Multiplexación en acción: ",
            description: "Aprenden sobre multiplexación, que es como enviar mensajes entre diferentes componentes en un circuito. Lo aplican físicamente al integrar con un display."
        },
        {
            title: "Integración de Sensores: ",
            description: "Relacionan los conceptos con dispositivos comunes, utilizando sensores para mostrar mensajes en un display, configurar movimientos precisos con servomotores y detectar la proximidad en circuitos prácticos"
        },
        {
            title: "Nuevos componentes:",
            description: "Combinan diferentes elementos, como servomotores y sensores de ultrasonido, para crear sistemas que respondan a su entorno. Utilizan botones para controlar movimientos y fotorresistores para interactuar con la luz ambiental."
        },
        {
            title: "Aplicación práctica:",
            description: "El enfoque está en la aplicación práctica de conceptos de electrónica en proyectos que los estudiantes pueden relacionar con su vida diaria, lo que les permite entender cómo funcionan los dispositivos electrónicos y cómo pueden crear sistemas similares."
        }
        ]
    },
    {
        program: "E-Board II Comprimido",
        frase: "encendiendo la chispa del futuro digital",
        indicators: [{
            title: "Multiplexación en acción: ",
            description: "Aprenden sobre multiplexación, que es como enviar mensajes entre diferentes componentes en un circuito. Lo aplican físicamente al integrar con un display."
        },
        {
            title: "Integración de Sensores: ",
            description: "Relacionan los conceptos con dispositivos comunes, utilizando sensores para mostrar mensajes en un display, configurar movimientos precisos con servomotores y detectar la proximidad en circuitos prácticos"
        },
        {
            title: "Nuevos componentes:",
            description: "Combinan diferentes elementos, como servomotores y sensores de ultrasonido, para crear sistemas que respondan a su entorno. Utilizan botones para controlar movimientos y fotorresistores para interactuar con la luz ambiental."
        },
        {
            title: "Aplicación práctica:",
            description: "El enfoque está en la aplicación práctica de conceptos de electrónica en proyectos que los estudiantes pueden relacionar con su vida diaria, lo que les permite entender cómo funcionan los dispositivos electrónicos y cómo pueden crear sistemas similares."
        }
        ]
    },
    {
        program: "Makerlab I",
        frase: "Descubriendo el diseño, personalización y creatividad con los bloques avanzados en Scratch.",
        version: "A",
        indicators: [{
            title: "Habilidad en el Diseño de Bloques Avanzados:",
            description: "Dominio en la creación de bloques avanzados de apariencia como disfraces para la personalización de personajes y fondos que realzan la estética y funcionalidad del proyecto."
        },
        {
            title: "Creatividad en la Personalización Visual:",
            description: "Habilidad para utilizar bloques de apariencia como de personalización, permitiendo la creación de personajes únicos y entornos envolventes."
        },
        {
            title: "Precisión Espacial:",
            description: "Competencia en el uso de coordenadas cartesianas para ubicar elementos con precisión en el espacio, creando composiciones visuales impactantes y visualmente atractivas."
        },
        {
            title: "Movimiento de los personajes:",
            description: "Dominio en la aplicación de bloques avanzados de movimiento para crear trayectorias dinámicas y complejas."
        },
        ]
    },
    {
        program: "Makerlab I Comprimido",
        frase: "Descubriendo el diseño, personalización y creatividad con los bloques avanzados en Scratch.",
        version: "A",
        indicators: [{
            title: "Habilidad en el Diseño de Bloques Avanzados:",
            description: "Dominio en la creación de bloques avanzados de apariencia como disfraces para la personalización de personajes y fondos que realzan la estética y funcionalidad del proyecto."
        },
        {
            title: "Creatividad en la Personalización Visual:",
            description: "Habilidad para utilizar bloques de apariencia como de personalización, permitiendo la creación de personajes únicos y entornos envolventes."
        },
        {
            title: "Precisión Espacial:",
            description: "Competencia en el uso de coordenadas cartesianas para ubicar elementos con precisión en el espacio, creando composiciones visuales impactantes y visualmente atractivas."
        },
        {
            title: "Movimiento de los personajes:",
            description: "Dominio en la aplicación de bloques avanzados de movimiento para crear trayectorias dinámicas y complejas."
        },
        ]
    },
    {
        program: "Makerlab I",
        frase: "Personalización e interacción de cada experiencia explorando el potencial de la programación.",
        version: "B",
        indicators: [{
            title: "Control Total del Programa:",
            description: "Capacidad para comprender y aprovechar al máximo los bloques de variables, permitiendo al estudiante tener un control absoluto sobre los valores y el comportamiento del programa."
        },
        {
            title: "Personalización en Acción:",
            description: "Integración de bloques de variables en tus programas, ofreciendo una personalización sin límites y adaptando cada experiencia a las necesidades específicas del usuario."
        },
        {
            title: "Interactividad Potenciada:",
            description: "Habilidad para utilizar bloques de eventos de manera efectiva, vinculando botones funcionales para activar animaciones cautivadoras, generando una experiencia interactiva y envolvente."
        }
        ]
    },
    {
        program: "Makerlab I - Comprimido",
        frase: "Personalización e interacción de cada experiencia explorando el potencial de la programación.",
        version: "B",
        indicators: [{
            title: "Control Total del Programa:",
            description: "Capacidad para comprender y aprovechar al máximo los bloques de variables, permitiendo al estudiante tener un control absoluto sobre los valores y el comportamiento del programa."
        },
        {
            title: "Personalización en Acción:",
            description: "Integración de bloques de variables en tus programas, ofreciendo una personalización sin límites y adaptando cada experiencia a las necesidades específicas del usuario."
        },
        {
            title: "Interactividad Potenciada:",
            description: "Habilidad para utilizar bloques de eventos de manera efectiva, vinculando botones funcionales para activar animaciones cautivadoras, generando una experiencia interactiva y envolvente."
        }
        ]
    },
    {
        program: "Makerlab II",
        frase: "Explorando el potencial de la inteligencia artificial y los armados prácticos para transformar las ideas en realidad.",
        version: "A",
        indicators: [{
            title: "Precisión Espacial Optimizada: ",
            description: "Reconocimiento de la importancia del entrenamiento de Photon con Inteligencia Artificial para mejorar la precisión en la ubicación espacial, permitiendo una interacción más intuitiva con el robot."
        },
        {
            title: "Creatividad Funcional:",
            description: "Habilidad para representar situaciones de la vida diaria mediante el ensamblaje de armados con las versátiles piezas KNex integrando el uso de motores, transformando ideas en soluciones tangibles y creativas."
        },
        {
            title: "Innovación Práctica:",
            description: "Capacidad para expresar ideas de máquinas más útiles y eficientes a través de armados, fusionando creatividad y funcionalidad para diseñar soluciones que mejoran la calidad de vida y optimizan los procesos cotidianos."
        }
        ]
    },
    {
        program: "Makerlab II Comprimido",
        frase: "Explorando el potencial de la inteligencia artificial y los armados prácticos para transformar las ideas en realidad.",
        version: "A",
        indicators: [{
            title: "Precisión Espacial Optimizada: ",
            description: "Reconocimiento de la importancia del entrenamiento de Photon con Inteligencia Artificial para mejorar la precisión en la ubicación espacial, permitiendo una interacción más intuitiva con el robot."
        },
        {
            title: "Creatividad Funcional:",
            description: "Habilidad para representar situaciones de la vida diaria mediante el ensamblaje de armados con las versátiles piezas KNex integrando el uso de motores, transformando ideas en soluciones tangibles y creativas."
        },
        {
            title: "Innovación Práctica:",
            description: "Capacidad para expresar ideas de máquinas más útiles y eficientes a través de armados, fusionando creatividad y funcionalidad para diseñar soluciones que mejoran la calidad de vida y optimizan los procesos cotidianos."
        }
        ]
    },
    {
        program: "Makerlab II",
        frase: "Explorando la innovación a través de armados en movimiento, simulación y la programación.",
        version: "B",
        indicators: [{
            title: "Visión Tecnológica:",
            description: "Capacidad para identificar elementos del entorno y relacionarlos con el emocionante campo de Inteligencia Artificial a través de Photon, abriendo las puertas a la innovación y la resolución de problemas en diversos ámbitos de la vida cotidiana.            "
        },
        {
            title: "Innovación en Movimiento:",
            description: "Habilidad para crear armados tridimensionales con movimiento durante las clases de robótica, fomentando la creatividad y fortaleciendo el trabajo en equipo, mientras se exploran conceptos avanzados de ingeniería y diseño."
        },
        {
            title: "Dominio de la Simulación:",
            description: "Competencia en el uso efectivo del simulador de Tinkercad, demostrando habilidades para conectar LEDs correctamente y explorar diversas configuraciones. Los estudiantes de 6to grado han realizado eficazmente circuitos usando la placa Arduino a través de la programación en bloques."
        },
        {
            title: "Excelencia en Circuitos:",
            description: "Aplicación del proceso para desarrollar circuitos eléctricos, desde la creación del plano eléctrico y el diagrama de flujo hasta su implementación en el simulador, garantizando un enfoque metódico y preciso en la construcción y análisis de circuitos."
        }
        ]
    },
    {
        program: "Makerlab II Comprimido",
        frase: "Explorando la innovación a través de armados en movimiento, simulación y la programación.",
        version: "B",
        indicators: [{
            title: "Visión Tecnológica:",
            description: "Capacidad para identificar elementos del entorno y relacionarlos con el emocionante campo de Inteligencia Artificial a través de Photon, abriendo las puertas a la innovación y la resolución de problemas en diversos ámbitos de la vida cotidiana.            "
        },
        {
            title: "Innovación en Movimiento:",
            description: "Habilidad para crear armados tridimensionales con movimiento durante las clases de robótica, fomentando la creatividad y fortaleciendo el trabajo en equipo, mientras se exploran conceptos avanzados de ingeniería y diseño."
        },
        {
            title: "Dominio de la Simulación:",
            description: "Competencia en el uso efectivo del simulador de Tinkercad, demostrando habilidades para conectar LEDs correctamente y explorar diversas configuraciones. Los estudiantes de 6to grado han realizado eficazmente circuitos usando la placa Arduino a través de la programación en bloques."
        },
        {
            title: "Excelencia en Circuitos:",
            description: "Aplicación del proceso para desarrollar circuitos eléctricos, desde la creación del plano eléctrico y el diagrama de flujo hasta su implementación en el simulador, garantizando un enfoque metódico y preciso en la construcción y análisis de circuitos."
        }
        ]
    },
    {
        program: "Makerlab III",
        frase: "Creación de proyectos inteligentes y adaptables con kits avanzados y programación intuitiva.",
        indicators: [{
            title: "Potencia Tecnológica Fusionada:",
            description: "Habilidad para integrar de manera hábil las funciones del kit R1000, la versátil placa Micro:bit y la programación en bloques para otorgar funcionamiento a los armados, potenciando así la creatividad y la funcionalidad de cada creación."
        },
        {
            title: "Movimiento Inteligente y Sensorial:",
            description: "Competencia en el uso del servomotor en conjunto con los avanzados sensores del kit R1000, permitiendo la creación de proyectos interactivos y adaptativos que responden de manera inteligente a su entorno."
        },
        {
            title: "Control con Micro:bit:",
            description: "Dominio en el control del funcionamiento de los armados mediante programas desarrollados para la placa Micro:bit, ofreciendo una experiencia de usuario intuitiva y personalizada que maximiza el potencial de cada proyecto."
        },
        {
            title: "Entendimiento Técnico:",
            description: "Distinguir entre una caja de reducción y una caja de ampliación, demostrando un conocimiento preciso de los principios mecánicos y eléctricos subyacentes, esenciales para el diseño y la optimización de sistemas mecánicos y electrónicos."
        }
        ]
    },
    {
        program: "Makerlab III Comprimido",
        frase: "Creación de proyectos inteligentes y adaptables con kits avanzados y programación intuitiva.",
        indicators: [{
            title: "Potencia Tecnológica Fusionada:",
            description: "Habilidad para integrar de manera hábil las funciones del kit R1000, la versátil placa Micro:bit y la programación en bloques para otorgar funcionamiento a los armados, potenciando así la creatividad y la funcionalidad de cada creación."
        },
        {
            title: "Movimiento Inteligente y Sensorial:",
            description: "Competencia en el uso del servomotor en conjunto con los avanzados sensores del kit R1000, permitiendo la creación de proyectos interactivos y adaptativos que responden de manera inteligente a su entorno."
        },
        {
            title: "Control con Micro:bit:",
            description: "Dominio en el control del funcionamiento de los armados mediante programas desarrollados para la placa Micro:bit, ofreciendo una experiencia de usuario intuitiva y personalizada que maximiza el potencial de cada proyecto."
        },
        {
            title: "Entendimiento Técnico:",
            description: "Distinguir entre una caja de reducción y una caja de ampliación, demostrando un conocimiento preciso de los principios mecánicos y eléctricos subyacentes, esenciales para el diseño y la optimización de sistemas mecánicos y electrónicos."
        }
        ]
    },
    {
        program: "Marketplace",
        frase: "transformando insights comerciales en decisiones que ayudan a alcanzar el éxito empresarial",
        indicators: [
            {
                title: "Fundamentos del emprendimiento",
                description: "Los alumnos aprenden los conceptos básicos de iniciar un negocio, incluyendo las diferentes formas de financiamiento, las ventajas y desventajas de varios tipos de negocios, y la diferencia entre empresas con y sin fines de lucro."
            },
            {
                title: "Desarrollo de un modelo de negocio:",
                description: "Se centran en desarrollar un modelo de negocio sólido y competitivo. Aprenden a crear planes para alcanzar la independencia financiera y gestionar eficazmente el flujo de efectivo."
            },
            {
                title: "Aplicación de estrategias empresariales:",
                description: "Los estudiantes aplican conceptos y estrategias para el desarrollo de su empresa, incluyendo la planificación financiera, la expresión clara de las premisas del negocio y la gestión eficiente de la información financiera."
            },
            {
                title: "Análisis empresarial:",
                description: "Analizan información desde la perspectiva empresarial, comprendiendo en qué cuadrante de flujo de efectivo se encuentra su empresa y registrando la información financiera de manera eficiente para tomar decisiones informadas."
            }
    ]
    },
    {
        program: "Playblock I",
        frase: "Donde la creatividad y la resolución de problemas se fusionan en un mundo de exploración con Photon",
        indicators: [
            {
                title: "Resolución Creativa de Desafíos:",
                description: "Los estudiantes encuentran soluciones innovadoras a los desafíos presentados en clase, fomentando la creatividad y la resolución de problemas."
            },
            {
                title: "Exploración Personalizada:",
                description: "Los estudiantes muestran sus preferencias y expresan su creatividad al experimentar con Photon, promoviendo la individualidad y la autoexpresión."
            },
            {
                title: "Programación y Creatividad Artística:",
                description: "Los estudiantes programan a Photon Draw según las instrucciones y realizan creaciones plásticas basadas en el recorrido del robot, combinando habilidades de programación, lateralidad y ubicación espacial."
            },
    ]
    },
    {
        program: "Playblock II",
        frase: "Creación de circuitos, programación y reconocimiento de funciones con Photon",
        indicators: [
            {
                title: "Creación de Circuitos con Photon:",
                description: "Los estudiantes demuestran habilidades avanzadas al crear circuitos con Photon, siguiendo las instrucciones de la actividad con precisión y creatividad."
            },
            {
                title: "Reconocimiento de Funciones en Photon Badge:",
                description: "Los estudiantes reconocen y comprenden las diversas funciones presentes en Photon Badge, demostrando un conocimiento profundo de las capacidades y aplicaciones de esta herramienta tecnológica."
            },
            {
                title: "Programación Avanzada de Photon:",
                description: "Los estudiantes programan con éxito a Photon en su interfaz de acuerdo con las Flashcards asignadas en la actividad, demostrando habilidades avanzadas de programación y resolución de problemas."
            },
            {
                title: "Creación de Arte Plástico con el Recorrido del Robot:",
                description: "Los estudiantes realizan creaciones plásticas innovadoras utilizando el recorrido del robot Photon como inspiración, fusionando la tecnología con el arte de manera imaginativa y expresiva."
            },
    ]
    },
    {
        program: "U-Code",
        frase: "donde la creatividad se convierte en código",
        indicators: [{
            title: "Conceptos Avanzados de Python:",
            description: "Los estudiantes aprenden funciones, métodos para listas y strings, y la diferencia entre tuplas y diccionarios para organizar datos de manera eficiente."
        },
        {
            title: "Optimización del código:",
            description: "Se enseña a usar funciones para hacer el código más eficiente, modularizarlo y reutilizarlo, además de aprender a trabajar con parámetros y llamar funciones correctamente."
        },
        {
            title: "Proyectos reales:",
            description: "El enfoque está en proporcionar habilidades prácticas para resolver problemas mediante la programación en Python, permitiendo a los estudiantes aplicar estos conocimientos en proyectos reales."
        },
        ]
    },
    {
        program: "U-Code Pro",
        frase: "avanzando hacia el dominio del código para conquistar nuevos horizontes",
        indicators: [{
            title: "Interactividad con Javascript:",
            description: "Los alumnos aprenden a hacer que sus páginas web sean más dinámicas. Incorporan Javascript en documentos HTML, utilizan variables, filtran elementos con querySelector y responden a acciones del usuario."
        },
        {
            title: "Diseño de interfaces amigables:",
            description: "Diseñan interfaces web atractivas y organizadas. Organizan elementos dentro de la página y a utilizan la estructura de archivos correcta lo que mejora la usabilidad y la experiencia del usuario."
        },
        {
            title: "Despliegue en servidor local:",
            description: "Realizan la instalación de Flask, un servidor web en Python, y lo utilizan para desplegar sus aplicaciones en un servidor local. Esto les permite visualizar y probar sus páginas antes de compartirlas en Internet."
        },
        {
            title: "Aplicación práctica:",
            description: "El enfoque está en la aplicación práctica de conceptos de electrónica en proyectos que los estudiantes pueden relacionar con su vida diaria, lo que les permite entender cómo funcionan los dispositivos electrónicos y cómo pueden crear sistemas similares."
        }
        ]
    },
    
]

export const proyeccion = {
    "proyeccion1": [{
        logo: "/bblock.png",
        description: "En preescolar, usan piezas de construcción para crear armados, mientras trabajan la motricidad fina, la resolución de problemas y la coordinación óculo-manual. "
    },{
        logo: "/playblock.png",
        description: "Usan el robot Photon para aprender de forma divertida temas importantes de la educación temprana, mientras desarrollan habilidades como el pensamiento lógico y la programación básica. "
    }],
    "proyeccion2": [{
        logo: "/block+.png",
        description: "En primaria, usan piezas de construcción para aprender sobre mecánica básica y habilidad espacial durante la etapa de primer grado. "
    },{
        logo: "/makerlab.png",
        description: "Integran las tres áreas de la robótica durante 5 niveles.  A medida de que van avanzando, aprenden programación para crear animaciones y videojuegos. Hacen armados para conocer más de mecánica. Usan inteligencia artificial, y a través de un robot con un microcontrolador,  aprenden a programar por bloques."
    }],
    "proyeccion3": [{
        logo: "/eboard.png",
        description: "A través de uno de los microcontroladores más importantes en el mundo, los estudiantes de media general aprenden electrónica y programación durante dos niveles."
    },{
        logo: "/ucode.png",
        description: "Aprenden el lenguaje de programación Python. En la versión pro, los alumnos de bachillerato aprenden  herramientas como HTML, CSS y Javascript para programar y diseñar páginas web."
    }]
}