import React, { useContext, useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import { Formik, Form } from "formik";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import {
  MyTextInput,
  MySubmitButton,
  MyCheckbox,
} from "../FormFields";
import * as Yup from "yup";
import styles from "./CreateAnnouncement.module.css";
import { AnnouncementsContext } from "../../context/AnnouncementsContext";
import { InstitutionsContext } from "../../context/InstitutionsContext";

function CreateAnnouncement() {
  const { createAnnouncement } = useContext(AnnouncementsContext);
  const { institutions, fetchInstitutions } = useContext(InstitutionsContext);
  const [filterText, setFilterText] = useState("");
  const [sendAll, setSendAll] = useState(false);
  const [showInstitutions, setShowInstitutions] = useState(true);
  const [file, setFile] = useState(null);
  const [text, setText] = useState('');

  useEffect(() => {
    fetchInstitutions()
  }, []);

  const handleChange = (content) => {
    setText(content);
  };

  let filterData = institutions.filter(
    (item) =>
      item.name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.short_name.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleShowInst = () => {
    setFilterText("");
    setShowInstitutions(!showInstitutions);
  };

  const institutionsId = [];
  institutions &&
    institutions.forEach((inst) => {
      institutionsId.push(inst?.id?.toString());
    });

    const handleFileChange = (e) => {
      setFile(e.target.files[0]);
    };
  return (
    <div className={styles["create-institution"]}>
      <Formik
        initialValues={{
          title: "",
          content: "",
          start_date: "",
          expiration_date: "",
          url: "",
          image: "",
        }}
        validationSchema={Yup.object({
          title: Yup.string()
            .min(1, "El campo no puede estar vacío.")
            .required("Campo requerido"),
          content: Yup.string().min(2, "Debe contener más de 2 caracteres."),
          start_date: Yup.date().required("Campo requerido"),
          expiration_date: Yup.date().required("Campo requerido"),
          url: Yup.string(),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const formData = new FormData();

          if (sendAll) {
            values.institutions = institutionsId;
          }
          const textValue = JSON.stringify(text);

          formData.append("title", values.title);
          formData.append("institutions", values.institutions);
          formData.append("start_date", values.start_date);
          formData.append("expiration_date", values.expiration_date);
          formData.append("content", textValue);
          formData.append("url", values.url);
          formData.append("image", file);

          setTimeout(() => {
            createAnnouncement(formData);
            setText('')
            setSubmitting(false);
          }, 400);
          resetForm();
        }}
      >
        {/* formProps contains handlechange, handleblur, etc */}
        {(formProps) => (
          <Form
            className={styles["form__fields"]}
            encType="multipart/form-data"
          >
            <Grid container direction={"row"} rowSpacing={2} columnSpacing={2}>
              <Grid item md={7} sm={12}>
                <Grid container direction={"row"} rowSpacing={2} columnSpacing={2}>
                  <Grid item md={6} sm={12} xs={12}>
                  <MyTextInput
                    label="Título"
                    name="title"
                    type="text"
                    placeholder="Título"
                    icon="fa fa-user"
                    width={'100%'}
                  />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                  <MyTextInput
                    label="Fecha de inicio"
                    name="start_date"
                    type="date"
                    icon="fa fa-key"
                    width={'100%'}
                  />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                  <MyTextInput
                    label="Fecha de expiración"
                    name="expiration_date"
                    type="date"
                    icon="fa fa-user"
                    width={'100%'}
                  />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <ReactQuill value={text} onChange={handleChange} />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                  <label htmlFor="file" style={{ fontSize: "1.5rem" }}>
                      Imagen
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      name="image"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <Button
                      variant="outlined"
                      component="span" fullWidth
                      startIcon={<CloudUploadIcon />}
                      onClick={() => document.getElementById('file-upload').click()}
                      sx={{ marginTop: "3px", borderRadius: '10px' }}
                    >
                      Subir Imagen
                    </Button>
                    {file && (
                      <Typography variant="body1" sx={{ marginTop: "10px" }}>
                        Archivo seleccionado: {file.name}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                  <MyTextInput
                    label="URL"
                    name="url"
                    type="url"
                    placeholder="www..."
                    icon="fa fa-user"
                    width={'100%'}
                  />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={5} sm={12} xs={12}>
              <Accordion sx={{borderRadius: '10px'}} elevation={0} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon fontSize="large" />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{fontSize: '16px'}}
                >
                  Seleccione las instituciones
                </AccordionSummary>
                <AccordionDetails>
                <div>
                  <TextField variant="outlined" 
                  size="small" 
                  value={filterText}
                  placeholder="Buscar" fullWidth
                  onChange={(e) => setFilterText(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchRoundedIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    marginBottom: 1,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }} />
                    
                    <div className={styles.inst}>
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          marginBottom: "5px",
                        }}
                      >
                        <input
                          type="checkbox"
                          id="institutions"
                          name="institutions"
                          checked={sendAll}
                          style={{
                            width: "17px",
                            height: "17px",
                            marginRight: "5px",
                          }}
                          onClick={() => setSendAll(!sendAll)}
                        />
                        <label
                          for="institutions"
                          style={{ fontSize: "1.5rem" }}
                        >
                          Todas
                        </label>
                      </div>
                      {!sendAll &&
                        filterData.map((inst) => (
                          <div className={styles.check_level} key={inst.id}>
                            <MyCheckbox
                              name="institutions"
                              value={inst.id.toString()}
                            >
                              {`${inst.name}`}
                            </MyCheckbox>
                          </div>
                        ))}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              </Grid>
            </Grid>
            <MySubmitButton name="Crear" type="submit" />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateAnnouncement;
