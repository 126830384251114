import React from "react";
import ReactDOM from "react-dom/client";
import "react-tooltip/dist/react-tooltip.css";
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { CurrentUserProvider } from "./context/CurrentUserProvider";
import { UsersProvider } from "./context/UsersProvider";
import { CoursesProvider } from "./context/CoursesContext";
import { InstitutionsProvider } from "./context/InstitutionsContext";
import { ProgramsProvider } from "./context/ProgramsContext";
import { InstitutionsLevelProvider } from "./context/Institutions_LevelsContext";
import { AnnouncementsProvider } from "./context/AnnouncementsContext";
import { SectionsProvider } from "./context/SectionsContext";
import { ForumsProvider } from "./context/ForumContext";
import { GroupsProvider } from "./context/GroupsContext";
import { FormsProvider } from "./context/FormsContext";
import { ConversationsProvider } from "./context/ConversationsProvider";
import { SocketProvider } from "./context/SocketProvider";
import { ClassJournalProvider } from "./context/ClassJournalContext";
import { AlertsProvider } from "./context/AlertsContext";
import { MonitorFormsProvider } from "./context/MonitorFormsContext";
import { CapacitacionesProvider } from "./context/CapacitacionesContext";
import { LoadingProvider } from "./context/LoadingContext";
import { EventsProvider } from "./context/EventsProvider";
import { AnnualTrainingsProvider } from "./context/AnnualTrainingsContext";
import { AuthProvider } from "./context/AuthProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
    <LoadingProvider>
      <AlertsProvider>
        <CurrentUserProvider>
          <AuthProvider>
          <UsersProvider>
            <ProgramsProvider>
             <EventsProvider>
               <InstitutionsProvider>
                 <ProgramsProvider>
                   <InstitutionsLevelProvider>
                     <AnnouncementsProvider>
                       <SectionsProvider>
                         <CoursesProvider>
                           <ForumsProvider>
                             <GroupsProvider>
                               <FormsProvider>
                                 <ConversationsProvider>
                                   <SocketProvider>
                                     <ClassJournalProvider>
                                       <MonitorFormsProvider>
                                         <CapacitacionesProvider>
                                          <AnnualTrainingsProvider>
                                           <App />
                                          </AnnualTrainingsProvider>
                                         </CapacitacionesProvider>
                                       </MonitorFormsProvider>
                                     </ClassJournalProvider>
                                   </SocketProvider>
                                 </ConversationsProvider>
                               </FormsProvider>
                             </GroupsProvider>
                           </ForumsProvider>
                         </CoursesProvider>
                       </SectionsProvider>
                     </AnnouncementsProvider>
                   </InstitutionsLevelProvider>
                 </ProgramsProvider>
               </InstitutionsProvider>
             </EventsProvider>
            </ProgramsProvider>
          </UsersProvider>
          </AuthProvider>
        </CurrentUserProvider>
      </AlertsProvider>
      </LoadingProvider>
    </BrowserRouter>
    
  </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
